import { useObjectsState } from "../../contexts/ObjectsProvider";
import Shape from "./Shape";
import { IKeyPressHandler } from "../../components/ObjectPropertyBox/models/IKeyPressHandler";
import React from "react";
import { handleKeyPress } from "../../components/ObjectPropertyBox/functions/PropertyBoxFunctions";

export interface ShapeListProps {
  target: HTMLElement | null;
  keyPressHandlers: IKeyPressHandler;
}

export const ShapeList = ({ target, keyPressHandlers }: ShapeListProps) => {
  const { shapeList } = useObjectsState();

  return (
    <>
      {shapeList.map((props, index) => (
        <Shape
          key={props.objectId}
          objectId={props.objectId}
          index={index}
          keyPressHandlers={keyPressHandlers}
          target={target}
          handleKeyPress={handleKeyPress}
          rotate={props.rotation ?? 0}
          scale={props.transform?.scale}
          left={props.left}
          top={props.top}
          height={props.height}
          width={props.width}
          pixelTop={props.rawData?.pixelTop}
          pixelLeft={props.rawData?.pixelLeft}
          pixelWidth={props.rawData?.pixelWidth}
          pixelHeight={props.rawData?.pixelHeight}
          borderColor={props.borderColor}
          backgroundColor={props.backgroundColor}
          strokeWidth={props.strokeWidth}
          zIndex={props.zIndex}
          opacity={props.opacity}
          visible={props.isDisplayed}
          ghost={props.ghost}
          blurIntensity={props?.blurIntensity}
          blurOpacity={props?.blurOpacity}
          blurColor={props?.blurColor}
          blurCutoutShapes={props?.blurCutoutShapes}
        />
      ))}
    </>
  );
};
