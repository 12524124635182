import { CSSProperties, useEffect, useRef, useState } from "react";
import WaveRenderer from "./renderer";
import { getAudioSource } from "../../../../contexts/PageAudioManager";

export interface AudioWaveProps {
  objectId: string;
  height: number;
  width: number;
  waveColor: string;
}
export const AudioWave = ({ objectId, height, width, waveColor }: AudioWaveProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const audioSourceRef = useRef<AudioBuffer | null>(null);
  const [renderer, setRenderer] = useState<WaveRenderer | null>(null);
  const [containerStyles, setContainerStyles] = useState<CSSProperties>({});

  useEffect(() => {
    // Any other mutable values that should change the container size in real time
    setContainerStyles({
      height: `${height}px`,
      width: `${width}px`,
    });
  }, [height, width]);

  useEffect(() => {
    audioSourceRef.current = getAudioSource(objectId)?.buffer ?? null;
  }, [objectId]);

  useEffect(() => {
    if (ref?.current && !renderer) {
      setRenderer(
        new WaveRenderer({
          height,
          fillParent: true,
          waveColor: waveColor,
          container: ref.current,
        }),
      );
    }
  }, [ref?.current]);

  useEffect(() => {
    if (renderer && audioSourceRef.current) {
      renderer.options.waveColor = waveColor;
      void renderer.render(audioSourceRef.current);
    }
  }, [JSON.stringify(containerStyles), renderer, audioSourceRef.current, waveColor]);

  return <div className="audio-wave" ref={ref} style={containerStyles} />;
};
