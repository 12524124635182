import { CutoutShapes } from "../../types";
import type { CSSProperties, MouseEventHandler } from "react";

type BaseMask = {
  type: CutoutShapes;
  x: number;
  y: number;
  width: number;
  height: number;
  cutoutId: string;
  rotate: number;
  isVisible: boolean;
};

export interface ShapeMasksProps {
  masks: BaseMask[];
  objectId: string;
  width: number;
  height: number;
  onSelect: (mask: BaseMask) => void;
}

export function ShapeMasks({ masks, objectId, width, height, onSelect }: ShapeMasksProps) {
  return (
    <>
      {/*Render SVG*/}
      <svg
        style={{
          position: "absolute",
          zIndex: 1,
          width: width,
          height: height,
        }}
      >
        <mask id={objectId}>
          {/*Render white sheet to show everything*/}
          <rect fill="white" x="0" y="0" width={width} height={height} />
          {/*Render mask objects to hide pieces*/}
          {masks.map((mask) => (
            <MaskSVGShape key={mask.cutoutId} mask={mask} />
          ))}
        </mask>
      </svg>

      {/*Render Mask Dragging Handlers*/}
      {masks.map((mask) => (
        <MaskDraggingHandle mask={mask} key={mask.cutoutId} onClick={() => onSelect(mask)} />
      ))}
    </>
  );
}

const MaskSVGShape = ({ mask }: { mask: BaseMask }) => {
  const rotateString = `rotate(${mask.rotate} ${mask.x + mask.width / 2} ${mask.y + mask.height / 2})`;
  const isVisible = mask.isVisible;
  if (!isVisible) return null;

  switch (mask.type) {
    case "rectangular":
      return (
        <rect x={mask.x} y={mask.y} width={mask.width} height={mask.height} transform={rotateString} fill="black" />
      );
    case "triangle":
      return (
        <polygon
          points={`${mask.x},${mask.y + mask.height} ${mask.x + mask.width / 2},${mask.y} ${mask.x + mask.width},${
            mask.y + mask.height
          }`}
          fill="black"
          transform={rotateString}
        />
      );
    case "ellipse":
      return (
        <ellipse
          cx={mask.x + mask.width / 2}
          cy={mask.y + mask.height / 2}
          rx={mask.width / 2}
          ry={mask.height / 2}
          fill="black"
          transform={rotateString}
        />
      );
  }
};

const MaskDraggingHandle = ({ mask, onClick }: { mask: BaseMask; onClick: MouseEventHandler }) => {
  const transform = `translate(${mask.x}px, ${mask.y}px) rotate(${mask.rotate}deg)`;
  const commonStyle: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    width: mask.width,
    height: mask.height,
    transform,
  };
  const handleStyle: CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    cursor: "grab",
  };
  return (
    <>
      <div className="shape-mask resizeable-child" id={`handle-${mask.cutoutId}`} style={commonStyle} onClick={onClick}>
        <div className="shape-mask-handle" style={handleStyle} />
      </div>
    </>
  );
};
