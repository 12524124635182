import { EventEmitter } from "eventemitter3";
import { GlobalTimerConfig, TimerEvents, TimerState } from "./types";

const defaults: GlobalTimerConfig = {
  FPS: 60,
};

class GlobalTimer extends EventEmitter<TimerEvents> {
  /**
   * Config
   */
  config: GlobalTimerConfig = {
    FPS: defaults.FPS,
  };
  interval = 0; // Calculated based on frame rate, will determine how many times the callbacks will run
  /**
   * Props
   */
  requestInstance: number | null = null;
  currentTime = 0; // Current time relative to Zero time
  zeroTime = 0; // Relative starting point
  deltaInterval = 0; // Relative value in between frames
  /**
   * State
   */
  state: TimerState = TimerState.STOPPED;
  /**
   * Constructor
   */
  constructor(config?: GlobalTimerConfig) {
    super();
    // Apply config
    this.config = {
      ...defaults,
      ...config,
    };
    // Basic config setup for 60 frames
    this.interval = 1000 / this.config.FPS;
  }
  /**
   * Getters/Setters
   */
  setState(newState: TimerState) {
    switch (newState) {
      case TimerState.RUNNING:
        this.emit(TimerEvents.START);
        break;
      case TimerState.STOPPED:
        this.emit(TimerEvents.STOP);
        break;
    }

    this.state = newState;
  }
  /**
   * Controls
   */
  start(startTime = 0) {
    if (this.state === TimerState.RUNNING) {
      return;
    }

    this.setState(TimerState.RUNNING);

    this.zeroTime = Date.now() - startTime * 1000;
    this.currentTime = this.zeroTime;

    this.tick();
  }
  stop() {
    if (this.state !== TimerState.RUNNING) {
      return;
    }

    if (this.requestInstance) {
      cancelAnimationFrame(this.requestInstance);
    }

    this.setState(TimerState.STOPPED);
  }
  /**
   * Loop
   */
  tick() {
    if (this.state !== TimerState.RUNNING) {
      return;
    }

    this.requestInstance = requestAnimationFrame(() => this.tick());
    const timestamp = Date.now();
    const elapsedTime = timestamp - this.deltaInterval;

    if (elapsedTime >= this.interval) {
      // Update overall current time
      this.currentTime = timestamp - this.zeroTime;
      // Update delta interval for next run calculation
      this.deltaInterval = timestamp - (elapsedTime % this.interval);
      this.emit(TimerEvents.TICK, this.currentTime);
    }
  }
}

export default new GlobalTimer();
