import { BasePageTextBlockVersion } from "../../../utils/Versioning/basePagesVersioning";
import { parseSemanticVersioning } from "../../../utils/Versioning/versioning";

export const colors: Array<string> = [
  "#FF0000",
  "#FFFF00",
  "#00FF00",
  "#FFBF00",
  "#CC0000",
  "#9FFF0A",
  "#00CCCC",
  "#0000FF",
  "#F2DB18",
  "#FF9900",
  "#DADBDD",
  "#5E36FF",
  "#008500",
  "#969DA6",
  "#7C0A02",
  "#72BE44",
  "#0091FF",
  "#283D55",
  "#CC7722",
  "#990099",
  "#A8B8C8",
  "#4B4F58",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#FFC0CB",
  "#808080",
  "#000000",
  "#FFFFFF",
  "#D3D3D3",
  "#ADD8E6",
  "#90EE90",
  "#FFFFE0",
  "#FFB6C1",
  "#FFCCCB",
  "#E0FFFF",
  "#A9A9A9",
  "#00008B",
  "#006400",
  "#8B0000",
  "#9B870C",
  "#8B008B",
  "#008B8B",
];
export const bgColors: Array<string> = [
  "#FF0000",
  "#FFFF00",
  "#00FF00",
  "#FFBF00",
  "#CC0000",
  "#9FFF0A",
  "#00CCCC",
  "#0000FF",
  "#F2DB18",
  "#FF9900",
  "#DADBDD",
  "#5E36FF",
  "#008500",
  "#969DA6",
  "#7C0A02",
  "#72BE44",
  "#0091FF",
  "#283D55",
  "#CC7722",
  "#990099",
  "#A8B8C8",
  "#4B4F58",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#FFC0CB",
  "#808080",
  "#000000",
  "#FFFFFF",
  "#D3D3D3",
  "#ADD8E6",
  "#90EE90",
  "#FFFFE0",
  "#FFB6C1",
  "#FFCCCB",
  "#E0FFFF",
  "#A9A9A9",
  "#00008B",
  "#006400",
  "#8B0000",
  "#9B870C",
  "#8B008B",
  "#008B8B",
  "transparent",
];
// export const BASE_PAGES_QUILL_FONT_SIZE_WHITELIST: Array<string> =['9.37px', '13.12px', '18.74px', '28.11px', '37.48px'];

export const BASE_PAGES_QUILL_FONT_SIZE_WHITELIST: Array<string> = [
  "tiny",
  "tiny2",
  "small",
  "small2",
  "small3",
  "normal",
  "normal2",
  "normal3",
  "large",
  "huge",
];
export const OLD_EM_FONT_WHITELIST: Array<string> = [
  "0.5em",
  "0.6em",
  "0.7em",
  "0.8em",
  "0.9em",
  "1em",
  "1.2em",
  "1.3em",
  "1.5em",
  "2em",
];
export const OLD_REM_FONT_WHITELIST: Array<string> = [
  "0.5rem",
  "0.6rem",
  "0.7rem",
  "0.8rem",
  "0.9rem",
  "1rem",
  "1.2rem",
  "1.3rem",
  "1.5rem",
  "2rem",
];

export const getFontsFromVersion = (v?: BasePageTextBlockVersion) => {
  const version = parseSemanticVersioning(v);

  if (!!version && version[0] >= 1 && version[1] >= 1 && version[2] >= 0) {
    return BASE_PAGES_QUILL_FONT_SIZE_WHITELIST;
  } else {
    return OLD_EM_FONT_WHITELIST;
  }
};
