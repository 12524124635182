import { useContext, useRef } from "react";
import { useTimeline } from "../../../../contexts/TimelineProvider/TimelineProvider";
import classes from "../../../../utils/HTML/classes";
import { OBJECT_ROW_HEIGHT } from "../../TimelinePanel";
import { ReactComponent as AddIcon } from "../../../../assets/icons/Common/add-icon.svg";
import type { OverlayTriggerHandle } from "rsuite/Picker";
import { Whisper } from "rsuite";
import { Popover } from "react-bootstrap";
import { AudioEffectsPopover, AudioEffectsPopoverAction } from "./AudioEffectPopover";
import {
  AssetContext,
  IAssetManagementContext,
  ILessonPageActionsContext,
  LessonPageActionsContext,
} from "../../../../routes/builderContexts";
import { ASSET_TYPE_ID } from "../../../../const";
import { useAudioManagerStore } from "../../../../contexts/PageAudioManager";
import { useShallow } from "zustand/react/shallow";
import { InsertMode, useAudioEffectStore } from "./store";
import { AudioEffectItem } from "./AudioEffectItem";

export function AudioEffectRow() {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const [timeline, , { clippedSpaceFromUnitSpace }] = useTimeline();
  const [moveEffectAudio] = useAudioManagerStore(useShallow((state) => [state.moveEffectAudio]));
  const [setInsertMode] = useAudioEffectStore(useShallow((state) => [state.setInsertMode]));

  const isDragging = useAudioEffectStore((state) => state.isDragging);
  const objects = useAudioEffectStore((state) => state.objects);
  const isEmpty = objects.size <= 0;

  const handleAddAudio = (action: AudioEffectsPopoverAction, targetObjectId?: string) => {
    if (action === "assetLibrary") {
      assetContext.setAssetTypeId(ASSET_TYPE_ID.AUDIO_EFFECT);
      lessonPageActionsContext.setAssetManagerController({
        isOpen: true,
        mode: "AudioEffectOnly",
      });

      if (targetObjectId) {
        setInsertMode(InsertMode.BEFORE);
      } else {
        setInsertMode(InsertMode.AFTER);
      }
    }
  };

  const handleOnMove = (objectId: string, startTime: number) => {
    moveEffectAudio(objectId, startTime);
  };

  const getAddButtonPosition = (): number => {
    const lastItem = Array.from(objects.values()).pop();

    if (lastItem) {
      return clippedSpaceFromUnitSpace(lastItem?.end ?? 0) + 4;
    }

    return timeline.widthInPx / 2;
  };

  return (
    <li className="right-obj-row">
      <div
        className="right-obj-wrapper"
        style={{ width: isEmpty ? timeline.widthInPx + "px" : "100%", height: OBJECT_ROW_HEIGHT }}
      >
        {/* Holds all audio effects */}
        <div
          className={classes("narration-row-wrapper", "narration-row-wrapper--interactive", {
            "narration-row-wrapper--empty": isEmpty,
            "narration-row-wrapper--dragging": isDragging,
          })}
        >
          <div className="narration-row-divider"></div>

          {Array.from(objects.values()).map((item) => {
            return (
              <AudioEffectItem
                key={item.objectId}
                item={item}
                onClickAdd={(action) => {
                  handleAddAudio(action, item.objectId);
                }}
                onMoved={(newStart) => handleOnMove(item.objectId, newStart)}
              />
            );
          })}

          <div
            className={classes("add-button-container", {
              "add-button-container--hidden": isDragging,
            })}
            style={{
              left: getAddButtonPosition(),
            }}
          >
            <AddEffectButton handleAddAudio={handleAddAudio} disabled={false} />
          </div>
        </div>
      </div>
    </li>
  );
}

interface AddAudioButtonProps {
  handleAddAudio: (action: AudioEffectsPopoverAction) => void;
  disabled?: boolean;
}

export function AddEffectButton({ handleAddAudio, disabled = false }: AddAudioButtonProps) {
  const popOverRef = useRef<OverlayTriggerHandle>(null);
  const handleSelect = (action: AudioEffectsPopoverAction) => {
    popOverRef.current?.close();
    handleAddAudio(action);
  };

  return (
    <Whisper
      controlId="add-audio-effect"
      placement="bottom"
      trigger="click"
      ref={popOverRef}
      speaker={<AudioEffectsPopover onSelect={handleSelect} />}
    >
      <button
        className={classes("add-button cpat-button cpat-button--icon", {
          "cpat-button--ghost": disabled,
        })}
        title="Add Audio"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <AddIcon />
      </button>
    </Whisper>
  );
}
