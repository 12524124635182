import "./BasePageDesigner.css";
import _ from "lodash";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import {
  AssetContext,
  IAssetManagementContext,
  ILessonPageActionsContext,
  IPageContext,
  LessonPageActionsContext,
  PageContext,
} from "../../routes/builderContexts";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import BaseDoubleGraphicSplit from "./components/BaseDoubleGraphicSplit";
import BaseDoubleGraphicSplitRight from "./components/BaseDoubleGraphicSplitRight";
import BaseFullPageGraphic from "./components/BaseFullPageGraphic";
import BaseIntroduction from "./components/BaseIntroduction";
import BaseSingleGraphicCenter from "./components/BaseSingleGraphicCenter";
import BaseSingleGraphicCenter2 from "./components/BaseSingleGraphicCenter2";
import BaseSingleGraphicLeft from "./components/BaseSingleGraphicLeft";
import BaseSingleGraphicRight from "./components/BaseSingleGraphicRight";
import BaseDoubleGraphicSplitVert from "./components/BaseDoubleGraphicSplitVert";
import BaseDoubleGraphicSplitHoriz from "./components/BaseDoubleGraphicSplitHorizontal";
import BaseDoubleGraphicSplitHorizTextBelow from "./components/BaseDoubleGraphicSplitHorizTextBelow";
import BaseDoubleGraphicSplitHorizTextAbove from "./components/BaseDoubleGraphicSplitHorizTextAbove";
import BaseTrippleGraphicSplitLeft from "./components/BaseTrippleGraphicSplitLeft";
import BaseTrippleGraphicSplitRight from "./components/BaseTrippleGraphicSplitRight";
import BaseTrippleGraphicSplitTop from "./components/BaseTrippleGraphicSplitTop";
import BaseTrippleGraphicSplitBottom from "./components/BaseTrippleGraphicSplitBottom";
import BaseFourGraphicSplit from "./components/BaseFourGraphicSplit";
import BaseFourGraphicSplitText from "./components/BaseFourGraphicSplitText";
import BaseTextOnlyCenter from "./components/BaseTextOnlyCenter";
import DesignerModal from "../../components/Modals/DesignerModal";
import BaseObjectives from "./components/BaseObjectives";
import BaseSummary from "./components/BaseSummary";
import BaseWarning from "./components/BaseWarning";
import layoutChangeIcon from "../../assets/icons/ld-change-layout-icon.png";
import BaseFreeForm from "./components/BaseFreeForm/BaseFreeForm";
import { ElementTypes, IBasePage, IBasicPageImageV2, IBasicPageTextBlockV2 } from "./components/IBasePage";
import WYSIWYG from "../../components/WYSIWYG/WYSIWYG";
import { crawlAudioArray, crawlAudios, IDandPath } from "../../services/Utils/PageTypeCrawler/PageTypeCrawler";
import {
  borderColorConversion,
  convertLabelTextFormat,
  interweaveHtmlAndPlainTextToQuillHtmlBridgeFromatting,
} from "./components/BaseFreeForm/functions/formatConversion";
import { RangeStatic } from "quill";
import { CustomPageObjectList } from "../../classes/ObjectList/CustomPageObjectList";
import { Annotation } from "../../components/Annotation/classes/AnnotationClass";
import { CustomVideoElement } from "./components/BaseFreeForm/classes/CustomVideoElement";
import { CustomPageScorm } from "./components/BaseFreeForm/classes/CustomPageSCORM";
import { ClassSymbol } from "../../components/Symbol/classes/ClassSymbol";

import { TablesDataProvider, useTablesDataState } from "../../components/Tables/TablesDataProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import {
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { useObjectPropertyBoxDispatch } from "../../contexts/ObjectPropertyBox/ObjectPropertyBoxProvider";
import { nanoid } from "../../lib/nanoId";
import WYSIWYGToolbar from "../../components/WYSIWYGToolbar/WYSIWYGToolbar";
import useSettingsToggle from "../../hooks/useSettingsToggle";
import { freeformSettings } from "./components/BaseFreeForm/configurations/permissions";
import { Annotations } from "../../components/Annotations";
import { ASSET_TYPE_ID, symbolTypes } from "../../const";
import { BoundType } from "react-moveable";
import { IAnnotationState } from "../../components/Annotation/models/IAnnotationState";
import { ISymbolV2 } from "../../components/Symbol/models/ISymbol";
import { AbstractMoveable } from "../../components/Moveable/AbstractMoveable";
import { ListOfObjects } from "../../classes/ObjectList/ListOfObjects";
import { useMovableElementsPlaneDispatch, useUIStore } from "../../contexts/MovableElementsPlaneProvider";

import ConfirmDelete from "../../components/Modals/ConfirmDelete";
import { determinePageBG } from "../../contexts/PageColorProvider/PageColorProvider";
import { LineMoveable } from "../../components/Moveable/LineMoveable";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { LessonPagesActions, useLessonPagesDispatch } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
// import { ClassSymbol } from '../../components/Symbol/classes/ClassSymbol';
// import { ISymbolV2 } from '../../components/Symbol/models/ISymbol';
const MAX_ANNOTATIONS_COUNT = 250;
export interface IImages {
  assetVersionId: number;
  blobPath: string;
  name: string;
  description: string;
  displayVersion: string;
  assetTypeName: string;
  isCollection: boolean;
}

export interface IRTEModule {
  align?: string | undefined;
  list?: string | undefined;
}

const moduleRTE = [{ align: "" }, { align: "center" }, { align: "right" }, { list: "bullet" }, { list: "ordered" }];

type DisplayPageProps = {
  pageObject: any;
  basePageType: string;
};
const DisplayBasePageType = ({ pageObject, basePageType }: DisplayPageProps) => {
  switch (basePageType) {
    case "singleGraphicRight":
      return <BaseSingleGraphicRight {...pageObject} />;
    case "singleGraphicLeft":
      return <BaseSingleGraphicLeft {...pageObject} />;
    case "singleGraphicCenter":
      return <BaseSingleGraphicCenter {...pageObject} />;
    case "fullPageGraphic":
      return <BaseFullPageGraphic {...pageObject} />;
    case "doubleGraphicsSplit":
      return <BaseDoubleGraphicSplit {...pageObject} />;
    case "doubleGraphicSplitRight":
      return <BaseDoubleGraphicSplitRight {...pageObject} />;
    case "textOnly":
      return <BaseTextOnlyCenter {...pageObject} />;

    // new base pages
    case "singleGraphicCenter2":
      return <BaseSingleGraphicCenter2 {...pageObject} />;
    case "doubleGraphicSplitVert":
      return <BaseDoubleGraphicSplitVert {...pageObject} />;
    case "doubleGraphicSplitHoriz":
      return <BaseDoubleGraphicSplitHoriz {...pageObject} />;
    case "doubleGraphicSplitHorizTextBelow":
      return <BaseDoubleGraphicSplitHorizTextBelow {...pageObject} />;
    case "doubleGraphicSplitHorizTextAbove":
      return <BaseDoubleGraphicSplitHorizTextAbove {...pageObject} />;
    case "trippleGraphicSplitLeft":
      return <BaseTrippleGraphicSplitLeft {...pageObject} />;
    case "trippleGraphicSplitRight":
      return <BaseTrippleGraphicSplitRight {...pageObject} />;
    case "trippleGraphicSplitTop":
      return <BaseTrippleGraphicSplitTop {...pageObject} />;
    case "trippleGraphicSplitBottom":
      return <BaseTrippleGraphicSplitBottom {...pageObject} />;
    case "fourGraphicSplit":
      return <BaseFourGraphicSplit {...pageObject} />;
    case "fourGraphicSplitText":
      return <BaseFourGraphicSplitText {...pageObject} />;

    case "introduction":
      return <BaseIntroduction {...pageObject} />;
    case "objectives":
    case "objective":
      return <BaseObjectives {...pageObject} />;
    case "summary":
    case "conclusion":
      return <BaseSummary {...pageObject} />;
    case "warning":
      return <BaseWarning {...pageObject} />;
    default:
      return (
        <DesignerModal
          showModal={pageObject.isModalShown}
          modalType={"Change Layout"}
          hideModal={() => pageObject.setIsModalShown(false)}
        />
      );
  }
};

export const BasicPagePlayer: React.FC<any> = () => {
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  // const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const lessonPageActionsContext: ILessonPageActionsContext =
    useContext<ILessonPageActionsContext>(LessonPageActionsContext);
  const updatedManifest: IBasePage = _.cloneDeep(pageContext.pageManifest);
  const quillRef: React.RefObject<any> = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const movableElementsDispatch = useMovableElementsPlaneDispatch();
  const lessonPagesDispatch = useLessonPagesDispatch();
  const propertyBoxDispatch = useObjectPropertyBoxDispatch();
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const tablesState = useTablesDataState();
  const selectedObjectState = useSelectedObjectState();
  const { annotations, objectList, moveableRef, selectedObjects, moveableLineRef } = useObjectsState();
  const pageManifest: IBasePage = _.cloneDeep(pageContext.pageManifest);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isPropertyBoxShown, setIsPropertyBoxShown] = useState<boolean>(false);
  const [permissions] = useSettingsToggle(freeformSettings);
  const [nodeToUpdate, setNodeToUpdate] = useState<any>();
  const playerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [target, setTarget] = useState<any>();
  const isMounted = useRef<boolean>(true);
  const [, setMiscUI] = useMiscUI();
  const [bounds, setBounds]: [BoundType, Dispatch<SetStateAction<BoundType>>] = useState<BoundType>({
    top: 0,
    bottom: 900,
    left: 0,
    right: 1500,
  });
  const [editableIndex, setEditableIndex] = useState<number>(-1);
  const [elementType, setElementType] = useState<ElementTypes>("annotations");
  const [pageDims, setPageDims] = useState<DOMRect | null | undefined>();

  const imageOne: string =
    !updatedManifest.pageImage || !updatedManifest.pageImage[0] || !updatedManifest.pageImage[0].imagePath
      ? ""
      : blobUrlFormatHelper(updatedManifest.pageImage[0].imagePath);
  const imageTwo: string =
    !updatedManifest.pageImage || !updatedManifest.pageImage[1] || !updatedManifest.pageImage[1].imagePath
      ? ""
      : blobUrlFormatHelper(updatedManifest.pageImage[1].imagePath);
  const imageThree: string =
    !updatedManifest.pageImage || !updatedManifest.pageImage[2] || !updatedManifest.pageImage[2].imagePath
      ? ""
      : blobUrlFormatHelper(updatedManifest.pageImage[2].imagePath);
  const imageFour: string =
    !updatedManifest.pageImage || !updatedManifest.pageImage[3] || !updatedManifest.pageImage[3].imagePath
      ? ""
      : blobUrlFormatHelper(updatedManifest.pageImage[3].imagePath);

  const [basePageType, setBasePageType] = useState<string>("");
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [textBlockIndex, setTextBlockIndex] = useState(0);
  const [placeholderText] = useState<string>("Enter Text Here...");
  const [selectedFontSize, setSelectedFontSize] = useState<string>("18.74px");
  const { setDesignerViewportDims } = useUIStore();
  // ##### Version system
  // ##### Version system
  // ##### Version system

  const currentVersion = 20210223; // dropped the "B" to simplfy warning check
  const visibleChange = true; //  Does the changes constitute a visible change to the presented data
  // if visibleChange = true then when you update the previousVersion copy value to lastVisibleChange
  const previousVersion = 20210222; // dropped the "B" to simplfy warning check
  const lastVisibleChange = 20210222; // This should be the last version that had visibleChange = true

  const versionChangeWarning = () => {
    if (visibleChange === true || previousVersion < lastVisibleChange || previousVersion === lastVisibleChange) {
      doWarning();
    }
  };

  const doWarning = () => {
    // We need a warning message designed by Sabelyz and approved by Kent.  The following ALERT is a stand in.
    return;
  };

  const addVersion = (currentVersion: number) => {
    updatedManifest.version = currentVersion;
    pageContext.updatePageManifest(updatedManifest);
  };
  const versionCheck = (currentVersion: number) => {
    if (currentVersion !== updatedManifest.version) {
      versionChangeWarning();
      addVersion(currentVersion);
    }
  };

  useEffect(() => {
    if (pageContext.page.pageManifestIsDirty === false || pageContext.page.pageManifestIsDirty === undefined) {
      return;
    } else {
      updatedManifest.version !== undefined || updatedManifest.version !== null
        ? versionCheck(currentVersion)
        : addVersion(currentVersion);
    }
  }, [pageContext.page.pageManifestIsDirty]);

  // #### End of version system
  // #### End of version system
  // #### End of version system

  useEffect(() => {
    setIsLoading(false);
    isMounted.current = true;
    return () => {
      playerRef.current = null;
      propertyBoxDispatch({
        type: "REMOVE_OBJECT_PROPERTY_BOX_DOM_NODE",
      });
      movableElementsDispatch({
        type: "RESET_STATE",
      });
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const pageManifest = _.cloneDeep(updatedManifest);
    const incrementor = pageContext.getObjectIncrementor();
    const objectList = new CustomPageObjectList(updatedManifest, incrementor);
    if (updatedManifest.basePageType === "freeForm") {
      const objectList = new CustomPageObjectList(updatedManifest, incrementor);
      if (!_.isEqual(updatedManifest.pageImage, objectList.pageImage)) {
        updatedManifest.pageImage = _.cloneDeep(objectList.pageImage) as IBasicPageImageV2[];
      }
      if (!_.isEqual(updatedManifest.textBlock, objectList.textBlock)) {
        updatedManifest.textBlock = _.cloneDeep(objectList.textBlock) as IBasicPageTextBlockV2[];
      }
      if (updatedManifest.symbols && !_.isEqual(updatedManifest.symbols, objectList.symbols)) {
        updatedManifest.symbols = _.cloneDeep(objectList.symbols);
      }
      if (!Array.isArray(updatedManifest.annotations)) {
        updatedManifest.annotations = [];
      }
    }
    function handleTextConversion() {
      updatedManifest.textBlock = interweaveHtmlAndPlainTextToQuillHtmlBridgeFromatting(
        objectList.textBlock as IBasicPageTextBlockV2[],
      );
    }
    handleTextConversion();

    function validateAnnotations() {
      if (updatedManifest.annotations) {
        const notations = new Annotation(updatedManifest.annotations, "", -1, {}, null, 0).validateAnnotationNames();
        if (notations) updatedManifest.annotations = _.cloneDeep(notations);
        updatedManifest.annotations = convertLabelTextFormat(updatedManifest.annotations);
        updatedManifest.annotations = borderColorConversion(updatedManifest.annotations);
        // pageContext.updatePageManifest(updatedManifest);
      }
    }

    function updateVideoAttributes() {
      if (_.has(updatedManifest.video, "attributes")) {
        const incrementor = pageContext.getObjectIncrementor();
        const newVideo = new CustomVideoElement(-1, updatedManifest.video, {}, [], 0);
        const updatedVideo = newVideo.updateVideoAttributes();
        updatedManifest.video = _.cloneDeep(updatedVideo);
        // pageContext.updatePageManifest(updatedManifest);
      }
    }

    function updateSCORMAttributes() {
      const incrementor = pageContext.getObjectIncrementor();
      const newSCORM = new CustomPageScorm(-1, updatedManifest.pageScormObject as any, {}, [], 0);
      const updatedScorm = newSCORM.updateSCORMAttributes();
      updatedManifest.pageScormObject = updatedScorm;
      // pageContext.updatePageManifest(updatedManifest);
    }

    if (_.has(updatedManifest, "symbols")) validateSymbols();
    if (_.has(updatedManifest, "video")) updateVideoAttributes();
    if (_.has(updatedManifest, "pageScormObject")) updateSCORMAttributes();
    if (!_.isEqual(updatedManifest, pageManifest)) {
      pageContext.updatePageManifest(updatedManifest);
    }
    validateAnnotations();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    // this will run right after the previous effect, the order of this matters
    // allso it will run because isLoading has changed state. which means this actually runs twice.
    //if(!isMounted.current) return;
    const tempBounds = {
      top: 0,
      bottom: playerRef?.current?.getBoundingClientRect().height,
      left: 0,
      right: playerRef?.current?.getBoundingClientRect().width,
    };

    const majorElementsCount = new ListOfObjects().countMajorObjects(
      pageManifest.textBlock,
      pageManifest.pageImage,
      pageManifest.tables,
    );
    if (playerRef.current) {
      setDesignerViewportDims(playerRef.current.offsetWidth, playerRef.current.offsetHeight);
      movableElementsDispatch({
        type: "SET_PLANE_VIEWPORT_HEIGHT",
        payload: playerRef.current.offsetHeight,
      });
      movableElementsDispatch({
        type: "SET_PLANE_VIEWPORT_WIDTH",
        payload: playerRef.current.offsetWidth,
      });
    }
    setBounds(tempBounds);
    setPageDims(playerRef?.current?.getBoundingClientRect());
    if (!_.isEqual(pageContext.pageManifest, pageManifest)) {
      pageContext.updatePageManifest(pageManifest);
    }
  }, [isLoading]);

  useEffect(() => {
    addLayoutButtons();
  }, [pageContext.pageManifest.basePageType]);

  useEffect(() => {
    setBasePageType(pageContext.pageManifest.basePageType);
  }, [pageContext.pageManifest.basePageType]);

  useEffect(() => {
    let tempIds: number[] = [];

    const arr = () => {
      _.forEach(updatedManifest.Audio, (audio) => {
        if (typeof audio.Version === "number") {
          tempIds.push(audio.Version);
        }
      });
      _.forEach(updatedManifest.pageImage, (image) => {
        tempIds.push(image.assetVersionId);
      });
      // return tempIds;
    };
    arr();

    if (!_.isEqual(pageContext.pageAssetIds, tempIds) && tempIds.length > 0) {
      pageContext.setPageAssetIds(tempIds);
    }
    return () => {
      tempIds = [];
    };
  }, [updatedManifest.pageImage.length, pageContext.pageAssetIds]);

  const standardButtons: any[] = [];

  function validateSymbols() {
    if (_.has(updatedManifest, "symbols") && updatedManifest.symbols.length > 0) {
      const incrementor = pageContext.getObjectIncrementor();
      const newSymbol = new ClassSymbol(-1, {}, updatedManifest.symbols, "", [], incrementor);
      const symbols = newSymbol.convertSymbolIfHasStyleAttribute();
      updatedManifest.symbols = _.cloneDeep(symbols);
    }
  }

  const addLayoutButtons = () => {
    switch (pageContext.pageManifest.basePageType) {
      case "objective":
        lessonPageActionsContext.setLessonPageActions(standardButtons);
        break;
      case "conclusion":
      case "summary":
      case "objectives":
      case "warning":
      case "freeForm":
        // case "introduction": //Removed to allow layout changes on intro pages
        lessonPageActionsContext.setLessonPageActions([
          ...standardButtons,
          // {
          //     actionName: "Upload Audio",
          //     triggerAction: () => {
          //         assetContext.showBlobUploader(true);
          //         assetContext.setAssetIndex(0);
          //         assetContext.setAssetTypeId(5);
          //     },
          //     iconPath: `${uploadAudioIcon}`
          // }
        ]);
        break;
      default:
        lessonPageActionsContext.setLessonPageActions([
          ...standardButtons,
          {
            actionName: "Change Layout",
            triggerAction: () => {
              pageContext.setSelectorModalInfo("Change Layout");
            },
            iconPath: `${layoutChangeIcon}`,
          },
        ]);
        break;
    }
  };

  const assignNewText = (newText: string) => {
    updatedManifest.textBlock[textBlockIndex].text = newText;
    pageContext.updatePageManifest(updatedManifest);
  };

  function handleSelectionChange(range: RangeStatic) {
    if (range !== null && range !== undefined && quillRef.current) {
      const attr = quillRef.current.editor.getFormat(range.index, range.length);
      if (attr.size) {
        setSelectedFontSize(attr.size);
      }
    }
  }

  const displayImage = (imageIndex: number): JSX.Element => {
    let imageSource = "";
    if (imageIndex === 0) {
      imageSource = imageOne;
    } else if (imageIndex === 1) {
      imageSource = imageTwo;
    } else if (imageIndex === 2) {
      imageSource = imageThree;
    } else {
      imageSource = imageFour;
    }

    if (!_.isEmpty(updatedManifest.pageImage)) {
      return <img src={imageSource} alt="" />;
    } else {
      return <>Click here to select a picture...</>;
    }
  };

  const handleImageDivClick = (index: number) => {
    assetContext.setAssetIndex(index);
    assetContext.setAssetTypeId(ASSET_TYPE_ID.IMAGE);
    lessonPageActionsContext.setAssetManagerController({
      isOpen: true,
      mode: "ImagesOnly",
    });
    //assetContext.showBlobUploader(true);
  };

  const handleVideoClick = () => {
    assetContext.setAssetTypeId(ASSET_TYPE_ID.VIDEO);
    lessonPageActionsContext.setAssetManagerController({
      isOpen: true,
      mode: "VideosOnly",
    });
  };

  const handleSCORMClick = () => {
    assetContext.setAssetTypeId(ASSET_TYPE_ID.SCORM);
    lessonPageActionsContext.setAssetManagerController({
      isOpen: true,
      mode: "SCORM",
    });
  };

  const handleSmartObjectClick = () => {
    lessonPagesDispatch({
      type: LessonPagesActions.UPDATE_SELECTED_PANEL,
      payload: "properties",
    });

    assetContext.setAssetTypeId(ASSET_TYPE_ID.SMART_OBJECT);
    lessonPageActionsContext.setAssetManagerController({
      isOpen: true,
      mode: "SmartObject",
    });
  };

  const handle360pClick = () => {
    assetContext.setAssetTypeId(15);
    lessonPageActionsContext.setAssetManagerController({
      isOpen: true,
      mode: "PanoramicOnly",
    });
  };

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      const focusedElement = document.activeElement;
      const activeClassName = focusedElement?.className;
      if (
        !selectedObjects[0] ||
        focusedElement?.nodeName === "INPUT" ||
        focusedElement?.nodeName === "TEXTAREA" ||
        activeClassName === "ql-editor" ||
        activeClassName === "ql-editor ql-blank"
      )
        return;
      switch (e.key) {
        case "ArrowUp":
        case "w": {
          if (moveableRef) moveableRef.request("draggable", { deltaY: -1 }, true);
          if (moveableLineRef) moveableLineRef.request("draggable", { deltaY: -1 }, true);
          break;
        }
        case "ArrowDown":
        case "s": {
          if (moveableRef) moveableRef.request("draggable", { deltaY: 1 }, true);
          if (moveableLineRef) moveableLineRef.request("draggable", { deltaY: 1 }, true);
          break;
        }
        case "ArrowLeft":
        case "a": {
          if (moveableRef) moveableRef.request("draggable", { deltaX: -1 }, true);
          if (moveableLineRef) moveableLineRef.request("draggable", { deltaX: -1 }, true);
          break;
        }
        case "ArrowRight":
        case "d": {
          if (moveableRef) moveableRef.request("draggable", { deltaX: 1 }, true);
          if (moveableLineRef) moveableLineRef.request("draggable", { deltaX: 1 }, true);
          break;
        }
        case "Backspace":
        case "Delete": {
          // while the modal to delete freeForm pages is localized to free form pages, we attach listener for deleting in free form pages
          if (basePageType !== "freeForm") {
            // delete object
            objectsDispatch({
              type: ObjectActionsType.DELETE_OBJECT,
              objectId: selectedObjects[0].objectId,
            });
          }
          // setShowDeleteConfirm(true);
          break;
        }
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [moveableRef, selectedObjects[0]]);

  const addNewAnnotation = (type: string, addFunction: any) => {
    const newAnnotation = {
      objectId: `ann${nanoid()}`,
      type,
      width: 5.8,
      height: 10,
      left: 2 + annotations.length,
      top: 2 + annotations.length,
      zIndex: objectList.length + 1,
      isDisplayed: true,
      displayName: `${type} ${annotations.length + 1}`,
    };

    if (symbolTypes.has(type)) {
      newAnnotation.width = 10;
      newAnnotation.height = 10 * (4 / 3);
      newAnnotation.backgroundColor = "transparent";
      newAnnotation.borderColor = "white";
    }

    switch (type) {
      case "label": {
        newAnnotation.text = "";
        newAnnotation.fontColor = "white";
        newAnnotation.width = 17;
        newAnnotation.height = 5;
        break;
      }
      case "dashedLine":
      case "arrow":
      case "line":
      case "solidLine":
      case "lineArrow":
      case "fillArrow": {
        newAnnotation.width = 10;
        newAnnotation.height = 3;
        break;
      }
      case "circledNumber":
      case "circledLetter":
      case "circleLetter":
      case "squareNumber":
      case "squareLetter": {
        newAnnotation.width = 5.8;
        newAnnotation.height = 10;
        newAnnotation.fontColor = "white";
        newAnnotation.backgroundColor = "#4B4F58";
        newAnnotation.borderColor = "white";
        break;
      }
      case "circle":
      case "triangle":
      case "roundedSquare":
      case "square": {
        newAnnotation.width = 10;
        newAnnotation.height = 10 * (4 / 3);
        newAnnotation.backgroundColor = "transparent";
        newAnnotation.borderColor = "white";
        break;
      }
    }
    // const annotation: IAnnotation = annotationsToAdd[addFunction](type, annotationsState);
    objectsDispatch({
      type: ObjectActionsType.ADD_NEW_OBJECT,
      object: newAnnotation,
    });
  };

  const addSymbol = async (name: string) => {
    if (!_.has(pageManifest, symbols)) {
      pageManifest.symbols = [];
    }
    let incrementor = pageContext.getObjectIncrementor();

    const objectList = new CustomPageObjectList(pageManifest, incrementor);
    const fullList = objectList.fullList;
    const symbol = new ClassSymbol(editableIndex, bounds, pageManifest.symbols, name, fullList, incrementor);
    const newSymbol = symbol.add();
    pageManifest.symbols.push(newSymbol);
    const lastIndex: number = pageManifest.symbols.length - 1;
    setElementType(symbols);
    setEditableIndex(lastIndex);
    setNodeToUpdate(newSymbol);
    pageManifest.objectIncrementor = ++incrementor;
    pageContext.updatePageManifest(pageManifest);
  };

  const checkCurrentElement = (e: any) => {
    if (e.target.id === "baseplayer" || e.target.id === "draggable") {
      setEditableIndex(-1);
      setNodeToUpdate(null);
      setTarget(null);
      setIsPropertyBoxShown(false);
    }
    objectsDispatch({ type: ObjectActionsType.SET_SELECTED_OBJECT, payload: null });
    setMiscUI({
      type: "SET_SELECTED_MASK_ID",
      payload: "",
    });
    // tablesDispatch({ action: "setSelectedTable", payload: [undefined] });
  };

  // const sendToBack = () => {
  //   const pm = _.cloneDeep(pageManifest);
  //   const objectId = nodeToUpdate?.objectId ?? selectedObjectState.objectId;
  //   if (!objectId) return;
  //   const zIndexes = zIndexShifter(pm, objectId, "back");

  //   runOnObjects(pm, "all", (object: any) => {
  //     object.zIndex = zIndexes.find((z: Z) => z.objectId === object.objectId)!.zIndex;
  //   });
  //   if (nodeToUpdate || nodeToUpdate?.zIndex) {
  //     const objectList = getObjectsFromManifest(pm, "all");
  //     nodeToUpdate.zIndex = objectList.find((x: any) => x.objectId === nodeToUpdate.objectId)!.zIndex;
  //     const temp = pbFunctions.changePropertyBox(nodeToUpdate, propertyBox);
  //     pbFunctions.createPropertyBox(temp, pageDims as DOMRect, setPropertyBox);
  //     setNodeToUpdate(nodeToUpdate);
  //   }
  //   pageContext.updatePageManifest(pm);
  // };

  function removeObjectDispatch() {
    return removeSelected;
  }

  function removeSelected() {
    objectsDispatch({ type: ObjectActionsType.DELETE_OBJECT, objectId: selectedObjects[0].objectId });
    setNodeToUpdate({});
    setEditableIndex(-1);
    // setPreviousIndex(-1);
    setTarget(null);
    setShowConfirm(false);
    setElementType("annotations");
    setIsPropertyBoxShown(false);
    pageContext.updatePageManifest(pageManifest);
  }

  if (isLoading) return <></>;
  else if (basePageType === "freeForm") {
    return (
      <BaseFreeForm
        // {...pageObject}
        quillRef={quillRef}
        selectedFontSize={selectedFontSize}
        handleImageDivClick={handleImageDivClick}
        handleVideoClick={handleVideoClick}
        handleSCORMClick={handleSCORMClick}
        handleSmartObjectClick={handleSmartObjectClick}
        handle360pClick={handle360pClick}
        setSelectedFontSize={setSelectedFontSize}
      />
    );
  } else {
    return (
      <React.Fragment>
        <div className="contentBox">
          <div className="free-form" id="base-page-wrapper">
            <WYSIWYGToolbar
              areAnnotationsShown={true}
              areMajorsDisabled={true}
              attributes={nodeToUpdate}
              editableIndex={editableIndex}
              elementType={elementType}
              isFreeForm={false}
              isVideoButtonShown={false}
              isSCORMButtonShown={false}
              showFontIcons={false}
              addNewAnnotation={addNewAnnotation}
              addSymbolOLD={addSymbol}
              // addNewImageBox={addNewImageBox}
              // addNewTextBox={addNewTextBox}
              // addNewVideo={addNewVideo}
              // addNewSCORM={addNewSCORM}
              removeElement={setShowConfirm}
              // sendToBack={sendToBack}
              setOldPropertyBoxIsShown={setIsPropertyBoxShown}
              permissions={permissions}
              // updateAttributes={updateAttributes}
              // handleFormatChange={handleFormatChange}
            />
            <div className="draggable-aspect-ratio-page-container" id="draggable" onMouseDown={checkCurrentElement}>
              <div
                id="pageplayerarea"
                className="page-player-area"
                ref={playerRef}
                style={
                  pageContext.pageManifest.theme
                    ? {
                        background: determinePageBG(pageContext.pageManifest.theme),
                      }
                    : {}
                }
                onMouseDown={(e) => {
                  selectedObjectDispatch({
                    type: "SET_OBJECT_ID",
                    payload: null,
                  });
                }}
              >
                <div
                  id="baseplayer"
                  className="baseplayer"
                  // ref={playerRef}
                  onMouseDown={(e) => {
                    selectedObjectDispatch({
                      type: "SET_OBJECT_ID",
                      payload: null,
                    });
                  }}
                  style={{
                    display: "block",
                  }}
                >
                  <Annotations />
                  <AbstractMoveable pageDims={pageDims} />
                  {selectedObjects.length === 1 && <LineMoveable pageDims={pageDims} />}
                  <DisplayBasePageType
                    pageObject={{
                      placeholderText,
                      textBlockIndex,
                      updatedManifest,
                      moduleRTE,
                      quillRef,
                      assignNewText,
                      displayImage,
                      handleImageDivClick,
                      handleSelectionChange,
                      setTextBlockIndex,
                      isModalShown,
                      setIsModalShown,
                    }}
                    basePageType={basePageType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDelete show={showConfirm} cancel={setShowConfirm} confirm={removeObjectDispatch()} />
      </React.Fragment>
    );
  }
};

interface IBaseAsset {
  assetVersionId: number;
  blobPath: string;
}

export function basePageManifestAssetCollector(pageManifest: IBasePage) {
  const assets: IDandPath[] = [];
  for (const [key, value] of Object.entries(pageManifest)) {
    if (key === "pageImage") {
      value.forEach((image: any) => {
        if ("assetVersionId" in image && "imagePath" in image) {
          const { assetVersionId, imagePath } = image;
          const temp = new IDandPath(assetVersionId, imagePath);
          assets.push(temp);
        }
      });
    } else if (key === "pageScormObject" && value) {
      value.forEach((scormObj: IBaseAsset) => {
        if ("assetVersionId" in scormObj && "blobPath" in scormObj) {
          const { assetVersionId, blobPath } = scormObj;
          const temp = new IDandPath(assetVersionId, blobPath);
          assets.push(temp);
        }
      });
    } else if (key === "video") {
      if (value && "assetVersionId" in value && "path" in value) {
        const { assetVersionId, path } = value;
        const temp = new IDandPath(assetVersionId, path);
        assets.push(temp);
      }
    } else if (key === "Audio") {
      assets.push(...crawlAudioArray(value));
    } else if (["narrationAudios", "audios", "audioEffects"].includes(key)) {
      assets.push(...crawlAudios(value));
    } else if (key === "panoramicList") {
      (value as IBasePage["panoramicList"]).forEach((pano) => {
        if ("assetVersionId" in pano && "cubeMap" in pano && pano.cubeMap !== null) {
          const { assetVersionId, cubeMap, assetBlobPath } = pano;
          cubeMap.forEach((image) => {
            const imagePath = assetBlobPath + "/" + image.path;
            const temp = new IDandPath(assetVersionId, imagePath);
            assets.push(temp);
          });
        }
      });
    }
  }
  return assets;
}
