import "./WYSIWYGToolbar.css";
import React, { useState, useEffect } from "react";
import { IAnnotation } from "../Annotation/models/IAnnotation";
import { IBasicPageAttributes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import MajorElements from "./components/MajorElements";
import { pageType, PageTypeList } from "../../types/Objects";
// import SymbolsButton from './components/SymbolsButton';
import ISymbolStyle from "../Symbol/models/ISymbolStyle";
import AnnotationButton from "./components/AnnotationButton";
import FontColorButton2 from "./components/FontColorButton2";
import FillButton from "./components/FillButton";
import BorderColorButton from "./components/BorderColorButton";
import FontStyleButtons from "./components/FontStyleButtons";
import AlignButtons from "./components/AlignButtons";
import DeleteButton from "./components/DeleteButton";
import SendToBack from "./components/SendToBackButton";
import LineWidthButton from "./components/LineWidthButton";
import { useInteracitvityHotspotState } from "../../contexts/InteractivityHotspotProvider";
import { useTablesDataState } from "../Tables/TablesDataProvider";
import EditTableMenu from "../../components/Tables/EditTableMenu";
import { useObjectsState } from "../../contexts/ObjectsProvider";
import { symbolTypes, annotationTypes, fontAnnotation, lineAnnotation, shapeAnnotation } from "../../const";
import { useLessonPagesState } from "../../contexts/LessonPagesProvider/LessonPagesProvider";
import CopyButton from "./components/CopyButton";
import PasteButton from "./components/PasteButton";

// import QuillToolbar from 'react-quill'

type AddToBaseType = {
  areAnnotationsShown: boolean;
  areMajorsDisabled?: boolean;
  attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle;
  editableIndex: number;
  elementType?: string;
  isFreeForm?: boolean;
  isVideoButtonShown?: boolean;
  isSCORMButtonShown?: boolean;
  showFontIcons: boolean | undefined;
  rightSideActions?: any;
  addNewAnnotation: (value: string, funcName: any) => void;
  addNewImageBox?: () => void;
  addNewTable?: () => void;
  addNewTextBox?: () => void;
  addNewVideo?: () => void;
  addNewSCORM?: () => void;
  addNewSmartObject?: () => void;
  addNewPanoramic?: () => void;
  addNewMaskingShape?: () => void;
  addSymbolOLD: (name: string) => void;
  removeElement: (bool: boolean) => void;
  sendToBack: () => void;
  // updateAttributes: (attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) => void
  handleFormatChange(e: any, funct: any, value: any, keyValue: any): void;
  permissions?: { [key: string]: boolean };
  setOldPropertyBoxIsShown?: (value: boolean) => void;
};

export type FormatButtonType = {
  handleFormatChange(e: any, funct: any, value: any, keyValue: any): void;
};

const WYSIWYGToolbar = (props: AddToBaseType) => {
  const {
    isSCORMButtonShown,
    addNewSCORM,
    addNewPanoramic,
    addNewMaskingShape,
    areAnnotationsShown,
    areMajorsDisabled,
    attributes,
    editableIndex,
    elementType,
    isVideoButtonShown,
    showFontIcons,
    addNewAnnotation,
    addNewImageBox,
    addSymbolOLD,
    addNewTextBox,
    addNewTable,
    addNewVideo,
    addNewSmartObject,
    handleFormatChange,
    removeElement,
    sendToBack,
    isFreeForm,
    permissions,
    setOldPropertyBoxIsShown,
    rightSideActions,
  } = props;
  const [pageType, setPageType] = useState<pageType | null>(null);
  const { selectedObjects, selectedObjectId } = useObjectsState();
  const lessonPagesState = useLessonPagesState();
  const { currentLessonPage } = lessonPagesState;

  useEffect(() => {
    if (currentLessonPage && currentLessonPage.pagePlayerType !== pageType) {
      setPageType(currentLessonPage.pagePlayerType ?? pageType);
    }
  }, [currentLessonPage]);

  const canShowFillColorBtn: boolean =
    pageType !== PageTypeList.Base &&
    !lineAnnotation.has(selectedObjects[0]?.type ?? attributes?.type) &&
    (annotationTypes.has(selectedObjects[0]?.type || attributes?.type) ||
      symbolTypes.has(selectedObjects[0]?.type || attributes?.type) ||
      selectedObjects[0]?.type === "textBlock" ||
      attributes?.type) &&
    lineAnnotation.has(selectedObjects[0]?.type || attributes?.type) === false;
  const canShowFontColorBtn: boolean = fontAnnotation.has(selectedObjects[0]?.type || attributes?.type);
  const canShowLineWidthBtn: boolean =
    pageType !== PageTypeList.Base &&
    (annotationTypes.has(selectedObjects[0]?.type || attributes?.type) ||
      lineAnnotation.has(selectedObjects[0]?.type || attributes?.type) ||
      symbolTypes.has(selectedObjects[0]?.type || attributes?.type) ||
      selectedObjects[0]?.type === "textBlock" ||
      attributes?.type === "textBlock");

  const canShowBorderColorBtn: boolean =
    pageType !== PageTypeList.Base &&
    (fontAnnotation.has(selectedObjects[0]?.type || attributes?.type) ||
      symbolTypes.has(selectedObjects[0]?.type || attributes?.type) ||
      lineAnnotation.has(selectedObjects[0]?.type || attributes?.type) ||
      shapeAnnotation.has(selectedObjects[0]?.type || attributes?.type) ||
      selectedObjects[0]?.type === "textBlock" ||
      selectedObjects[0]?.type === "label" ||
      attributes?.type === "textBlock" ||
      attributes?.type === "label" ||
      attributes?.type === "symbol");

  const interacitvityHotspotState = useInteracitvityHotspotState();
  const TablesState = useTablesDataState();
  return (
    <React.Fragment>
      <div className="wysiwyg-toolbar">
        <div id="toolbar-left">
          <MajorElements
            areMajorsDisabled={areMajorsDisabled}
            isFreeForm={isFreeForm}
            isVideoButtonShown={isVideoButtonShown}
            isSCORMButtonShown={isSCORMButtonShown}
            addNewTextBox={addNewTextBox}
            addNewTable={addNewTable}
            addNewImageBox={addNewImageBox}
            addNewVideo={addNewVideo}
            addNewSCORM={addNewSCORM}
            addNewSmartObject={addNewSmartObject}
            permissions={props.permissions}
            setOldPropertyBoxIsShown={props.setOldPropertyBoxIsShown}
            addNewPanoramic={addNewPanoramic}
            addNewMaskingShape={addNewMaskingShape}
          />
          {areAnnotationsShown ? (
            <AnnotationButton addNewAnnotation={addNewAnnotation} addSymbolOLD={addSymbolOLD} />
          ) : (
            <></>
          )}
          {showFontIcons ? (
            <React.Fragment>
              <FontStyleButtons attributes={attributes} handleFormatChange={handleFormatChange} />
              <AlignButtons attributes={attributes} handleFormatChange={handleFormatChange} />
            </React.Fragment>
          ) : (
            <></>
          )}

          {canShowFontColorBtn ? <FontColorButton2 handleFormatChange={handleFormatChange} /> : <></>}
          {canShowBorderColorBtn ? <BorderColorButton handleFormatChange={handleFormatChange} /> : <></>}
          {canShowFillColorBtn ? <FillButton handleFormatChange={handleFormatChange} /> : <></>}
          {canShowLineWidthBtn ? (
            <LineWidthButton attributes={attributes as IAnnotation} handleFormatChange={handleFormatChange} />
          ) : (
            <></>
          )}
          <EditTableMenu></EditTableMenu>
          {selectedObjects[0]?.type !== "hotspot" && selectedObjects[0] ? <SendToBack /> : <></>}
          {0 <= editableIndex ? <SendToBack val={attributes} /> : <></>}
          {selectedObjects[0] ? (
            <>
              <hr className="wysiwyg-toolbar-divider" />
              <CopyButton />
              <PasteButton />
            </>
          ) : (
            <>
              <hr className="wysiwyg-toolbar-divider" />
              <PasteButton />
            </>
          )}
        </div>

        <div id="toolbar-right">
          {rightSideActions && (
            <div className="right-side-wysiwyg-container">
              <DeleteButton removeElement={removeElement} />
              <hr className="wysiwyg-toolbar-divider" />
              <props.rightSideActions rando="billy" />
            </div>
          )}
          {selectedObjectId.length ||
          interacitvityHotspotState.currentlySelectedHotspot ||
          TablesState.selectedTable !== undefined ||
          lessonPagesState.currentLessonPage?.name === "FMS" ? (
            <DeleteButton removeElement={removeElement} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGToolbar;
