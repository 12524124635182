import { useCallback } from "react";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { SVGCirlce } from "./components/SVGCircle";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import { SVGLineArrow } from "./components/SVGLineArrow";
import { SVGCircledLetter } from "./components/SVGCircledLetter";
import { SVGCircledNumber } from "./components/SVGCircledNumber";
import { SVGLine } from "./components/SVGLine";
import { SVGSquareNumber } from "./components/SVGSquareNumber";
import { SVGSquareLetter } from "./components/SVGSquareLetter";
import { SVGTriangle } from "./components/SVGTriangle";
import { AnnotationLabel } from "./components/AnnotationLabel";
import { SVGSquare } from "./components/SVGSquare";
import { SVGDashedLine } from "./components/SVGDashedLine";
import { SVGPointArrow } from "./components/SVGPointArrow";
import { SVGRoundedSquare } from "./components/SVGRoundedSquare";
import { SVGAircraftSymbol1 } from "./components/AircraftSymbol1";
import { SVGAircraftSymbol } from "./components/AircraftSymbol";
import { SVGCoursePointer1 } from "./components/CoursePointer1";
import { SVGCoursePointer2 } from "./components/CoursePointer2";
import { SVGCoursePointer3 } from "./components/CoursePointer3";
import { SVGCoursePointer4 } from "./components/CoursePointer4";
import { SVGCurrentTrack } from "./components/CurrentTrack";
import { SVGDistanceSymbol } from "./components/DistanceSymbol";
import { SVGCurrentHeadingPointer } from "./components/CurrentHeadingPointer";
import { SVGDMETACAN } from "./components/DMETACAN";
import { SVGExpandedCompass } from "./components/ExpandedCompass";
import { SVGGlideSlopePointer } from "./components/GlideSlopePointer";
import { SVGHeadingBug } from "./components/HeadingBug";
import { SVGILSLocalizer } from "./components/ILSLocalizer";
import { SVGLineM } from "./components/LineM";
import { SVGTrackLineRangeScale } from "./components/TrackLineRangeScale";
import { SVGVOR } from "./components/VOR";
import { SVGVOR1PointerHead } from "./components/VOR1PointerHead";
import { SVGVOR2PointerHead } from "./components/VOR2PointerHead";
import { SVGVOR1PointerTail } from "./components/VOR1PointerTail";
import { SVGVOR2PointerTail } from "./components/VOR2PointerTail";
import { SVGVORTAC } from "./components/VORTAC";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { FreeFormPoly } from "../Annotation/types/FreeFormPoly";

export interface AnnotationProps {
  type: string;
  onMouseDown: (event: React.MouseEvent, annotationId: string) => void;
  objectId: string;
  transform: any;
  top: number;
  left: number;
  width: number;
  height: number;
  isDisplayed: boolean;
  zIndex: number;
  borderColor: string;
  strokeWidth: number;
  backgroundColor: string;
  opacity?: number;
  fontColor?: string;
  text?: string;
  rotate?: number;
  ghost: boolean;
  blurIntensity: number;
}

export interface SVGAnnotationProps {
  objectId: AnnotationProps["objectId"];
  onMouseDown: AnnotationProps["onMouseDown"];
  x: number;
  y: number;
  isDisplayed: AnnotationProps["isDisplayed"];
  height: AnnotationProps["height"];
  width: AnnotationProps["width"];
  zIndex: AnnotationProps["zIndex"];
  opacity: AnnotationProps["opacity"];
  borderColor: AnnotationProps["borderColor"];
  strokeWidth: AnnotationProps["strokeWidth"];
  backgroundColor: AnnotationProps["backgroundColor"];
  rotate?: AnnotationProps["rotate"];
  blurIntensity?: AnnotationProps["blurIntensity"];
}

export function Annotations() {
  const { annotations } = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const onMouseDown: AnnotationProps["onMouseDown"] = useCallback((e, annotationId) => {
    e.stopPropagation();
    if (!annotationId) return;
    let type = ObjectActionsType.SET_SELECTED_OBJECT;
    if (e.ctrlKey) {
      type = ObjectActionsType.ADD_SELECTED_OBJECT;
    }
    objectsDispatch({
      type,
      payload: { objectId: annotationId },
    });
  }, []);

  return (
    <>
      {annotations.map((annotation: any) => {
        return (
          <Annotation
            key={annotation.objectId}
            type={annotation.type}
            onMouseDown={onMouseDown}
            objectId={annotation.objectId}
            transform={annotation.transform}
            top={annotation.top}
            left={annotation.left}
            width={annotation.width}
            height={annotation.height}
            isDisplayed={annotation.isDisplayed}
            zIndex={annotation.zIndex}
            borderColor={annotation.borderColor}
            strokeWidth={annotation.strokeWidth}
            backgroundColor={annotation.backgroundColor}
            fontColor={annotation.fontColor}
            text={annotation.text}
            rotate={annotation.rotation ?? annotation.transform?.rotate ?? 0}
            opacity={annotation.opacity ?? 1}
            ghost={annotation.ghost}
            blurIntensity={annotation.blurIntensity}
          />
        );
      })}
    </>
  );
}

export function Annotation({
  type,
  onMouseDown,
  objectId,
  top,
  left,
  width,
  height,
  isDisplayed,
  zIndex,
  borderColor,
  strokeWidth,
  backgroundColor,
  fontColor,
  text,
  rotate,
  opacity,
  ghost,
  blurIntensity,
}: AnnotationProps) {
  const {
    position: [x, y],
    size: [w, h],
    opacity: opacityValue,
    rotation,
  } = useObjectPosition(objectId, top, left, width, height, rotate, opacity);
  const isInTimeline = useObjectIsInTime(objectId);
  const commonProps: SVGAnnotationProps = {
    onMouseDown,
    objectId,
    x: x ?? 0,
    y: y ?? 0,
    width: w ?? 0,
    height: h ?? 0,
    isDisplayed,
    zIndex,
    borderColor,
    strokeWidth,
    backgroundColor,
    opacity: isInTimeline ? opacityValue ?? 1 : ghost ? 0.25 : 0,
    rotate: rotation,
    blurIntensity,
  };
  switch (type) {
    case "fillArrow":
    case "pointArrow":
    case "arrow":
      return <SVGLineArrow {...commonProps} />;
    case "circledNumber":
      return <SVGCircledNumber {...commonProps} fontColor={fontColor} />;
    case "circledLetter":
    case "circleLetter":
      return <SVGCircledLetter {...commonProps} fontColor={fontColor} />;
    case "line":
    case "solidLine":
      return <SVGLine {...commonProps} />;
    case "squareNumber":
      return <SVGSquareNumber {...commonProps} fontColor={fontColor} />;
    case "squareLetter":
      return <SVGSquareLetter {...commonProps} fontColor={fontColor} />;
    case "label":
      return <AnnotationLabel {...commonProps} text={text ?? "None"} />;
    case "dashedLine":
      return <SVGDashedLine {...commonProps} />;
    case "lineArrow":
      return <SVGPointArrow {...commonProps} />;
    case "square":
      return <SVGSquare {...commonProps} />;
    case "roundedSquare":
      return <SVGRoundedSquare {...commonProps} />;
    case "triangle":
      return <SVGTriangle {...commonProps} />;
    case "circle":
      return <SVGCirlce {...commonProps} />;
    case "freeFormPoly":
      return <FreeFormPoly {...commonProps} />;
    case "aircraft":
      return <SVGAircraftSymbol {...commonProps} />;
    case "aircraft_1":
      return <SVGAircraftSymbol1 {...commonProps} />;
    case "course_pointer_1":
      return <SVGCoursePointer1 {...commonProps} />;
    case "course_pointer_2":
      return <SVGCoursePointer2 {...commonProps} />;
    case "course_pointer_3":
      return <SVGCoursePointer3 {...commonProps} />;
    case "course_pointer_4":
      return <SVGCoursePointer4 {...commonProps} />;
    case "current_track":
      return <SVGCurrentTrack {...commonProps} />;
    case "current_heading":
      return <SVGCurrentHeadingPointer {...commonProps} />;
    case "distance":
      return <SVGDistanceSymbol {...commonProps} />;
    case "dme_tacan":
      return <SVGDMETACAN {...commonProps} />;
    case "expanded_compass":
      return <SVGExpandedCompass {...commonProps} />;
    case "glideslope":
      return <SVGGlideSlopePointer {...commonProps} />;
    case "heading_bug":
      return <SVGHeadingBug {...commonProps} />;
    case "ils_localizer":
      return <SVGILSLocalizer {...commonProps} />;
    case "line_m":
      return <SVGLineM {...commonProps} />;
    case "track_line_range":
      return <SVGTrackLineRangeScale {...commonProps} />;
    case "vortac":
      return <SVGVORTAC {...commonProps} />;
    case "vor":
      return <SVGVOR {...commonProps} />;
    case "vor_1_head":
      return <SVGVOR1PointerHead {...commonProps} />;
    case "vor_2_head":
      return <SVGVOR2PointerHead {...commonProps} />;
    case "vor_1_tail":
      return <SVGVOR1PointerTail {...commonProps} />;
    case "vor_2_tail":
      return <SVGVOR2PointerTail {...commonProps} />;
    default:
      return null;
  }
}
