import React from "react";
import { SVGAnnotationProps } from "../index";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import { HighlightAnnotation } from "../HighlightAnnotation";
import { BlurComponent } from "./BlurComponent";

export function SVGSquare({
  x,
  y,
  objectId,
  onMouseDown,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
  blurIntensity,
  isDisplayed,
}: SVGAnnotationProps) {
  const { annotations } = useObjectsState();
  const squareAnnotations = annotations.filter((annotation: any) => annotation.type === "squareLetter");
  const index = squareAnnotations.findIndex((annotation: any) => annotation.objectId === objectId);
  const id = `square-${index}`;
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  const onMouseDownHandler = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement>) => {
    onMouseDown(e, objectId);
  };
  return (
    <>
      <BlurComponent
        width={width}
        height={height}
        transformString={transformString}
        objectId={objectId}
        blurIntensity={blurIntensity ?? 0}
        backgroundColor={backgroundColor}
        opacity={opacity ?? 0}
        zIndex={zIndex}
        id={id}
        onMouseDown={onMouseDownHandler}
        strokeWidth={strokeWidth}
        borderColor={borderColor}
        isDisplayed={isDisplayed}
      />
      <HighlightAnnotation objectId={objectId} id="glow-rect-for-square" />
    </>
  );
}
