import { ImageSwapPanel, SelectPanel } from "..";
import styles from "../AdvancedPanel.module.css";
import { SmartObjectHotspot, useMetaVariableStore } from "../../../../store";
import { TimelineButton } from "./TimeLineButton";
import { useAdvancedPanelTimeline } from "../useAdvancedPanelTimeline";
import { useTimeline } from "../../../../../../contexts/TimelineProvider/TimelineProvider";
import { useObjectsState } from "../../../../../../contexts/ObjectsProvider";
import { Collapse } from "./Collapse";
import { getHotspotFreezeTimelineKey } from "../../../../utils";

interface ImageSwapProps {
  data: ImageSwapPanel;
  onChange: () => void;
}

export function ImageSwap({ data, onChange }: ImageSwapProps) {
  const value = useMetaVariableStore((s) => s.metaVariables[data.key] as string);
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const setMetadata = useMetaVariableStore((s) => s.setMetaData);
  const { rate, animate } = useMetaVariableStore((s) => s.metaVariablesData[data.key]);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObjectId = useObjectsState().selectedObjects[0]?.objectId;
  const { isObjectInTimeline, handleTimelineClick, metaVariableInCurrentTime } = useAdvancedPanelTimeline(data.key);

  const handleSelectPanelChange = (key: string, value: any) => {
    set(key, value);

    if (animate) {
      setMetadata(data.key, "isAnimating", false);
    }

    if (isObjectInTimeline) {
      upsertAnimatedMetaVariable(key, currentTime, value, selectedObjectId, false, { isAnimating: false });
    }
    onChange();
  };

  const handleAnimate = () => {
    setMetadata(data.key, "isAnimating", true);

    if (isObjectInTimeline) {
      upsertAnimatedMetaVariable(data.key, currentTime, value, selectedObjectId, false, { isAnimating: true });
    }
    onChange();
  };

  const handleRateChange = (value: string) => {
    const rate = Number(value) || 500;
    setMetadata(data.key, "rate", rate);
    onChange();
  };

  const onTimelineClick = () => {
    handleTimelineClick(value);
    onChange();
  };

  return (
    <Collapse title={data.name}>
      <div className={styles.group} key={data.name}>
        <div className={styles.timelineWrapper}>
          <div className={styles.selectWrapper}>
            <SelectPanel
              key={data.key}
              data={data}
              selectedValue={value}
              handleChange={handleSelectPanelChange}
              handleAnimate={handleAnimate}
            />
            {data.animate && (
              <div className={styles.textGroup}>
                Rate:
                <input
                  type="number"
                  min={1}
                  max={1000}
                  value={rate as number}
                  onChange={(e) => {
                    handleRateChange(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
          {isObjectInTimeline && <TimelineButton onClick={onTimelineClick} active={!!metaVariableInCurrentTime} />}
        </div>

        {data.hotspots?.map((hotspot, idx) => {
          return (
            <HotSpots
              key={hotspot.id}
              number={idx + 1}
              data={hotspot}
              isObjectInTimeline={!!isObjectInTimeline}
              onChange={onChange}
            />
          );
        })}
      </div>
    </Collapse>
  );
}

interface HotSpotProps {
  data: SmartObjectHotspot;
  number: number;
  isObjectInTimeline: boolean;
  onChange: () => void;
}

const HotSpots = ({ data, number, isObjectInTimeline, onChange }: HotSpotProps) => {
  const { displayKey, enabledKey, target } = data;
  const name = normalizeName(data.name) || number;

  return (
    <>
      <div className={styles.timelineWrapper}>
        <div className={styles.hotspotTitle}>Hot spot {name}</div>
        {isObjectInTimeline && <div className={styles.emptyBox}></div>}
      </div>
      <HotSpotCheckBox target={target} metaVariableKey={displayKey} label="Display" onChange={onChange} />
      <HotSpotCheckBox target={target} metaVariableKey={enabledKey} label="Enabled" onChange={onChange} />
    </>
  );
};

interface HotSpotCheckboxProps {
  metaVariableKey: string;
  target: string;
  label: string;
  onChange: () => void;
}

const HotSpotCheckBox = ({ metaVariableKey, label, onChange, target }: HotSpotCheckboxProps) => {
  const value = useMetaVariableStore((s) => s.metaVariables[metaVariableKey]);
  const set = useMetaVariableStore((s) => s.setMetaVariable);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const selectedObjectId = useObjectsState().selectedObjects[0]?.objectId;
  const keys = getHotspotFreezeTimelineKey(selectedObjectId);
  const freezeValue = useMetaVariableStore((s) => s.metaVariables[keys.key]);
  const { isObjectInTimeline, handleTimelineClick, metaVariableInCurrentTime } =
    useAdvancedPanelTimeline(metaVariableKey);

  const handleCheckboxClick = () => {
    const newValue = !value;
    set(metaVariableKey, newValue);
    if (isObjectInTimeline) {
      const metadata =
        typeof freezeValue === "boolean" && metaVariableKey.includes("enabled")
          ? { resumeOnClick: freezeValue, target }
          : undefined;
      upsertAnimatedMetaVariable(metaVariableKey, currentTime, newValue, selectedObjectId, false, metadata);
    }
    onChange();
  };

  const onTimelineClick = () => {
    const metadata =
      typeof freezeValue === "boolean" && metaVariableKey.includes("enabled")
        ? { resumeOnClick: freezeValue, target }
        : undefined;
    handleTimelineClick(value, false, metadata);
  };

  return (
    <div className={styles.timelineWrapper}>
      <div className={styles.checkboxWrapper}>
        <input type="checkbox" checked={!!value} onChange={handleCheckboxClick} />
        <p>{label}</p>
      </div>
      {isObjectInTimeline && <TimelineButton active={!!metaVariableInCurrentTime} onClick={onTimelineClick} />}
    </div>
  );
};

const normalizeName = (name?: string) => (name ? name.toLowerCase().replaceAll("_", " ") : "");
