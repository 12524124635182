import {
  Audio,
  AudioInputTypes,
  AudioManagerEvent,
  AudioSequence,
  AudioSource,
  EAudioManagerEvents,
  EAudioManagerMode,
  EAudioManagerStates,
  LoadableAudio,
  LoadedAudio,
} from "./types";
import { nanoid } from "../../lib/nanoId";
import { EventEmitter } from "eventemitter3";
// import { createLogger } from "../../utils";

// const log = createLogger("AudioManager", {
//   background: "black",
//   color: "green",
//   pretty: true,
// });

class AudioManager extends EventEmitter<AudioManagerEvent> {
  // AudioContext
  context: AudioContext;
  // Scheduler
  scheduleQueue: Audio[] = [];
  schedulerId?: NodeJS.Timeout;
  schedulerInterval = 25; // 25 ms (runs 40 times per second)
  schedulerLookAhead = 0.2; // consider 50 ms ahead
  // State
  playerMode: EAudioManagerMode = EAudioManagerMode.SINGLE;
  playerState: EAudioManagerStates = EAudioManagerStates.STOPPED;
  currentPlaying: Set<Audio> = new Set();
  lastCurrentTime = 0;
  startTime = 0;
  audios: AudioSource[] = [];
  constructor() {
    super();

    this.context = new window.AudioContext();
    void this.context.suspend();
  }

  getCurrentTime() {
    return this.context.currentTime - this.lastCurrentTime + this.startTime;
  }

  /**
   * [STATE MANAGEMENT]
   */

  private setPlayerState(managerState: EAudioManagerStates) {
    this.playerState = managerState;
  }

  // Audio objects Management
  getAudioById(audioId: string): AudioSource | null {
    return this.audios.find((audio) => audio.objectId === audioId) ?? null;
  }
  getAudioIndexById(audioId: string): number | null {
    return this.audios.findIndex((audio) => audio.objectId === audioId) ?? null;
  }
  // Note: we already receive, start/end etc... at this point
  //  should we save these info?
  async loadAudio(...audios: LoadableAudio[]) {
    const loadedAudios = [];
    this.emit(EAudioManagerEvents.LOADING_START);

    for (const audio of audios) {
      this.emit(EAudioManagerEvents.AUDIO_LOAD_START, audio);
      const audioBuffer = audio.buffer ?? (await this.fetchAudio(audio.input))[0];
      this.emit(EAudioManagerEvents.AUDIO_LOAD_FINISH, audio);

      const audioObject = {
        ...audio,
        objectId: audio.objectId ?? nanoid(),
        buffer: audioBuffer,
        start: audio.start ?? 0,
        end: audio?.end && audio?.end > 0 ? audio.end : audioBuffer.duration,
        duration: audio.duration ?? audioBuffer.duration,
      };

      this.addAudio(audioObject);
      loadedAudios.push(audioObject);
    }

    this.emit(EAudioManagerEvents.LOADING_FINISH);
    return loadedAudios;
  }

  addAudio(audio: LoadedAudio) {
    this.audios.push(audio);

    this.emit(EAudioManagerEvents.AUDIO_ADD, audio);
    this.emit(EAudioManagerEvents.UPDATE, this.audios);
  }

  removeAudio(audioId: string) {
    const index = this.getAudioIndexById(audioId);

    if (index !== null) {
      const audioToDelete = { ...this.audios[index] };
      delete this.audios[index];
      this.emit(EAudioManagerEvents.AUDIO_DELETE, audioToDelete);
      this.emit(EAudioManagerEvents.UPDATE, this.audios);
    }
  }

  /**
   * This is the core function of the global player
   * This functions is running 40 times per second as long as this.isPlaying is true
   * To start it, just make a single call to "schedule" after setting the isPlaying flag to true.
   */
  checkForScheduledAudios() {
    // Keep the queue safe from mutations
    const queue = [...this.scheduleQueue];

    if (queue.length) {
      // Time since we resume/started to play
      // Lets also limit it to 2 decimal plates 0.00
      const currentTime = Number(this.getCurrentTime().toFixed(2));
      // Go through queue
      for (const scheduledAudio of queue) {
        // Individual conditions for better visualization

        // Skip running audios
        if (this.currentPlaying.has(scheduledAudio)) {
          continue;
        }
        // Skip past audios
        if (scheduledAudio.start + scheduledAudio.end < currentTime) {
          continue;
        }
        // Skip far ahead audios
        if (scheduledAudio.start - this.schedulerLookAhead > currentTime) {
          continue;
        }
        // Prevent it from playing twice
        // this.scheduleQueue[index].playing = true;
        this.playTrack(scheduledAudio);
      }
    }
  }

  // Checks if there are any sounds close to our current position and prepare to play them
  scheduler() {
    // We could implement auto stop to prevent context from running endlessly
    if (this.playerState !== EAudioManagerStates.PLAYING) {
      return;
    }
    // Check if there is anything that should be played
    this.checkForScheduledAudios();
    // currentTime - this.lastCurrentTime => Brings the value since last play!
    this.emit(EAudioManagerEvents.TICK, this.getCurrentTime());
    this.schedulerId = setTimeout(this.scheduler.bind(this), this.schedulerInterval);
  }

  /**
   * Mount the play queue based on the starting time
   * This way we skip scheduling audios that won't be played anymore
   */
  mountQueue(audioSequence: AudioSequence[]) {
    let audioSources: Audio[] = [];

    for (const audio of audioSequence) {
      const audioSource = this.getAudioById(audio.objectId);

      if (audioSource) {
        audioSources.push({
          ...audio,
          ...audioSource,
          start: audio.start ?? 0,
          end: audio.end ?? (audio.start ?? 0) + audioSource.buffer.duration,
          duration: audioSource.buffer.duration,
        });
      }
    }
    // Audios in order to make it easier to filter/manipulate
    audioSources = audioSources.sort((A, B) => {
      return A.start > B.start ? 1 : -1;
    });
    // Remove past audios
    audioSources = audioSources.filter((audio) => audio.end >= this.getCurrentTime());
    // Only audios that should either start now or in the future
    this.scheduleQueue = [...audioSources];
  }

  /**
   * If you do not clear the queue or at least call to buffer.disconnect
   * The next time you play this audio it will play twice as loud (basically it will sum up 2 audios playing)
   * This is strictly necessary before any pause/stop/resume/play workflow
   */
  clearQueue() {
    this.scheduleQueue.forEach((scheduledAudio) => {
      this.stopTrack(scheduledAudio);
    });

    this.scheduleQueue = [];
  }

  /**
   * Start playing scheduled audios, possibly from a different point in time
   */
  public play(audio: string | AudioSequence[], startTime = 0): void {
    // If you hit play from PAUSED state or from PLAYING state, lets make sure to stop all audios
    // basically a redo
    if (this.playerState !== EAudioManagerStates.STOPPED) {
      this.reset();
    }
    // 0 or some time in the future
    this.startTime = startTime;
    // Kick context
    // Change player state
    this.setPlayerState(EAudioManagerStates.PLAYING);
    // Resume context
    void this.context.resume();
    // Notify
    this.emit(EAudioManagerEvents.PLAY, this.getCurrentTime());
    // Decide what and how to play
    if (Array.isArray(audio) && audio.length > 0) {
      this.playerMode = EAudioManagerMode.SEQUENCE;
      // Copy the audio List
      const audioList = [...audio];
      // Mount queue
      this.mountQueue(audioList);
      // Kick off scheduler
      this.scheduler();
    } else if (typeof audio === "string") {
      this.playerMode = EAudioManagerMode.SINGLE;
      // Find audio
      const audioSource = this.getAudioById(audio);

      if (audioSource) {
        this.playTrack(
          {
            ...audioSource,
            start: 0,
            duration: audioSource.buffer.duration,
            end: audioSource.buffer.duration,
          },
          () => {
            this.stop();
          },
        );
        // We should make a helper function to automatically trigger
        //  audioSource.playing to true/false
        //  and emit the events PLAY/STOP
        // this.playBuffer(audioSource.buffer);
      } else {
        console.warn(`Audio Manager: Attempt to play non existing audio source by the id of "${audio}"`);
      }
    }
  }
  /**
   * PAUSES execution without rolling back the currentTime, allowing you to RESUME
   */
  pause() {
    this.setPlayerState(EAudioManagerStates.PAUSED);
    // Store current time for resuming
    void this.context.suspend();
    // Here either we clear the queue and let it play again OR we would have to control each individual SourceNode
    //  and stop/resume them as well
    // For what we need now, clearing and repopulating it is fine
    this.currentPlaying.forEach((audio) => {
      this.pauseTrack(audio);
    });
    // Notify
    this.emit(EAudioManagerEvents.PAUSE);
  }
  /**
   * Resumes from where it was PAUSED, if it was stopped, resume will do the same as PLAY
   */
  resume() {
    if (this.playerState === EAudioManagerStates.PAUSED) {
      // Change player state
      this.setPlayerState(EAudioManagerStates.PLAYING);
      // Resume context
      void this.context.resume();
      // Actually resume them...
      this.currentPlaying.forEach((audio) => {
        this.emit(EAudioManagerEvents.TRACK_START, audio);
      });
      // Notify
      this.emit(EAudioManagerEvents.PLAY, this.getCurrentTime());
      // Notify
      this.emit(EAudioManagerEvents.RESUME, this.lastCurrentTime);
    } else {
      console.warn(`Audio Manager: Player was not paused to attempt resuming.`);
    }
  }
  /**
   * Completely stops the timeline and rolls time to the beginning
   */
  stop() {
    this.setPlayerState(EAudioManagerStates.STOPPED);
    void this.context.suspend();
    // Clear all audios playing
    this.reset();
    // Notify
    this.emit(EAudioManagerEvents.STOP);
  }

  reset() {
    this.lastCurrentTime = this.context.currentTime;

    if (this.playerMode === EAudioManagerMode.SEQUENCE) {
      // Stop scheduler
      clearTimeout(this.schedulerId);
      // Stop all audios playing in queue
      this.clearQueue();
    } else {
      this.currentPlaying.forEach((audio) => {
        this.stopTrack(audio);
      });
    }
  }

  private pauseTrack(audio: Audio) {
    this.emit(EAudioManagerEvents.TRACK_STOP, audio);
    // audio.source?.stop();
    // audio.source?.disconnect(this.context.destination);
  }

  private playTrack(audio: Audio, onTrackEnd?: (audio: Audio) => void) {
    if (this.currentPlaying.has(audio)) {
      return;
    }

    this.emit(EAudioManagerEvents.TRACK_START, audio);
    this.currentPlaying.add(audio);

    const offset = this.getCurrentTime() - audio.start;
    // Play it!
    audio.source = this.playBuffer(
      audio.buffer,
      () => {
        this.stopTrack(audio);
        onTrackEnd?.(audio);
      },
      /**
       * This is the heart of the play ahead
       * If the offset is negative this means, we captured the audio a couple milliseconds before its actual play-time
       * when that happens we switch the starting point AKA "when" to use that value as a positive number.
       * Offset becomes >= 0 again for current and past audios that still should be played
       */
      offset < 0 ? Math.abs(offset) : 0,
      offset < 0 ? 0 : offset,
    );
  }
  private stopTrack(audio: Audio) {
    if (!this.currentPlaying.has(audio)) {
      return;
    }

    this.emit(EAudioManagerEvents.TRACK_STOP, audio);

    if (audio.source) {
      try {
        audio.source.stop();
        audio.source.disconnect(this.context.destination);
        audio.source.onended = null;
      } catch (e: unknown) {
        /**
         * Explicitly silenced, mostly DOMExceptions for trying to disconnect an already disconnected source.
         * Still shouting to debug channel in case something else happens, and we need to look into it.
         */
        console.debug(typeof e, e);
      }
    }

    this.currentPlaying.delete(audio);
  }

  /**
   * Low level buffer player, shouldn't be called directly
   * @param buffer
   * @param onEnd
   * @param when
   * @param offset
   * @param duration
   */
  private playBuffer(
    buffer: AudioBuffer,
    onEnd: (this: AudioScheduledSourceNode, ev: Event) => void,
    when?: number,
    offset?: number,
    duration?: number,
  ): AudioBufferSourceNode {
    const source = this.context.createBufferSource();

    source.buffer = buffer;
    source.connect(this.context.destination);
    source.start(when, offset, duration);
    source.onended = onEnd;

    return source;
  }
  /**
   * Fetch Audio from source.
   * Source could be string (path), File object of Blob object
   * @param filepaths
   */
  async fetchAudio(...filepaths: AudioInputTypes[]): Promise<AudioBuffer[]> {
    return await Promise.all(
      filepaths.map(async (filepath) => {
        let buffer: ArrayBuffer;

        if (filepath instanceof File || filepath instanceof Blob) {
          buffer = await filepath.arrayBuffer();
        } else {
          buffer = await fetch(filepath).then((response) => {
            if (response.headers.has("Content-Type") && !response.headers.get("Content-Type")!.includes("audio/")) {
              console.warn(
                `Audio Manager: Attempted to fetch an audio file, but its MIME type is \`${
                  response.headers.get("Content-Type")!.split(";")[0]
                }\`. We'll try and continue anyway. (file: "${filepath}")`,
              );
            }

            return response.arrayBuffer();
          });
        }

        return await this.context.decodeAudioData(buffer);
      }),
    );
  }
  /**
   * Merge Audio.
   * @param buffers
   * Receives a number of audio buffers and return a single buffer with all of them mixed
   */
  public mergeAudio(buffers: AudioBuffer[]): AudioBuffer {
    const maxChannels = Math.max(...buffers.map((buffer) => buffer.numberOfChannels));
    const maxDuration = Math.max(...buffers.map((buffer) => buffer.duration));

    const output = this.context.createBuffer(
      maxChannels,
      this.context.sampleRate * maxDuration,
      this.context.sampleRate,
    );

    buffers.forEach((buffer) => {
      for (let channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
        const outputData = output.getChannelData(channelNumber);
        const bufferData = buffer.getChannelData(channelNumber);

        for (let i = buffer.getChannelData(channelNumber).length - 1; i >= 0; i--) {
          outputData[i] += bufferData[i];
        }

        output.getChannelData(channelNumber).set(outputData);
      }
    });

    return output;
  }
  /**
   * Pads a specified AudioBuffer with silence from a specified start time,
   * for a specified length of time.
   *
   * Accepts float values as well as whole integers.
   *
   * @param buffer AudioBuffer to pad
   * @param padStart Time to start padding (in seconds)
   * @param seconds Duration to pad for (in seconds)
   */
  public padAudio(buffer: AudioBuffer, padStart = 0, seconds = 0): AudioBuffer {
    if (seconds === 0) return buffer;

    if (padStart < 0) throw new Error('AudioManager: Parameter "padStart" in padAudio must be positive');
    if (seconds < 0) throw new Error('AudioManager: Parameter "seconds" in padAudio must be positive');

    const updatedBuffer = this.context.createBuffer(
      buffer.numberOfChannels,
      Math.ceil(buffer.length + seconds * buffer.sampleRate),
      buffer.sampleRate,
    );

    for (let channelNumber = 0; channelNumber < buffer.numberOfChannels; channelNumber++) {
      const channelData = buffer.getChannelData(channelNumber);
      updatedBuffer
        .getChannelData(channelNumber)
        .set(channelData.subarray(0, Math.ceil(padStart * buffer.sampleRate) + 1), 0);

      updatedBuffer
        .getChannelData(channelNumber)
        .set(
          channelData.subarray(Math.ceil(padStart * buffer.sampleRate) + 2, updatedBuffer.length + 1),
          Math.ceil((padStart + seconds) * buffer.sampleRate),
        );
    }

    return updatedBuffer;
  }
}

const instance = new AudioManager();

export default instance;
