import { IBasicPageImageV2 } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { handleKeyPress } from "../../components/ObjectPropertyBox/functions/PropertyBoxFunctions";
import Image from "./Image";

export function Images({ images, keyPressHandlers, target, newHandleImageDivClick, loadImage, selectTarget }: any) {
  if (images.length > 0) {
    return (
      <>
        {(images as IBasicPageImageV2[]).map((image, index: number) => {
          return (
            <Image
              key={image.objectId}
              ffElement={image}
              index={index}
              kp={keyPressHandlers}
              target={target}
              handleKeyPress={handleKeyPress}
              handleImageDivClick={newHandleImageDivClick}
              loadImage={loadImage}
              selectTarget={(e, index, val) => selectTarget(e, index, val)}
              blobUrl={image.imagePath}
              objectId={image.objectId}
              borderColor={image.borderColor}
              shadowColor={image.shadowColor}
              fontColor={image.fontColor}
              fontStyle={image.fontStyle}
              pixelTop={image.rawData?.pixelTop}
              pixelLeft={image.rawData?.pixelLeft}
              pixelWidth={image.rawData?.pixelWidth}
              pixelHeight={image.rawData?.pixelHeight}
              clipTop={image.clipPath?.top}
              clipLeft={image.clipPath?.left}
              clipRight={image.clipPath?.right}
              clipBottom={image.clipPath?.bottom}
              clipPath={image.clipPathString}
              fontWeight={image.fontWeight}
              rotate={image.rotation ?? image.transform?.rotate ?? 0}
              scale={image.transform?.scale}
              backgroundColor={image.backgroundColor}
              height={image.height}
              width={image.width}
              left={image.left}
              top={image.top}
              version={image.version}
              zIndex={image.zIndex}
              maintainRatio={image.maintainRatio}
              textDecoration={image.textDecoration}
              opacity={image.opacity}
              visible={image.isDisplayed}
              grayscale={image.grayscale}
              ghost={image.ghost}
              blurIntensity={image?.blurIntensity}
              blurOpacity={image?.blurOpacity}
              blurColor={image?.blurColor}
              blurCutoutShapes={image?.blurCutoutShapes}
            />
          );
        })}
      </>
    );
  }
  return null;
}
