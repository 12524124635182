import React from "react";
import { standardColors } from "../../../../../../utils/Colors/colors";

interface StandardColorsProps {
  keyValue: "fontColor" | "backgroundColor" | "borderColor" | "annotationFontColor";
  onChange: (color: string) => void;
}

const StandardColors: React.FC<StandardColorsProps> = ({ keyValue, onChange }) => {
  const handleColorClick = (color: string) => {
    onChange(color);
  };

  return (
    <div className="standard-colors">
      <div className="object-properties-section-title">Standard Colors</div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "3px", rowGap: "10px" }}>
        {standardColors.map((color, index) => (
          <div
            key={index}
            className="colorBox"
            style={{
              backgroundColor: color,
              height: "20px",
              width: "20px",
              border: "1px solid #000",
              borderRadius: "2px",
            }}
            onClick={() => handleColorClick(color)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default StandardColors;
