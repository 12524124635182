import React, { useContext, useEffect, useState } from "react";
import {
  IPageContext,
  PageContext,
  IAssetManagementContext,
  AssetContext,
  PagesManagementContext,
  ILessonPageActionsContext,
  LessonPageActionsContext,
} from "../../../routes/builderContexts";
import {
  IUploadManagerContext,
  UploadManagerContext,
  AircraftForLesson,
  fileListObject,
  uploadedFileObject,
  IAssetMetaData,
  ITag,
  IAssetUploaderState,
} from "../interfaces/uploaderInterfaces";
//import { useAssetManagerDispatch, useAssetManagerState } from "../AssetManagerContext/AssetManagerProvider";
import genericRepositoryService from "../../../services/genericRepositoryService";
import * as uploaderFunctions from "../AssetManagerUtils/UploaderFunctions";
//import * as constants from '../../constants/pageTypes';
import lodash from "lodash";
import FileList from "./FileList";
import DragContainer from "./DragContainer";
import { useSelector } from "react-redux";
import { parseSvgFile } from "../../../lib/SmartObject";
import { ASSET_TYPE_ID } from "../../../const";
//import UploadeWarningModal from "./UploadWarningModal";

interface AssetUploaderProps {
  uploadWarningShown: boolean;
  movingToLibraryFromUploader: boolean;
  setUploadsProcessing: any;
  uploadsProcessing: boolean;
  lmsKey: number;
  isCpatUser: boolean;
  tailoredForLms: { lmsKey: number; name: string }[];
  saveAssets: boolean;
  setSaveAssets: (value: boolean) => void;
  handleCancel: (safeToCancel: boolean) => void;
  safeToClose: boolean;
  setSafeToClose: (value: boolean) => void;
  setSaveAssetErrorMessage: (value: string) => void;
  saveAssetErrorMessage: string;
  closeClick: number;
  setCloseClick: (value: number) => void;

  uploaderState: IAssetUploaderState;
  // setUploadWarningShown: (value: boolean) => void;
  // setAssetManagerController: (value: {isOpen: boolean, mode: string}) => void;

  lmsKeyForAssetOwner: string | null; // this is the owner for an asset, settable by CPaT users
  allowLibraryItem: boolean;
}
const MultiFileUploader = (props: AssetUploaderProps) => {
  const MaxiumumBlobUploadSize = 101000000;
  const assetContext: IAssetManagementContext = useContext<IAssetManagementContext>(AssetContext);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  //const pageManifest = lodash.cloneDeep(pageContext.pageManifest);
  //const lessonPageActionsContext: ILessonPageActionsContext = useContext<ILessonPageActionsContext>(LessonPageActionsContext);

  const dragDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  //const [fileString, setFileString] = useState<string>("")
  //const [filesUploaded, setFilesUploaded] = useState<number>(0);
  //const [selectedId, setSelectedID] = useState<number>(-1);
  //const [ataIds, setAtaIds] = useState<number[]>([]);
  //const [newFiles, setNewFiles] = useState<fileListObject[]>([]); //needs to flag for loading
  //const [uploadedAssets, setUploadedAssets] = useState<uploadedFileObject[]>([]);
  //const [pageList, setPageList] = useState<number[]>([0]);
  const [dragging, setDragging] = useState<boolean>(false);
  //const [tailoredForLmsKey, setTailoredForLmsKey] = useState<string>("");
  //const [versionName, setVersionName] = useState<string>("");
  //const [uploadsProcessing, setUploadsProcessing] = useState<boolean>(false);
  //const [cancelUploadModalBoolean, setCancelUploadModalBoolean] = useState<boolean>(false);
  // const [cancelUploadModalBoolean, setCancelUploadModalBoolean] = useState<boolean>(false);
  const [clickedFileUpload, setClickedFileUpload] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [saveStatusMessage, setSaveStatusMessage] = useState<string>(" ");
  const [saveStatusColor, setSaveStatusColor] = useState<string>("#6EBE45");
  const [failedUpdateCounter, setFailedUpdateCounter] = useState<number>(0);
  const [runSaveAndUpdate, setRunSaveAndUpdate] = useState<boolean>(false); //
  const [assetReRender, setAssetReRender] = useState<boolean>(false);

  //const [safeToClose, setSafeToClose] = useState<boolean>(false);
  //const assetManagerDispatch = useAssetManagerDispatch();
  //const assetRef = React.createRef();

  const [aircraftIds, setAircraftIds] = useState<AircraftForLesson>({
    aircraftId: null,
    aircraftFamilyId: null,
    manufacturerId: null,
  });
  const [aircraftData, setAircraftData] = useState<any>({
    manufacruter: "",
    fleet: "",
    variant: "",
    configuration: "",
  });

  const multiFile: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  // let dragDiv: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  // useEffect(()=>{

  // },[props.files])
  //NEED TO SPECIFY ALLOWED FILE TYPES BASED ON LESSON MODE
  const dragEnter = (event: React.DragEvent<HTMLInputElement>) => {
    setDragging(true);
  };
  const dragLeave = (event: React.DragEvent<HTMLInputElement>) => {
    setDragging(false);
  };
  const dragOver = (event: React.DragEvent<HTMLInputElement>) => {
    setDragging(true);
  };
  const drop = (event: React.DragEvent<HTMLInputElement>) => {
    //event.preventDefault();
    setClickedFileUpload(false); //without this there is a bug where an asset will be uploaded twice if the user clicks open the file explorer then drags
    if (event.dataTransfer) {
      //loop this

      props.setUploadsProcessing(true);

      addLocalFile(event.dataTransfer.files);
    }
  };

  const changeEffectUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      //map for multiple files
      if (clickedFileUpload) {
        //props.setUploadsProcessing(true);
        addLocalFile(event.target.files);
        //uploadLocalFile(event.target.files);
        setClickedFileUpload(false);
      } else {
        //addLocalFile(event.target.files);
        //uploadLocalFile(event.target.files);
      }
    }
  };
  const addLocalFile = async (files: FileList) => {
    let uploadCounter = props.uploaderState.filesUploaded;
    const uploadingFiles = props.uploaderState.newFiles;
    const fileTypes = ["jpg", "png", "gif", "jpeg", "mp3", "mp4", "rtf", "pdf", "svg"];
    if (props.isCpatUser) {
      fileTypes.push(...["zip", "prt"]);
    }
    const tranferFiles = Array.from(files);

    for (const file of files) {
      const nameSplit = file.name.split(".");
      const fileExtension = nameSplit[nameSplit.length - 1].toLowerCase();
      let validFile = true;
      const fileType = getFileType(file.name);
      const isSmartObject = assetContext.assetTypeId === ASSET_TYPE_ID.SMART_OBJECT ? true : false;
      let smartObjectFile = null;
      if (assetContext.assetTypeId !== -1) {
        validFile = uploaderFunctions.manageFileType(file, assetContext.assetTypeId);
      }
      if (fileTypes.includes(fileExtension) && validFile) {
        const databaseTemplate: IAssetMetaData = {
          assetVersionId: -1,
          manufacturerId: [],
          aircraftId: [],
          aircraftFamilyId: [],
          configurationId: [],
          name: uploaderFunctions.massageFileName(file),
          description: uploaderFunctions.massageFileName(file),
          isLibrary: false,
          LmsKey: null,
          tags: [],
        };

        if (isSmartObject || fileType === ASSET_TYPE_ID.SMART_OBJECT) {
          smartObjectFile = await parseSvgFile(file);
        }

        const newFileObj = {
          file: file,
          uploading: false,
          managerId: uploadCounter,
          name: uploaderFunctions.massageFileName(file),
          dataBaseObject: databaseTemplate,
          metaDataIsDirty: true,
          updateFailed: false,
          smartObject: smartObjectFile ?? undefined,
        };
        uploadingFiles.push(newFileObj);
        uploadCounter++;
      }
    }
    props.uploaderState.setFilesUploaded(uploadCounter);
    props.uploaderState.setNewFiles(uploadingFiles);
    setDragging(false);
  };

  const getFileType = (name: string) => {
    const nameSplit = name.split(".");
    const fileExtension = nameSplit[nameSplit.length - 1].toLowerCase();
    const images = ["png", "jpeg", "jpg", "gif"];
    const svg = ["svg"];
    const audio = ["mp3"];
    const pdf = ["pdf"];
    const video = ["mp4"];
    const interactiveDiagram = props.isCpatUser ? ["zip", "prt"] : [];

    if (images.includes(fileExtension)) {
      return ASSET_TYPE_ID.IMAGE;
    } else if (audio.includes(fileExtension)) {
      // return ASSET_TYPE_ID.AUDIO;
      /**
       * ASSET_TYPE_ID.AUDIO is not used anymore?
       * we might need a way to differentiate between audio and audio effect?
       */
      return ASSET_TYPE_ID.AUDIO_EFFECT;
    } else if (pdf.includes(fileExtension)) {
      return ASSET_TYPE_ID.PDF;
    } else if (video.includes(fileExtension)) {
      return ASSET_TYPE_ID.VIDEO;
    } else if (interactiveDiagram.includes(fileExtension)) {
      return ASSET_TYPE_ID.SCORM;
    } else if (svg.includes(fileExtension)) {
      return ASSET_TYPE_ID.SMART_OBJECT;
    } else {
      return -1;
    }
  };

  const updateAssetMetadata = (newMetaData: any, index: number) => {
    const newAssetList = lodash.cloneDeep(props.uploaderState.newFiles);
    if (newAssetList[index].dataBaseObject) {
      newAssetList[index].dataBaseObject = newMetaData;
      newAssetList[index].metaDataIsDirty = true;
      newAssetList[index].updateFailed = false;

      //metaUpdateApi(newAssetList[index])
      if (props.closeClick > 0) {
        props.setCloseClick(0);
      }
      validateSaveButtonClick(newAssetList);
    }

    props.uploaderState.setNewFiles(newAssetList);
  };
  const validateSaveButtonClick = (assetList: fileListObject[]) => {
    let failFlag = false;
    assetList.map((file) => {
      if (
        file.dataBaseObject.manufacturerId.length === 0 ||
        file.dataBaseObject.aircraftFamilyId.length === 0 ||
        file.dataBaseObject.aircraftId.length === 0 ||
        file.dataBaseObject.description.length === 0
      ) {
        failFlag = true;
      }
    });
    if (failFlag) {
      props.uploaderState.setCanClickSave(false);
    } else {
      props.uploaderState.setCanClickSave(true);
    }
  };

  const removeAsset = (id: number) => {
    const fileClone = lodash.cloneDeep(props.uploaderState.newFiles);
    const newClone = fileClone.filter((file) => {
      if (file.managerId !== id) {
        return file;
      }
    });
    props.uploaderState.setNewFiles(newClone);
    setAssetReRender(true);
  };
  useEffect(() => {
    if (props.saveAssets === true) {
      //SaveAssets();
      const fileClone = lodash.cloneDeep(props.uploaderState.newFiles);
      // adds prop to indicate state
      fileClone.map((file, index) => {
        fileClone[index].uploading = true;
      });
      props.uploaderState.setNewFiles(fileClone);
      setRunSaveAndUpdate(true);
    }
  }, [props.saveAssets]);
  ////#region rgba(42,42,42,0.7)
  useEffect(() => {
    const uploadAssetAndSave = async () => {
      props.uploaderState.newFiles.map(async (file, index) => {
        if (file.updateFailed) {
          const assetMetaData = await SaveAssetMeta(file.dataBaseObject.assetVersionId, index);
          if (assetMetaData.updateFailed) {
          }
        }
      });
      uploadLocalFile(
        props.uploaderState.newFiles.map((file) => {
          if (file.updateFailed === false) {
            return file;
          }
        }),
      );
      props.setUploadsProcessing(false);
    };
    const uploadLocalFile = async (files: fileListObject | any) => {
      const timeStart = performance.now();
      /**
       * fleet: lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftFamilyId: assetContext.lessonDataSettings.lessonMetaData.aircraftFamilyId}).aircraftFamilyName,
        variant: lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts, {aircraftId: assetContext.lessonDataSettings.lessonMetaData.aircraftId}).aircraftName,
        manufacturer: lodash.find(assetContext.lessonDataSettings.aircrafts.aircrafts,{manufacturerId: assetContext.lessonDataSettings.lessonMetaData.manufacturerId}).manufacturerName
       */
      const filesArray = Array.from(files as fileListObject[]); //lets us manipulate file list like a normal array
      const lessonData = {
        //needed data for upload api
        aircraftPlacementIds: null, //keep null
        ataIds: null, //not needed just keep null
        aircraftId: null, //keep null
        manufacturerId: null,
        versionName: props.uploaderState.versionName,
        aircraftFamilyId: null,
        tailoredForLmsKey: props.lmsKey ? props.lmsKey : null, //from redux,
        uploadForLmsKey: props.lmsKeyForAssetOwner ? parseInt(props.lmsKeyForAssetOwner) : null,
      };
      let assetPayload = {
        blobPath: "",
        assetVersionId: -1,
      };
      let uploadCounter = props.uploaderState.filesUploaded; //helps associate local files and uploaded files with an ID to compensate for async fuctions so that both data sets can match
      const localFileArray = lodash.cloneDeep(props.uploaderState.newFiles); //need local files to tell when its been successfully uploaded
      const uploadedFileArray = lodash.cloneDeep(props.uploaderState.uploadedAssets); //tracks the upload return data
      const newFileArray = lodash.cloneDeep(props.uploaderState.newFiles);
      //let validLocalFile = lodash.find(props.uploaderState.newFiles, { managerId: uploadCounter });

      // ^ validates that there is valid a local file present to be uploaded, discovered bug where local file is rejected but uploads anyways
      const uploadPromise = await filesArray.map(async (item, index) => {
        const { file } = item;
        const typeId = getFileType(file.name);
        if (typeId !== -1 || (assetContext.assetTypeId !== -1 && typeId !== assetContext.assetTypeId && isMounted)) {
          //if there is a specific asset at the page level it should only accept that asset type
          let uploadResult;

          if (assetContext.assetTypeId === ASSET_TYPE_ID.SMART_OBJECT || typeId === ASSET_TYPE_ID.SMART_OBJECT) {
            uploadResult = await uploaderFunctions.smartObjectUploadProcess(item, lessonData);
          } else {
            uploadResult = await uploaderFunctions.fileUploadProcess(file, lessonData, typeId);
          }

          if (uploadResult && uploadResult.data !== undefined) {
            localFileArray[index].dataBaseObject.assetVersionId = uploadResult.data.assetId;
            const assetMetaData = await genericRepositoryService.getAssetMetaData(uploadResult.data.assetId);

            const newUploadObject: uploadedFileObject = {
              blobPath: uploadResult.data.blobPath,
              assetId: uploadResult.data.assetId,
              managerId: filesArray.length > 1 ? index : uploadCounter,
              dataBaseObject: assetMetaData.isSuccess ? assetMetaData.data.asset : undefined,
              metaDataIsDirty: true,
              updateFailed: false,
            };

            localFileArray.map((file, index) => {
              if (newUploadObject.managerId === file.managerId) {
                localFileArray[index].uploading = true;
              }
            });
            const safeToclose = localFileArray.filter((file) => {
              return file.uploading === false;
            });

            if (safeToclose.length === 0) {
              props.setUploadsProcessing(false);
            }

            if (newUploadObject.managerId === 0) {
              assetPayload = {
                blobPath: newUploadObject.blobPath,
                assetVersionId: newUploadObject.assetId,
              };
            }
            localFileArray[index].dataBaseObject.assetVersionId = uploadResult.data.assetId;

            const metaObject = await SaveAssetMeta(localFileArray[index], index);
            if (metaObject.updateFailed) {
              localFileArray[index].updateFailed = true;
              localFileArray[index].uploading = false;
            }
            uploadCounter++;
            //uploadedFileArray.push(newUploadObject);
            props.uploaderState.setFilesUploaded(uploadCounter);
            return localFileArray[index];
          } else if (uploadResult === undefined) {
            props.setSaveAssetErrorMessage(
              "*Some files can't be saved due to a connection issue, please try again later.",
            );
            setSaveStatusColor("red");
            localFileArray[index].updateFailed = true;
            localFileArray[index].uploading = false;
          }
        } else {
          props.setSaveAssetErrorMessage("*Some files can't be saved due to a file issue, please try again later.");
          setSaveStatusColor("red");
          localFileArray[index].updateFailed = true;
          localFileArray[index].uploading = false;
        }
      });
      const fullUploadList = await Promise.all(uploadPromise);

      if (fullUploadList !== undefined) {
        //ts-ignore - this filter specifically checks that the element is not undefined but typesctipt throws an error that it could be undefined
        //@ts-ignore
        const uploadObjects: uploadedFileObject[] = fullUploadList.filter((upload) => {
          return typeof upload !== undefined;
        });
        const filteredFailures = localFileArray.filter((file) => {
          return file.updateFailed === true;
        });
        props.uploaderState.setNewFiles(filteredFailures);

        const timeEnd = performance.now();
        const timeTotal = timeEnd - timeStart;
        console.info("upload completed in " + timeTotal + " miliseconds");
        if (uploadedFileArray.length) {
          props.uploaderState.setUploadedAssets(
            uploadedFileArray.concat(uploadObjects).filter((file, index) => {
              return file.updateFailed === true;
            }),
          );
        } else {
          props.uploaderState.setUploadedAssets(
            uploadedFileArray.filter((file, index) => {
              return file.updateFailed === true;
            }),
          );
        }
      } else {
        props.uploaderState.setNewFiles(
          localFileArray.filter((file) => {
            return file.updateFailed === true;
          }),
        );
      }
    };
    // const convertTagsToDict = (tags: ITag[]) => { //tags used to need to be converted to a dictionary before the update asset call, now this is just an easy way to make the update fail for testing
    //   let tagDictionary: any = {}
    //   tags.forEach(tag => {
    //     let tagKey = Object.keys(tag)
    //     let dictionaryKeys = Object.keys(tagDictionary);
    //     if(!dictionaryKeys.includes(tagKey[0])){
    //       tagDictionary[tagKey[0]] = tag[tagKey[0]]
    //     }

    //   });
    //   return tagDictionary;
    // }
    const SaveAssetMeta = async (fileData: fileListObject, index: number) => {
      const fileClone = lodash.cloneDeep(fileData);
      if (fileClone.dataBaseObject) {
        if (fileClone.metaDataIsDirty === true) {
          if (props.isCpatUser === false || !props.allowLibraryItem) {
            fileClone.dataBaseObject.isLibrary = false;
          }

          //old way to save tags
          //let tagDict = convertTagsToDict(fileClone.dataBaseObject.tags);
          //fileClone.dataBaseObject.tags = tagDict;
          // fileClone.dataBaseObject.tags.forEach((tag: any, i: number) => {

          //   if(Object.keys(tag)[0].length === 0) {

          //     //fileClone.dataBaseObject.tags.splice(i, 1);
          //   }
          // })
          const tags = fileClone.dataBaseObject.tags.filter((tag: any) => {
            return Object.keys(tag)[0].length !== 0 && Object.values(tag as string[])[0].length !== 0;
          });

          fileClone.dataBaseObject.tags = tags;
          fileClone.dataBaseObject.LmsKey = props.lmsKeyForAssetOwner;
          const callResult = await genericRepositoryService.updateAssetMetaDataInitial(fileClone.dataBaseObject);

          if (callResult.data && callResult.isSuccess) {
            fileClone.metaDataIsDirty = false;
            fileClone.updateFailed = false;
            //saveCounter++;
          } else {
            setSaveStatusColor("red");
            fileClone.updateFailed = true;

            if (callResult.errorMessage.length === 0 || callResult.errorMessage === "Request could not be processed") {
              props.setSaveAssetErrorMessage("*Files can't be saved, please try again later");
            } else {
              props.setSaveAssetErrorMessage("*" + callResult.errorMessage);
            }

            props.setSafeToClose(false);
            //safeClose = false;
            //failCounter++
          }
        }
      }

      return fileClone;
    };
    if (runSaveAndUpdate === true) {
      uploadAssetAndSave();
      props.setUploadsProcessing(true);
      setRunSaveAndUpdate(false);
      props.setSaveAssets(false);
      props.uploaderState.setCanClickSave(false);
    }
  }, [runSaveAndUpdate]);
  useEffect(() => {}, [props.safeToClose]);
  useEffect(() => {
    if (saveStatusMessage !== " ") {
      setTimeout(() => {
        setSaveStatusMessage(" ");
      }, 5000);
    }
  }, [saveStatusMessage]);
  useEffect(() => {
    if (props.uploaderState.newFiles.length > 2) {
      const newPageList = [0];
      let pages = 0;
      let pageCounter = 0;
      for (let i = 0; i < props.uploaderState.newFiles.length - 1; i++) {
        pageCounter++;
        if (pageCounter === 2) {
          pages++;
          newPageList.push(pages);

          pageCounter = 0;
        }
      }

      props.uploaderState.setPageList(newPageList);
    }
  }, [props.uploaderState.newFiles.length]);
  useEffect(() => {
    if (assetReRender === true) {
      setAssetReRender(false);
    }
  }, [assetReRender]);
  useEffect(() => {
    let newFileString = "";
    switch (assetContext.assetTypeId) {
      case 4: {
        newFileString = "image/png, image/jpeg, image/jpg, image/gif";
        break;
      }
      case 5: {
        newFileString = "audio/mp3";
        break;
      }
      case 7: {
        newFileString = "application/pdf";
        break;
      }
      case 8: {
        newFileString = "video/mp4";
        break;
      }
      case 16: {
        newFileString = "image/svg+xml";
        break;
      }
      default: {
        newFileString =
          "image/png, image/jpeg, image/jpg, image/gif, image/svg+xml, audio/mp3, application/pdf, video/mp4";
        if (props.isCpatUser) {
          newFileString += ", application/zip, application/x-zip-compressed,.prt";
        }
        break;
      }
    }
    props.uploaderState.setFileString(newFileString);
    setIsMounted(true);
    return () => {
      setIsMounted(false);
      props.setSafeToClose(false);
    };
  }, []);

  //const UploadManagerContext = createContext<any>({} as any)
  const createUploadManagerContext = (): IUploadManagerContext => {
    //<UploaderContext.Provider value={createUploaderContextProvider()} key="mine1">
    return {
      aircraftData,
      setAircraftData,
      aircraftIds,
      setAircraftIds,
      ataIds: props.uploaderState.ataIds,
      setAtaIds: props.uploaderState.setAtaIds,
      uploadedAssets: props.uploaderState.uploadedAssets,
      newFiles: props.uploaderState.newFiles,
      lmsKey: props.lmsKey,
      isCpatUser: props.isCpatUser,
      tailoredForLms: props.tailoredForLms,
      removeAsset,
      assetReRender,
      setAssetReRender,
    };
  };
  return (
    <div className="uploader-div" style={{ pointerEvents: props.uploadWarningShown ? "none" : "all" }}>
      <UploadManagerContext.Provider value={createUploadManagerContext()} key="uploadManager">
        <DragContainer
          dragDiv={dragDiv}
          dragEnter={dragEnter}
          dragLeave={dragLeave}
          dragOver={dragOver}
          drop={drop}
          newFiles={props.uploaderState.newFiles}
          setNewFiles={props.uploaderState.setNewFiles}
          dragging={dragging}
          multiFile={multiFile}
          changeEffectUpload={changeEffectUpload}
          setClickedFileUpload={setClickedFileUpload}
          setUploadsProcessing={props.setUploadsProcessing}
          fileString={props.uploaderState.fileString}
        />

        <FileList
          newFiles={props.uploaderState.newFiles}
          uploadedAssets={props.uploaderState.uploadedAssets}
          pageList={props.uploaderState.pageList}
          setSelectedID={props.uploaderState.setSelectedID}
          selectedId={props.uploaderState.selectedId}
          updateAssetMetadata={updateAssetMetadata}
          saveStatusMessage={props.saveAssetErrorMessage.length > 0 ? props.saveAssetErrorMessage : saveStatusMessage}
          saveStatusColor={saveStatusColor}
          assetReRender={assetReRender}
          saveAssetErrorMessage={props.saveAssetErrorMessage}
          getFileType={getFileType}
          allowLibraryItem={props.allowLibraryItem}
        />
        {/* <div className="bottom-bottons">
          <button className="manager-button" onClick={props.triggerModal}>Cancel</button>
          <button className="manager-button" onClick={pageAssociation}>Add To Page</button>
        </div> */}
      </UploadManagerContext.Provider>
    </div>
  );
};

export default MultiFileUploader;
