import { createWithEqualityFn } from "zustand/traditional";
import { subscribeWithSelector, devtools } from "zustand/middleware";
import { SVG_SMART_OBJECT_HOTSPOTS_ACTIONS, SVG_SMART_OBJECT_TYPES } from ".";

type MetaVariableKey = string;

export type AnimatedMetaVariableFrame = {
  timestamp: number;
  value: string | number | boolean;
  metadata?: AnimatedMetaVariableMetadata;
};

export type AnimatedMetaVariable = {
  metaVariableKey: string;
  oneshot?: AnimatedMetaVariableFrame[];
  interpolated?: AnimatedMetaVariableFrame[];
  objectId: string;
};

interface MetaVariableStore {
  metaVariables: {
    [key: MetaVariableKey]: string | number | boolean;
  };
  metaVariablesData: {
    [key: MetaVariableKey]: MetaVariable;
  };
  addNewMetaVariable: (metaVariable: MetaVariable) => void;
  updateMetaVariable: (metaVariable: MetaVariable) => void;
  deleteMetaVariable: (metaVariable: MetaVariable) => void;
  setMetaVariable: (key: MetaVariableKey, value: string | number | boolean) => void;
  loadStore: (
    metaVariables: MetaVariableStore["metaVariables"],
    metaVariablesData: MetaVariableStore["metaVariablesData"],
    animatedMetaVariables: MetaVariableStore["animatedMetaVariables"],
  ) => void;
  clearAllMetaVariables: () => void;
  clearObjectMetaVariables: (objectId: string) => void;
  setColor: (key: string, value: string, color: string) => void;
  setMetaData: (objectKey: string, metadataKey: string, value: any) => void;
  toggleNext: (key: string, loop?: boolean) => void | string;
  togglePrevious: (key: string, loop?: boolean) => void;
  increment: (key: string) => void;
  decrement: (key: string) => void;
  /**
   * Animated Meta Variables
   */
  animatedMetaVariables: AnimatedMetaVariable[];
  mergedAnimatedMetaVariables: AnimatedMetaVariableFrame[]; // For the timeline bar
  deleteAnimatedMetaVariable: (metaVariable: string) => void;
  deleteAnimatedMetaVariableFrame: (metaVariable: string, timestamp: number) => void;
  upsertAnimatedMetaVariable: (
    metaVariable: string,
    timestamp: number,
    value: string | number | boolean,
    objectId: string,
    isInterpolated?: boolean,
    metadata?: AnimatedMetaVariableMetadata,
  ) => void;
  updateAnimatedMetaVariableTimestamp: (
    metaVariableKey: string,
    timestamp: number,
    newTimestamp: number,
    isInterpolated?: boolean,
  ) => void;
  adjustAnimatedMetaVariablesTimestamps: (
    objectId: string,
    oldStart: number,
    newStart: number,
    oldEnd: number,
    newEnd: number,
  ) => void;
  mergeAnimatedMetaVariables: () => void;
  clearAnimatedMetaVariables: () => void;
}

type AnimatedMetaVariableMetadata =
  | {
      isAnimating: boolean;
    }
  | {
      colors: {
        [key: string]: string;
      };
    }
  | {
      resumeOnClick: boolean;
      target?: string;
    };

export type MetaVariable = BaseMetaVariable; // | IndexedMetaVariable;

export interface SmartObjectHotspot {
  action: SVG_SMART_OBJECT_HOTSPOTS_ACTIONS;
  type: SVG_SMART_OBJECT_TYPES.HOTSPOT;
  setId: string;
  value: string | undefined;
  target: string;
  enabledKey: string;
  displayKey: string;
  id: string;
  name: string | undefined;
}

interface BaseMetaVariable {
  type: "ROTATE" | "IMAGE_SWAP" | string;
  key: string;
  value: string | number | boolean;
  choices: string[];
  order?: Record<string, string>;
  name: string;
  animate?: boolean;
  isAnimating?: boolean;
  hide?: boolean;
  rate?: number;
  action?: SVG_SMART_OBJECT_HOTSPOTS_ACTIONS;
  setId?: string;
  hotspots?: SmartObjectHotspot[];
  minValue?: number;
  maxValue?: number;
  minDegree?: number;
  maxDegree?: number;
  colors?: Record<string, string>;
  target?: string;
}

export const useMetaVariableStore = createWithEqualityFn<MetaVariableStore>()(
  devtools(
    subscribeWithSelector((set, get) => ({
      metaVariables: {},
      /**
       * used to store extra data for meta variables i.e. meta variables that are of "indexed" type will have the possible choices
       * in the same structure as the meta variable, maybe doings this is redundant but I am tring to squeeze as much performance as possible
       */
      metaVariablesData: {},
      addNewMetaVariable: (metaVariable: BaseMetaVariable) => {
        const state = get();
        const { key, value: initialValue } = metaVariable;
        if (key in state.metaVariables) {
          console.warn(`key ${key} already exists in metaVariables, skipping`);
          return;
        }
        if (!metaVariable.type) {
          set((s) => ({
            metaVariables: { ...s.metaVariables, [key]: initialValue },
            metaVariablesData: { ...s.metaVariablesData },
          }));
        } else {
          set((s) => ({
            metaVariables: { ...s.metaVariables, [key]: initialValue },
            metaVariablesData: { ...s.metaVariablesData, [key]: metaVariable },
          }));
        }
      },
      updateMetaVariable: (metaVariable: BaseMetaVariable) => {
        const state = get();
        const { key, value: initialValue } = metaVariable;
        if (!(key in state.metaVariables)) {
          console.warn(`key ${key} does not exist in store, skipping`);
          return;
        }
        console.info("updating metaVariables", key, initialValue);
        set((s) => ({
          metaVariables: { ...s.metaVariables, [key]: initialValue },
          metaVariablesData: { ...s.metaVariablesData, [key]: metaVariable },
        }));
      },
      deleteMetaVariable: (metaVariable: BaseMetaVariable) => {
        const state = get();
        const { key } = metaVariable;
        if (!(key in state.metaVariables)) {
          console.warn(`key ${key} does not exist in store, skipping`);
          return;
        }
        console.info("deleting metaVariables", key);
        set((s) => {
          delete s.metaVariables[key];
          delete s.metaVariablesData[key];
          return { metaVariables: { ...s.metaVariables }, metaVariablesData: { ...s.metaVariablesData } };
        });
      },
      setMetaVariable: (key: MetaVariableKey, value: string | number | boolean) => {
        const state = get();
        if (!(key in state.metaVariables)) {
          console.warn(`key ${key} does not exist in store, skipping`);
          return;
        }
        set((s) => {
          return { metaVariables: { ...s.metaVariables, [key]: value } };
        });
      },
      loadStore: (metaVariables, metaVariablesData, animatedMetaVariables) => {
        set(() => ({
          metaVariables,
          metaVariablesData,
          animatedMetaVariables,
        }));
      },
      clearAllMetaVariables: () => {
        set(() => ({
          metaVariables: {},
          metaVariablesData: {},
          animatedMetaVariables: [],
        }));
      },
      clearObjectMetaVariables: (objectId: string) => {
        const state = get();
        const metaVariables = { ...state.metaVariables };
        const metaVariablesData = { ...state.metaVariablesData };

        Object.keys(metaVariables).forEach((key) => {
          if (key.includes(objectId)) {
            delete metaVariables[key];
            delete metaVariablesData[key];
          }
        });

        const animatedMetaVariables = state.animatedMetaVariables.filter((v) => v.objectId !== objectId);

        set(() => ({
          metaVariables,
          metaVariablesData,
          animatedMetaVariables,
        }));
      },
      setColor: (key: string, value: string, color: string) => {
        const state = get();
        const colors = { ...state.metaVariablesData[key].colors };
        colors[value] = color;

        set((s) => {
          return {
            metaVariablesData: {
              ...s.metaVariablesData,
              [key]: {
                ...s.metaVariablesData[key],
                colors,
              },
            },
          };
        });
      },
      setMetaData: (objectKey: string, metadataKey: string, value: any) => {
        set((s) => {
          return {
            metaVariablesData: {
              ...s.metaVariablesData,
              [objectKey]: {
                ...s.metaVariablesData[objectKey],
                [metadataKey]: value,
              },
            },
          };
        });
      },
      toggleNext: (key: string, loop = false) => {
        const state = get();
        const choices = state.metaVariablesData[key].choices;
        const length = choices.length - 1;
        const currentValue = state.metaVariables[key];
        const currentIndex = currentValue === "hide" ? 0 : choices.indexOf(currentValue as string);
        const isLastValue = currentIndex === length;

        if (currentIndex === -1) return;

        let newIndex;

        if (isLastValue) {
          newIndex = loop ? 0 : currentIndex;
        } else {
          newIndex = currentIndex + 1;
        }

        const newValue = choices[newIndex];

        state.setMetaVariable(key, newValue);

        return newValue;
      },
      togglePrevious: (key: string, loop = false) => {
        const state = get();
        const choices = state.metaVariablesData[key].choices;
        const length = choices.length - 1;
        const currentValue = state.metaVariables[key];
        const currentIndex = currentValue === "hide" ? 0 : choices.indexOf(currentValue as string);
        const isFirstValue = currentIndex === 0;

        if (currentIndex === -1) return;

        let newIndex;

        if (isFirstValue) {
          newIndex = loop ? length : currentIndex;
        } else {
          newIndex = currentIndex - 1;
        }

        state.setMetaVariable(key, choices[newIndex]);
      },
      increment: (key: string) => {
        // TODO: implement
      },
      decrement: (key: string) => {
        // TODO: implement
      },
      /**
       * Animated Meta Variables
       */
      animatedMetaVariables: [],
      mergedAnimatedMetaVariables: [],
      upsertAnimatedMetaVariable: (
        metaVariable: string,
        timestamp: number,
        value: string | number | boolean,
        objectId: string,
        isInterpolated = false,
        metadata?: AnimatedMetaVariableMetadata,
      ) => {
        const state = get();
        const timelineKey = isInterpolated ? "interpolated" : "oneshot";
        const variableIndex = state.animatedMetaVariables.findIndex((m) => m.metaVariableKey === metaVariable);
        const newAnimatedVariable: AnimatedMetaVariable = {
          metaVariableKey: metaVariable,
          objectId,
        };

        if (variableIndex === -1) {
          newAnimatedVariable[timelineKey] = [{ timestamp, value, metadata }];
          set((s) => ({
            animatedMetaVariables: [...s.animatedMetaVariables, newAnimatedVariable],
          }));
          return;
        }

        const variable = state.animatedMetaVariables[variableIndex];

        const newFrames = [...(variable[timelineKey] as AnimatedMetaVariableFrame[])];
        const newFrame = {
          timestamp,
          value,
          metadata,
        };

        for (let i = 0; i < newFrames.length; i++) {
          const frame = newFrames[i];

          if (timestamp < frame.timestamp) {
            newFrames.splice(i, 0, newFrame);
            break;
          }

          if (timestamp === frame.timestamp) {
            newFrames[i] = newFrame;
            break;
          }

          if (timestamp > frame.timestamp && i < newFrames.length - 1) {
            continue;
          } else {
            newFrames.push(newFrame);
            break;
          }
        }

        const newVariables = [...state.animatedMetaVariables];
        newVariables[variableIndex] = {
          objectId,
          metaVariableKey: metaVariable,
          [timelineKey]: newFrames,
        };

        set(() => ({
          animatedMetaVariables: newVariables,
        }));

        // state.mergeAnimatedMetaVariables();
      },
      mergeAnimatedMetaVariables: () => {
        const animatedMetaVariables = get().animatedMetaVariables;
        const merged: AnimatedMetaVariableFrame[] = [];

        // for (const key of animatedMetaVariables) {
        // }

        // set((s) => ({
        //   mergedAnimatedMetaVariables: merged,
        // }));
      },
      deleteAnimatedMetaVariableFrame: (metaVariable: string, timestamp: number) => {
        const animatedMetaVariables = get().animatedMetaVariables;
        const variableIndex = animatedMetaVariables.findIndex((a) => a.metaVariableKey === metaVariable);
        const frameKeys = ["oneshot", "interpolated"] as const;

        if (variableIndex === -1) return;

        const variable = animatedMetaVariables[variableIndex];
        let frameIndex: number;
        let newFrames: AnimatedMetaVariableFrame[] = [];
        let frameKey: "oneshot" | "interpolated" = "oneshot";

        /**
         * Removing one frame from the AnimatedMetaVariableFrames
         * prioritizing oneshots -> interpolated since they're the more common
         */
        for (const key of frameKeys) {
          const frames = variable[key] ?? [];
          if (frames.length > 0) {
            frameIndex = frames.findIndex((f) => f.timestamp === timestamp);

            if (frameIndex !== -1) {
              newFrames = [...frames];
              newFrames.splice(frameIndex, 1);
              frameKey = key;
              break;
            }
          }
        }

        const newVariables = [...animatedMetaVariables];

        /**
         * Checking to see if after deleting the frame from either oneshot
         * or interpolated, it equals to an empty array, if thats the case
         * we remove the animated meta variable all together
         */

        if (newFrames.length === 0) {
          newVariables.splice(variableIndex, 1);
        } else {
          newVariables[variableIndex] = {
            ...newVariables[variableIndex],
            [frameKey]: newFrames,
          };
        }

        set(() => ({
          animatedMetaVariables: newVariables,
        }));

        //
      },
      deleteAnimatedMetaVariable: (metaVariable: string) => {
        const animatedMetaVariables = get().animatedMetaVariables;
        const variableIndex = animatedMetaVariables.findIndex((a) => a.metaVariableKey === metaVariable);

        if (variableIndex === -1) return;

        const newVariables = [...animatedMetaVariables];
        newVariables.splice(variableIndex, 1);

        set(() => ({
          animatedMetaVariables: newVariables,
        }));
      },
      updateAnimatedMetaVariableTimestamp: (
        metaVariableKey: string,
        timestamp: number,
        newTimestamp: number,
        isInterpolated = false,
      ) => {
        const state = get();
        const key = isInterpolated ? "interpolated" : "oneshot";
        const animatedMetaVariables = state.animatedMetaVariables;
        const animatedMetaVariableIndex = animatedMetaVariables.findIndex((v) => v.metaVariableKey === metaVariableKey);

        if (animatedMetaVariableIndex === -1) return;

        const originalFrames = animatedMetaVariables[animatedMetaVariableIndex][key] ?? [];
        const frameIndex = originalFrames.findIndex((f) => f.timestamp === timestamp);

        if (frameIndex === -1) return;

        const newFrames = [...originalFrames];
        const newFrame = { ...originalFrames[frameIndex], timestamp: newTimestamp };
        const frameIndexAtNewTimestamp = originalFrames.findIndex((f) => f.timestamp === newTimestamp);

        // if there isnt a frame at this timestamp just update it
        if (frameIndexAtNewTimestamp === -1) {
          newFrames[frameIndex] = newFrame;
        } else {
          // if there is a frame at this timestamp just update it with the new value
          newFrames[frameIndexAtNewTimestamp] = newFrame;
          // remove the old frame
          newFrames.splice(frameIndex, 1);
        }

        const newAnimatedMetaVariables = [...animatedMetaVariables];

        newAnimatedMetaVariables[animatedMetaVariableIndex] = {
          ...newAnimatedMetaVariables[animatedMetaVariableIndex],
          [key]: newFrames,
        };

        set(() => ({
          animatedMetaVariables: newAnimatedMetaVariables,
        }));
      },
      adjustAnimatedMetaVariablesTimestamps: (
        objectId: string,
        oldStart: number,
        newStart: number,
        oldEnd: number,
        newEnd: number,
      ) => {
        const state = get();
        const animatedMetaVariables = state.animatedMetaVariables;

        if (oldStart === newStart && oldEnd === newEnd) return;
        if (animatedMetaVariables.length === 0) return;

        const newAnimatedMetaVariables = animatedMetaVariables.map((variable) => {
          if (variable.objectId !== objectId) return variable;

          let frames: AnimatedMetaVariableFrame[] = [];
          let key: "oneshot" | "interpolated" = "oneshot";

          /**
           * Might need a better way to handle this
           * currently we only check for either oneshot or interpolated
           * if there is a case where one meta variable has both, interpolated will be
           * ignored
           */
          if (variable.oneshot && variable.oneshot?.length > 0) {
            frames = variable.oneshot;
          } else if (variable.interpolated && variable.interpolated?.length > 0) {
            frames = variable.interpolated;
            key = "interpolated";
          }

          /**
           * Don't believe it will be an issue, but maybe we'll need
           * logic to "merge" frames in a similar way to the function
           * `updateAnimatedMetaVariableTimestamp`
           */
          const newFrames = frames.map((frame) => {
            const proportion = (frame.timestamp - oldStart) / (oldEnd - oldStart);
            const newTimestamp = newStart + proportion * (newEnd - newStart);
            const rounded = Math.round(newTimestamp * 4) / 4; // rounding to the nearest 0.25
            return {
              ...frame,
              timestamp: rounded,
            };
          });

          return {
            ...variable,
            [key]: newFrames,
          };
        });

        set(() => ({
          animatedMetaVariables: newAnimatedMetaVariables,
        }));
      },
      clearAnimatedMetaVariables: () => {
        // probably not needed
        set(() => ({
          animatedMetaVariables: [],
        }));
      },
    })),
  ),
  Object.is,
);
