import { flattenObject } from "../Object/flattenObject";
import { objectifyArray } from "../Object/objectifyArray";
import { deflattenObject } from "../Object/deflattenObject";

export const arrayToFrameValue = (object: Record<string, any>[], key = "id") => {
  return flattenObject(objectifyArray(key, object));
};

export const mergeNestedFrameValueToArray = <T extends Record<string, any>>(
  object: Record<string, any>,
  originalArray: T[],
): T[] => {
  const originalCopy = [...originalArray];

  Object.entries(deflattenObject(object) as Record<string, any>).forEach(([objectId, props]) => {
    const index = originalArray.findIndex((item) => item.id === objectId);

    originalCopy[index] = { ...originalCopy[index], ...props };
  });

  return originalCopy;
};
