import { ReactComponent as AudioIcon } from "../../assets/icons/audio/audio-icon.svg";
import { ReactComponent as SpeechIcon } from "../../assets/icons/audio/speech-icon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Common/trash-icon.svg";

import "./index.css";
import { EInteractiveAudioType, LanguageType } from "../../models/IInteractiveAudio";
import { SecondaryLanguageSwitch } from "../../components/NarrationDrawer/components/SecondaryLanguageSwitch";
import { EffectAudioState, NarrationAudioState, OneShotAudioState } from "../../contexts/PageAudioManager/types";
export interface AudioActionsProps {
  audio: OneShotAudioState | NarrationAudioState | EffectAudioState;
  haSecondaryAudio: boolean;
  onUpload: () => void;
  onTextToSpeech: () => void;
  onRemove: () => void;
  onChangeLanguage: (language: LanguageType) => void;
  onCreateSecondary: () => void;
  type: EInteractiveAudioType;
}
export const AudioActions = ({
  audio,
  haSecondaryAudio,
  onUpload,
  onTextToSpeech,
  onRemove,
  onChangeLanguage,
  onCreateSecondary,
  type,
}: AudioActionsProps) => {
  const label = type === EInteractiveAudioType.EFFECT ? "Remove Audio" : "Replace or Remove Audio";
  return (
    <>
      {type === EInteractiveAudioType.NARRATION ? (
        <div className="audio-actions-wrapper">
          <SecondaryLanguageSwitch
            activeLanguage={audio.language}
            onSelectPrimary={() => onChangeLanguage(LanguageType.PRIMARY)}
            onSelectSecondary={() => onChangeLanguage(LanguageType.SECONDARY)}
            onCreateSecondary={() => onCreateSecondary()}
            hasSecondary={haSecondaryAudio}
          />
        </div>
      ) : null}

      <div className="audio-actions-title">{label}</div>
      <div className="audio-actions-wrapper">
        {type === EInteractiveAudioType.NARRATION ? (
          <>
            <div className="audio-action">
              <button
                className="cpat-icon-button cpat-icon-button--dark cpat-icon-button--xl cpat-icon-button--rounded"
                title="Upload Audio"
                onClick={onUpload}
              >
                <AudioIcon />
              </button>
              <div className="audio-action-label">Upload Audio</div>
            </div>
            <div className="audio-action">
              <button
                className="cpat-icon-button cpat-icon-button--dark cpat-icon-button--xl cpat-icon-button--rounded"
                title="Text to Speech"
                onClick={onTextToSpeech}
              >
                <SpeechIcon />
              </button>
              <div className="audio-action-label">Text to Speech</div>
            </div>
          </>
        ) : null}

        <div className="audio-action">
          <button
            className="cpat-icon-button cpat-icon-button--dark cpat-icon-button--xl cpat-icon-button--rounded"
            title="Remove Audio"
            onClick={onRemove}
          >
            <DeleteIcon />
          </button>
          <div className="audio-action-label">Remove Audio</div>
        </div>
      </div>
    </>
  );
};
