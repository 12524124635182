import { useMemo } from "react";
import { useTimeline } from "../contexts/TimelineProvider/TimelineProvider";
import { getFramesNearCurrentTime } from "../utils/Animation/getFramesNearCurrentTime";
import { useAnimatedObject } from "./useAnimatedObject";
import { interpolateFrame } from "../utils/Animation/interpolateFrame";

export function useInterpolatedFrame(objectId: string) {
  const animatedObject = useAnimatedObject(objectId);
  const frames = animatedObject?.frames;
  const framesCacheId = animatedObject?.framesCacheId;
  const [timeline] = useTimeline();
  const currentTime = timeline.scrubbingCurrentTime;
  const interpolatedFrame = useMemo(() => getFramesNearCurrentTime(frames, currentTime), [framesCacheId, currentTime]);

  return {
    transformY: interpolatedFrame?.y,
    transformX: interpolatedFrame?.x,
    width: interpolatedFrame?.width,
    height: interpolatedFrame?.height,
    rotation: interpolatedFrame?.rotation,
    opacity: interpolatedFrame?.opacity,
    blurIntensity: interpolatedFrame?.blurIntensity,
    blurOpacity: interpolatedFrame?.blurOpacity,
    blurCutoutShapes: interpolatedFrame?.blurCutoutShapes,
    pitch: interpolatedFrame?.pitch,
    yaw: interpolatedFrame?.yaw,
    zoom: interpolatedFrame?.zoom,
  };
}
