import React, { useMemo, useEffect, useState, useRef } from "react";
import { SVGAnnotationProps } from "../index";
import { HighlightAnnotation } from "../HighlightAnnotation";
import { handleTransparencyColor } from "../../../utils/Conversion";

export function SVGTriangle({
  x,
  y,
  objectId,
  onMouseDown,
  rotate,
  height,
  width,
  zIndex,
  opacity,
  borderColor,
  strokeWidth,
  backgroundColor,
  blurIntensity,
  isDisplayed,
}: SVGAnnotationProps) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const divRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const createPoints = useMemo(() => {
    const point1 = [4, dimensions.height - 4];
    const point2 = [(dimensions.width || 0) / 2, 4];
    const point3 = [dimensions.width - 4 || 0, dimensions.height - 4 || 0];
    // point format is "number,number number,number number,number"
    const points = `${point1.join(",")} ${point2.join(",")} ${point3.join(",")}`;
    return points;
  }, [dimensions.width, dimensions.height]);

  const id = `triangle-${objectId}`;
  const transformString = `translate(${x}px, ${y}px) rotate(${rotate ?? 0}deg)`;
  const points = createPoints;
  const clipPathId = `triangle-clip-${objectId}`;
  const onMouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    onMouseDown(e, objectId);
  };
  return (
    <div
      ref={divRef}
      data-objectid={objectId}
      id={id}
      onMouseDown={onMouseDownHandler}
      style={{
        position: "absolute",
        width: `${width}px`,
        height: `${height}px`,
        cursor: "grab",
        transform: transformString,
        pointerEvents: "all",
        zIndex: zIndex,
      }}
    >
      <svg
        viewBox="auto auto auto auto"
        className="annotation"
        name="triangle"
        preserveAspectRatio="xMinYMin meet"
        style={{
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          pointerEvents: "none",
        }}
      >
        <defs>
          <symbol id={`triangle-points-${objectId}`}>
            <polygon points={points} />
          </symbol>
          <clipPath id={clipPathId}>
            <polygon points={points} />
          </clipPath>
        </defs>
        <use
          xlinkHref={`#triangle-points-${objectId}`}
          href={`#triangle-points-${objectId}`}
          id={`triangle-points-${objectId}`}
          fill="transparent"
          stroke={`${handleTransparencyColor(borderColor, opacity) || "white"}`}
          strokeWidth={`${strokeWidth || "4"}`}
        />
      </svg>
      <HighlightAnnotation objectId={objectId} />
      <div
        style={{
          position: "absolute",
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          backdropFilter: `blur(${opacity === 0 ? 0 : blurIntensity ?? 0}px)`,
          WebkitBackdropFilter: `blur(${opacity === 0 ? 0 : blurIntensity ?? 0}px)`,
          backgroundColor: handleTransparencyColor(backgroundColor, opacity),
          cursor: "grab",
          clipPath: `url(#${clipPathId})`,
          pointerEvents: "none",
          filter: isDisplayed ? "none" : "grayScale(100%)",
        }}
      />
    </div>
  );
}
