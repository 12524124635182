import React from "react";
import { SmartObject } from "../../../types";
import { SmartObjectSvgRenderer } from "./Svg";

interface SmartObjectRendererProps {
  smartObject: SmartObject;
}

function SmartObjectRendererImpl(props: SmartObjectRendererProps) {
  const { smartObject } = props;
  const { elementList, objectId } = smartObject;

  return (
    <>
      {elementList.map((element, index) => {
        const { type } = element;
        if (type === "svg") {
          return <SmartObjectSvgRenderer key={`${objectId}-${index}`} element={element} objectId={objectId} />;
        }
      })}
    </>
  );
}

export const SmartObjectRenderer = React.memo(SmartObjectRendererImpl);
