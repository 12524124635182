import { forwardRef, MouseEventHandler } from "react";
import type { PopoverProps } from "rsuite/esm/Popover/Popover";
import { Popover } from "rsuite";

export type AudioEffectsPopoverAction = "assetLibrary" | "uploadAudio";

export interface AudioOptionsPopoverProps extends PopoverProps {
  onSelect: (action: AudioEffectsPopoverAction) => void;
}

export const AudioEffectsPopover = forwardRef<HTMLDivElement, AudioOptionsPopoverProps>(
  ({ onSelect, visible, className }, ref) => {
    const handleSelect = (action: AudioEffectsPopoverAction): MouseEventHandler => {
      return (event) => {
        event.stopPropagation();
        onSelect(action);
      };
    };

    return (
      <Popover ref={ref} visible={visible} className={className} full arrow={false}>
        <ul className="cpat-list">
          <li className="cpat-list-item" onClick={handleSelect("assetLibrary")}>
            Asset Library
          </li>
          {/* Not sure if supported? */}
          {/* <li className="cpat-list-item" onClick={handleSelect("uploadAudio")}>
            Upload Audio
          </li> */}
        </ul>
      </Popover>
    );
  },
);
AudioEffectsPopover.displayName = "AudioEffectsPopover";
