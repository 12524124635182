import React, { createContext, PropsWithChildren, useContext, useEffect, useReducer, useState } from "react";
import { IPageContext, PageContext } from "../../routes/builderContexts";
import { createLogger } from "../../utils";
import { useGlobalAudioState } from "../GlobalAudioProvider/GlobalAudioProvider";
import { useInteractivityBuilderState } from "../InteractivityBuilderProvider";

const log = createLogger("TimelineProvider");
const TimelineContext = createContext<null | TimelineState>(null);
const TimelineDispatchContext = createContext<null | any>(null);

export const SUB_UNITS_PER_UNIT = 16;

export interface TimelineState {
  heightInPx: number;
  subUnitsPerUnit: number;
  objs: any[];
  sequenceLength: number;
  unitSpace: {};
  sequencedObjectsAmount: number;
  scaledSpace: {
    leftPadding: number;
  };
  clippedSpace: {
    range: {
      start: number;
      end: number;
    };
  };
  widthInPx: number;
  /**
   * This is the current time of the scrubber in seconds
   */
  scrubbingCurrentTime: number;
  usingScrub: boolean;
}
const initialState: TimelineState = {
  objs: [],
  subUnitsPerUnit: SUB_UNITS_PER_UNIT,
  sequenceLength: 360,
  sequencedObjectsAmount: 0,
  unitSpace: {},
  scaledSpace: {
    leftPadding: 10,
  },
  clippedSpace: {
    range: {
      start: 0,
      end: 25,
    },
  },
  widthInPx: 0,
  heightInPx: 0,
  scrubbingCurrentTime: 0,
  usingScrub: false,
};

const second = 1;
const minute = second * 60;
const hour = minute * 60;

function ensureRange(range: TimelineState["clippedSpace"]["range"]) {
  if (range.end <= range.start) {
    range.end = range.start + 1;
  }
  if (range.start < 0) {
    const length = range.end - range.start;
    range.start = 0;
    range.end = length;
  }
  return range;
}
function timelineReducer(state: TimelineState, action: any): TimelineState {
  switch (action.type) {
    case "SET_RANGE": {
      const range = { ...action.payload };
      ensureRange(range);
      return {
        ...state,
        clippedSpace: {
          ...state.clippedSpace,
          range: {
            start: range.start,
            end: range.end,
          },
        },
      };
    }

    case "ADD_RANGE": {
      const range = {
        start: state.clippedSpace.range.start + action.payload.start,
        end: state.clippedSpace.range.end + action.payload.end,
      };
      ensureRange(range);
      return {
        ...state,
        clippedSpace: {
          ...state.clippedSpace,
          range,
        },
      };
    }
    case "SET_WIDTH_PX": {
      return {
        ...state,
        widthInPx: action.payload,
      };
    }
    case "SET_SEQUENCED_OBJECTS_AMOUNT": {
      return {
        ...state,
        sequencedObjectsAmount: action.payload,
      };
    }
    case "SET_HEIGHT_PX": {
      return {
        ...state,
        heightInPx: action.payload,
      };
    }
    case "SET_OBJ": {
      return {
        ...state,
        objs: state.objs.map((obj) => {
          if (obj.id === action.payload.id) {
            return { ...action.payload };
          }
          return obj;
        }),
      };
    }
    case "SET_SEQUENCE_LENGTH": {
      let length = action.payload;
      if (length < 0) {
        length = 0;
      }
      return {
        ...state,
        sequenceLength: length,
      };
    }
    case "SET_SCRUBBING_CURRENT_TIME": {
      const currentPostionClipped = action.payload;
      let newUnitSpace = clippedSpaceToUnitSpace(
        currentPostionClipped,
        state.scaledSpace.leftPadding,
        state.clippedSpace,
        state.widthInPx,
      );
      if (newUnitSpace < 0) {
        newUnitSpace = 0;
      }
      // snap to unit
      const unit = snapToTimeline(newUnitSpace);

      return {
        ...state,
        scrubbingCurrentTime: unit,
      };
    }
    case "SET_SECONDS": {
      return {
        ...state,
        scrubbingCurrentTime: action.payload,
      };
    }
    case "SNAP_TO_GRID": {
      const unit = snapToGrid(state.scrubbingCurrentTime, state.subUnitsPerUnit);
      return {
        ...state,
        scrubbingCurrentTime: unit,
      };
    }
    case "SET_USING_SCRUB": {
      return {
        ...state,
        usingScrub: action.payload,
      };
    }
    default:
      return state;
  }
}

function TimelineProvider({ children }: PropsWithChildren<any>) {
  const [state, dispatch] = useReducer(timelineReducer, initialState);

  return (
    <TimelineDispatchContext.Provider value={dispatch}>
      <TimelineContext.Provider value={state}>{children}</TimelineContext.Provider>
    </TimelineDispatchContext.Provider>
  );
}

export function scaledSpaceFromUnitSpace(
  unitSpace: number,
  clippedSpace: TimelineState["clippedSpace"],
  widthInPx: number,
) {
  const unitsShownInClippedSpace = clippedSpace.range.end - clippedSpace.range.start;
  const pixelsShownInClippedSpace = widthInPx;
  const pixelToUnitRatio = pixelsShownInClippedSpace / unitsShownInClippedSpace;
  return unitSpace * pixelToUnitRatio;
}
export function scaledSpaceToUnitSpace(
  scaledSpace: number,
  clippedSpace: TimelineState["clippedSpace"],
  widthInPx: number,
) {
  const unitsShownInClippedSpace = clippedSpace.range.end - clippedSpace.range.start;
  const pixelsShownInClippedSpace = widthInPx;
  const unitToPixelRatio = unitsShownInClippedSpace / pixelsShownInClippedSpace;
  return scaledSpace * unitToPixelRatio;
}
export function clippedSpaceFromUnitSpace(
  unit: number,
  leftPadding: number,
  clippedSpace: TimelineState["clippedSpace"],
  widthInPx: number,
) {
  const unitSpace = unit - clippedSpace.range.start;
  const scaledSpace = scaledSpaceFromUnitSpace(unitSpace, clippedSpace, widthInPx);
  return scaledSpace + leftPadding;
}
export function clippedSpaceToUnitSpace(
  clippedUnit: number,
  leftPadding: number,
  clippedSpace: TimelineState["clippedSpace"],
  widthInPx: number,
) {
  const scaledSpace = clippedUnit - leftPadding;
  const unitSpace = scaledSpaceToUnitSpace(scaledSpace, clippedSpace, widthInPx);
  return unitSpace + clippedSpace.range.start;
}
export function formatFullUnitForGrid(posInUnitSpace: number, subunitsPerUnit: number): string {
  let p = posInUnitSpace;

  let s = "";

  if (p >= hour) {
    const hours = Math.floor(p / hour);
    s += hours + "h";
    p = p % hour;
  }

  if (p >= minute) {
    const minutes = Math.floor(p / minute);
    s += minutes + "m";
    p = p % minute;
  }

  if (p >= second) {
    const seconds = Math.floor(p / second);
    s += seconds + "s";
    p = p % second;
  }

  const frame = 1 / subunitsPerUnit;

  if (p >= frame) {
    const frames = Math.floor(p / frame);
    s += frames + "f";
    p = p % frame;
  }

  return s.length === 0 ? "0s" : s;
}
export function formatSubUnitForGrid(posInUnitSpace: number, subunitsPerUnit: number): string {
  const subSecondPos = posInUnitSpace % 1;
  const frame = 1 / subunitsPerUnit; //4

  // 0.25 * s

  const frames = Math.round(subSecondPos / frame);
  // return (posInUnitSpace + subSecondPos) + "x";
  return posInUnitSpace + "s";
}

export function snapToTimeline(finalUnitPosition: number, subUnitsPerUnit: number = SUB_UNITS_PER_UNIT) {
  return Math.round(finalUnitPosition * subUnitsPerUnit) / subUnitsPerUnit;
}

export function snapToGrid(positionInUnitSpace: number, subUnitsPerUnit: number): number {
  const gridLength = 1 / subUnitsPerUnit;
  return parseFloat((Math.round(positionInUnitSpace / gridLength) * gridLength).toFixed(3));
}

function useTimeline() {
  const state = useContext(TimelineContext);
  if (!state) {
    throw new Error("useTimeline must be used within a TimelineProvider");
  }
  const dispatch = useContext(TimelineDispatchContext);
  if (!dispatch) {
    throw new Error("useTimeline must be used within a TimelineProvider");
  }
  // State aware helpers, to reduce parameters that can be accessed from this side.
  const contextAwareMethods = {
    clippedSpaceToUnitSpace: (clippedSpace: number) => {
      return clippedSpaceToUnitSpace(clippedSpace, state.scaledSpace.leftPadding, state.clippedSpace, state.widthInPx);
    },
    clippedSpaceFromUnitSpace: (unitSpace: number) => {
      return clippedSpaceFromUnitSpace(unitSpace, state.scaledSpace.leftPadding, state.clippedSpace, state.widthInPx);
    },
    getTime: () => {
      return state.scrubbingCurrentTime;
    },
  };
  return [state, dispatch, contextAwareMethods] as const;
}

export function useTimelineDispatch() {
  const dispatch = useContext(TimelineDispatchContext);
  if (!dispatch) {
    throw new Error("useTimeline must be used within a TimelineProvider");
  }
  return dispatch;
}

export { TimelineProvider, useTimeline };
