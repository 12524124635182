import { useShallow } from "zustand/react/shallow";
import { snapToTimeline, useTimeline } from "../../../../contexts/TimelineProvider/TimelineProvider";
import { useDomRef } from "../../../../hooks/useDomRef";
import { AudioEffectObject, ConflictType, useAudioEffectStore } from "./store";
import { useObjectsUtils } from "../../../../contexts/ObjectsProvider";
import { useAudioManagerStore } from "../../../../contexts/PageAudioManager";
import { useMemo } from "react";
import useDrag from "../../../../hooks/useDrag";
import { AudioEffectsPopoverAction } from "./AudioEffectPopover";
import classes from "../../../../utils/HTML/classes";
import { AddEffectButton } from ".";
import { BAR_HEIGHT } from "../../TimelinePanel";
import "./AudioEffectItem.css";
import { AudioEffectWaveBar } from "./AudioEffectWaveBar";

interface AudioEffectItemProps {
  item: AudioEffectObject;
  onClickAdd: (action: AudioEffectsPopoverAction) => void;
  onMoved: (newStart: number) => void;
}

export const AudioEffectItem = ({ item, onClickAdd, onMoved }: AudioEffectItemProps) => {
  const [, , { clippedSpaceFromUnitSpace, clippedSpaceToUnitSpace }] = useTimeline();
  const [audioBarRef, setRef] = useDomRef();
  const [objects, setDragging, isDragging, setCurrentPosition, setConflict, conflictType, conflictObject] =
    useAudioEffectStore(
      useShallow((state) => [
        state.objects,
        state.setDragging,
        state.isDragging,
        state.setCurrentPosition,
        state.setConflict,
        state.conflictType,
        state.conflictObject,
      ]),
    );

  const { unselectAll } = useObjectsUtils();
  const [selectedAudioId, selectAudio, clearSelection] = useAudioManagerStore(
    useShallow((state) => [state.selectedAudio, state.selectAudio, state.clearSelection]),
  );
  const [currentAudioEffect] = useAudioManagerStore(useShallow((state) => [state.getEffectAudio(item.objectId)]));

  const otherAudios = useMemo(() => [...objects.values()].filter((i) => i.objectId !== item.objectId), [objects]);

  const handleAddAudio = (action: AudioEffectsPopoverAction) => {
    onClickAdd(action);
  };

  const handleSelectAudio = (objectId: string, unselect?: boolean) => {
    if (unselect) {
      clearSelection();
    } else {
      selectAudio(objectId);
    }
    unselectAll();
  };

  const start = item.start;
  const clippedStart = clippedSpaceFromUnitSpace(start ?? 0);
  const width = clippedSpaceFromUnitSpace(item.end) - clippedSpaceFromUnitSpace(item.start);

  const [draggingMiddle] = useDrag(audioBarRef, {
    debugName: `Audio Effect ${item.objectId}`,
    onDragStart() {
      const initialPosition = clippedStart;
      let finalUnitPosition: number;

      return {
        onDrag(dx) {
          if (!audioBarRef) return;
          setDragging(item.objectId);

          const newPosition = initialPosition + dx;
          finalUnitPosition = clippedSpaceToUnitSpace(newPosition);
          setCurrentPosition(clippedSpaceToUnitSpace(newPosition));

          // Check here if we are hovering other audios
          let noConflicts = true;
          otherAudios.forEach((otherAudio) => {
            const leftSideConflict =
              finalUnitPosition > otherAudio.start &&
              finalUnitPosition < otherAudio.start + (otherAudio.end - otherAudio.start) / 2;
            const rightSideConflict =
              finalUnitPosition > otherAudio.start + (otherAudio.end - otherAudio.start) / 2 &&
              finalUnitPosition < otherAudio.end;

            if (leftSideConflict) {
              setConflict(otherAudio.objectId, ConflictType.LEFT);
              noConflicts = noConflicts && false;
            } else if (rightSideConflict) {
              noConflicts = noConflicts && false;
              setConflict(otherAudio.objectId, ConflictType.RIGHT);
            }
          });

          if (noConflicts) {
            setConflict(null);
          }

          audioBarRef.style.left = newPosition + "px";
          audioBarRef.classList.add("narration-grouped-audios--dragging");
        },
        onDragEnd(dragHappened, mouseEvent) {
          if (!dragHappened) return;
          if (!audioBarRef) return;

          let snappedUnitPosition = snapToTimeline(finalUnitPosition);
          if (snappedUnitPosition < 0) {
            snappedUnitPosition = 0;
          }

          const newClippedSpace = clippedSpaceFromUnitSpace(item?.start ?? 0);

          audioBarRef.style.left = newClippedSpace + "px";

          audioBarRef.classList.remove("narration-grouped-audios--dragging");
          onMoved(snappedUnitPosition);

          // mouseEvent
          if (mouseEvent) {
            mouseEvent.stopPropagation();
            mouseEvent.preventDefault();
          }

          setDragging(null);
        },
        onClick(mouseUpEvent) {
          mouseUpEvent.preventDefault();
          mouseUpEvent.stopPropagation();
        },
      };
    },
  });

  const isConflict = item.objectId === conflictObject;
  const cursorLock = draggingMiddle
    ? conflictObject && conflictType === ConflictType.LEFT
      ? "url('/Mouse/snap-left.svg') 10 5, auto"
      : conflictObject && conflictType === ConflictType.RIGHT
      ? "url('/Mouse/snap-right.svg') 10 5, auto"
      : "url('/Mouse/move-row.svg') 10 5, auto"
    : "";

  return (
    <div
      className={classes("audio-effect-item-wrapper", {
        "audio-effect--dragging": draggingMiddle,
        "audio-effect--left": isConflict && conflictType === ConflictType.LEFT,
        "audio-effect--right": isConflict && conflictType === ConflictType.RIGHT,
      })}
      ref={setRef}
      style={{
        left: clippedStart,
        width: `${width}px`,
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <AddEffectButton handleAddAudio={handleAddAudio} disabled={isDragging} />

      <div
        className={classes("pointer-events center audio-bar-middle-handle", {
          "pointer--dragging": !draggingMiddle,
        })}
        style={{
          height: BAR_HEIGHT,
          cursor: cursorLock,
        }}
      >
        <div className="drop-indicator drop-indicator--left"></div>

        <AudioEffectWaveBar
          objectId={item.objectId}
          onSelect={(unselect) => {
            handleSelectAudio(item.objectId, unselect);
          }}
          selected={selectedAudioId === item.objectId}
        />

        <div className="drop-indicator drop-indicator--right"></div>
      </div>
    </div>
  );
};
