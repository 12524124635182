import "./ColorPicker.css";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { standardColors } from "../../../utils/Colors/colors";

type StandardColorsType = {
  keyValue: "fontColor" | "backgroundColor" | "borderColor" | "annotationFontColor";
};

const StandardColors = ({ keyValue }: StandardColorsType): JSX.Element => {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObject = objectsState.selectedObjects[0];

  const handleColorSelect = (color: string, keyValue: string) => {
    if (keyValue === "backgroundColor") {
      objectsDispatch({
        type: ObjectActionsType.UPDATE_TABLE,
        payload: { type: "backgroundColor", value: color },
      });
      objectsDispatch({
        type: ObjectActionsType.SET_BACKGROUND_COLOR,
        payload: {
          color: { hex: color },
          previousColor: {
            hex: objectsState.selectedObjects[0].borderColor ? objectsState.selectedObjects[0].borderColor : "#FFFFFF",
          },
          objectId: selectedObject.objectId,
        },
      });
    } else if (keyValue === "borderColor") {
      objectsDispatch({
        type: ObjectActionsType.UPDATE_TABLE,
        payload: { type: "borderColor", value: color },
      });
      objectsDispatch({
        type: ObjectActionsType.SET_BORDER_COLOR,
        payload: {
          color: { hex: color },
          previousColor: {
            hex: objectsState.selectedObjects[0].borderColor ? objectsState.selectedObjects[0].borderColor : "#FFFFFF",
          },
          objectId: selectedObject.objectId,
        },
      });
    } else if (keyValue === "annotationFontColor") {
      objectsDispatch({
        type: ObjectActionsType.SET_ANNOTATION_FONT_COLOR,
        payload: {
          color: { hex: color },
          objectId: selectedObject.objectId,
        },
      });
    }
  };

  return (
    <>
      <div className="object-properties-section-title">Standard Colors</div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "3px", rowGap: "10px" }}>
        <div
          className="colorBox"
          style={{
            backgroundColor: "transparent",
            height: "20px",
            width: "20px",
            border: "1px solid ",
            borderRadius: "2px",
            color: "red",
            fontSize: "large",
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
          onClick={() => handleColorSelect("transparent", keyValue)}
        >
          ⊘
        </div>
        {standardColors.map((color, index) => {
          return (
            <div
              key={index}
              className="colorBox"
              style={{
                backgroundColor: color,
                height: "20px",
                width: "20px",
                border: "1px solid ",
                borderRadius: "2px",
              }}
              onClick={() => handleColorSelect(color, keyValue)}
            ></div>
          );
        })}
      </div>
    </>
  );
};

export default StandardColors;
