import { flattenObject } from "../Object/flattenObject";
import { deflattenObject } from "../Object/deflattenObject";

export const objectToFrameValue = (object: Record<string, any>) => {
  return flattenObject(object);
};

export const frameValueToObject = (object: Record<string, any>) => {
  return deflattenObject(object);
};
