import Moveable from "react-moveable";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { BlurCutoutObject } from "../../types";
import { flattenObject } from "../../utils/Object/flattenObject";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { useAnimatedObject } from "../../hooks/useAnimatedObject";

export function MaskMoveable() {
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const { selectedObjects } = useObjectsState();
  const selectedObjectState = useSelectedObjectState();
  const [miscUI, setMiscUI] = useMiscUI();
  const [tl] = useTimeline();
  const currentTime = tl?.scrubbingCurrentTime;
  const currentCutoutId = miscUI.selectedMask[0];
  const currentSelectedObjectId = selectedObjects[0]?.objectId;
  const currentSelectedObject = selectedObjects[0];
  const animatedObject = useAnimatedObject(currentSelectedObject?.objectId);
  const frameAtCurrentTime = animatedObject?.frames?.find((frame) => frame.timestamp === currentTime);
  // const currentCutout = (selectedObjects[0]?.blurCutoutShapes || [])?.filter((item) => item?.id === currentCutoutId);
  const currentCutout = selectedObjectState?.blurCutoutShapes.find((item) => item?.id === currentCutoutId);
  const moveableRef = useRef<Moveable>(null);
  const previousSelectedObject = useRef<string | null>(null);

  const handleCutoutUpdate = useCallback(
    (props: Partial<BlurCutoutObject>) => {
      if (!currentCutout || !selectedObjectState) {
        return;
      }

      const currentCutoutIndex = selectedObjectState.blurCutoutShapes.findIndex(
        (cutout) => cutout.id === currentCutoutId,
      );
      const updatedCutouts = selectedObjectState.blurCutoutShapes;
      updatedCutouts.splice(currentCutoutIndex, 1, {
        ...currentCutout,
        ...props,
      });

      selectedObjectDispatch({
        type: SelectedObjectActionTypes.SET_BLUR_CUTOUT,
        payload: updatedCutouts,
      });

      objectsDispatch({
        type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
        payload: {
          objectId: currentSelectedObjectId,
          cutoutId: currentCutoutId,
          props,
        },
      });

      objectsDispatch({
        type: ObjectActionsType.UPSERT_OBJECT_FRAME,
        payload: {
          objectId: currentSelectedObjectId,
          frame: {
            timestamp: currentTime,
            blurCutoutShapes: flattenObject({
              ...(frameAtCurrentTime?.blurCutoutShapes ?? {}),
              ...flattenObject({ [currentCutoutId]: props }),
            }),
          },
        },
      });
    },
    [JSON.stringify(selectedObjectState.blurCutoutShapes), currentCutout?.id],
  );

  useLayoutEffect(() => {
    if (moveableRef.current) {
      moveableRef.current.updateRect();
    }
  });

  /**
   * This useEffect clears the selected mask id when a selected
   * object changes, to eliminate the green bounding box inside
   * the shape elements.
   *
   * Not the best approach, might need to refactor this later.
   */
  useEffect(() => {
    if (selectedObjects.length === 0) return;

    if (!previousSelectedObject.current) {
      previousSelectedObject.current = selectedObjects[0].objectId;
    }

    if (selectedObjects[0].objectId !== previousSelectedObject.current) {
      previousSelectedObject.current = selectedObjects[0].objectId;
      setMiscUI({
        type: "SET_SELECTED_MASK_ID",
        payload: selectedObjects[0].objectId,
      });
    }
  }, [selectedObjects]);

  return (
    <Moveable
      target={[`#handle-${currentCutoutId}`]}
      dragTargetSelf={true}
      container={null}
      origin={true}
      edge={false}
      ref={moveableRef}
      draggable={true}
      throttleDrag={0}
      clickable={true}
      renderDirections={["s", "se", "e"]}
      onDrag={({ translate }) => {
        handleCutoutUpdate({
          x: translate[0],
          y: translate[1],
        });
        // objectsDispatch({
        //   type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
        //   payload: {
        //     objectId: currentSelectedObjectId,
        //     cutoutId: currentCutoutId,
        //     props: {
        //       x: translate[0],
        //       y: translate[1],
        //     },
        //   },
        // });
      }}
      resizable={true}
      throttleResize={0}
      onResize={({ width, height }) => {
        handleCutoutUpdate({
          w: width,
          h: height,
        });
        // objectsDispatch({
        //   type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
        //   payload: {
        //     objectId: currentSelectedObjectId,
        //     cutoutId: currentCutoutId,
        //     props: {
        //       w: width,
        //       h: height,
        //     },
        //   },
        // });
      }}
      rotatable={true}
      throttleRotate={0}
      onRotate={({ dist }) => {
        handleCutoutUpdate({
          rotate: dist,
        });
        // objectsDispatch({
        //   type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
        //   payload: {
        //     objectId: currentSelectedObjectId,
        //     cutoutId: currentCutoutId,
        //     props: {
        //       rotate: dist,
        //     },
        //   },
        // });
      }}
    />
  );
}
