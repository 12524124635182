export const deflattenObject = (flatObject: Record<string, unknown>) => {
  if (Object(flatObject) !== flatObject || Array.isArray(flatObject)) {
    return flatObject;
  }

  const regex = /\.?([^.[\]]+)|\[(\d+)\]/g;
  const inflatedObject: Record<string, any> = {};

  for (const path in flatObject) {
    let currentObject = inflatedObject,
      property = "",
      match;

    while ((match = regex.exec(path))) {
      currentObject = currentObject[property] || (currentObject[property] = match[2] ? [] : {});
      property = match[2] || match[1];
    }

    currentObject[property] = flatObject[path];
  }

  return inflatedObject[""] || inflatedObject;
};
