import React, { useLayoutEffect } from "react";
import { OBJECT_ROW_HEIGHT, TOP_STRIP_HEIGHT } from "../TimelinePanel";
import "./Left.css";
import useRefAndState from "../../../hooks/useRefAndState";
import { useReceiveVerticalWheelEvent } from "../VerticalScrollContainer";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { useMetaVariableStore } from "../../../lib/SmartObject/store";
import { getMetaVariableNameFromKey } from "../../../lib/SmartObject/utils";
import { useAudioManagerStore } from "../../../contexts/PageAudioManager";
import { useShallow } from "zustand/react/shallow";

import { ReactComponent as X } from "assets/icons/Common/remove-tag-icon.svg";
import { ReactComponent as Ghost } from "assets/icons/timeline/ghost.svg";
import { ReactComponent as AudioIcon } from "assets/icons/audio/audio-icon.svg";
import { ReactComponent as MutedAudioIcon } from "assets/icons/audio/mute-icon.svg";
import { LanguageType } from "../../../models/IInteractiveAudio";
import classes from "../../../utils/HTML/classes";

const listenerOptions = {
  capture: true,
  passive: false,
};

export default function Left() {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const [ref, state] = useRefAndState<HTMLDivElement | null>(null);
  const receiveVerticalWheelEvent = useReceiveVerticalWheelEvent();
  const animatedMetaVariables = useMetaVariableStore((s) => s.animatedMetaVariables);
  const deleteAnimatedMetaVariable = useMetaVariableStore((s) => s.deleteAnimatedMetaVariable);
  const [currentLanguage, setLanguage, isPlaying] = useAudioManagerStore(
    useShallow((state) => [state.selectedLanguage, state.setLanguage, state.isPlaying]),
  );

  // const filteredHotspots = hotspots?.filter((hp) => hp.delay);

  useLayoutEffect(() => {
    if (!state) {
      return;
    }
    const handleWheel = (e: WheelEvent) => {
      receiveVerticalWheelEvent(e);
      e.preventDefault();
      e.stopPropagation();
    };
    state.addEventListener("wheel", handleWheel, listenerOptions);

    return () => {
      state.removeEventListener("wheel", handleWheel, listenerOptions);
    };
  }, [state]);

  if (!objectsState.animatedObjects) {
    return null;
  }

  const handleLanguageSwitch = (language: LanguageType) => {
    if (isPlaying || currentLanguage === language) {
      return;
    } else {
      setLanguage(language);
    }
  };

  // TODO: this could be done each time we update the objects instead of each render
  const zIndexMap = Object.fromEntries(objectsState.objectList.map((o) => [o.objectId, o.zIndex ?? 0]));
  return (
    <div ref={ref} className="left-container">
      <div
        style={{
          marginTop: TOP_STRIP_HEIGHT,
          paddingBottom: "10px",
        }}
      >
        <ul className="left-obj-list">
          <li key={"narration-in-timeline"} className="object-in-timeline " style={{ height: OBJECT_ROW_HEIGHT }}>
            <div className="w-1/4">Narration</div>
            <div className="w-3/4 flex justify-end items-center">
              <button
                title="Switch to Primary Audio"
                className={classes("cpat-icon-button", {
                  "cpat-icon-button--readonly": currentLanguage === LanguageType.PRIMARY,
                })}
                onClick={() => handleLanguageSwitch(LanguageType.PRIMARY)}
                disabled={isPlaying}
              >
                {currentLanguage === LanguageType.PRIMARY ? <AudioIcon /> : <MutedAudioIcon />}
              </button>
            </div>
          </li>

          <li
            key={"alternative-narration-in-timeline"}
            className="object-in-timeline "
            style={{ height: OBJECT_ROW_HEIGHT }}
          >
            <div className="w-1/4">Alternative Narration</div>
            <div className="w-3/4 flex justify-end items-center">
              <button
                title="Switch to Alternative Audio"
                className={classes("cpat-icon-button", {
                  "cpat-icon-button--readonly": currentLanguage === LanguageType.SECONDARY,
                })}
                onClick={() => handleLanguageSwitch(LanguageType.SECONDARY)}
                disabled={isPlaying}
              >
                {currentLanguage === LanguageType.SECONDARY ? <AudioIcon /> : <MutedAudioIcon />}
              </button>
            </div>
          </li>
          <li key={"audio-effects-in-timeline"} className="object-in-timeline " style={{ height: OBJECT_ROW_HEIGHT }}>
            <div className="w-1/4">Audio Effects</div>
            <div className="w-3/4 flex justify-end items-center">
              {/* <button
                title="Switch to Audio Effects"
                className={classes("cpat-icon-button", {
                  "cpat-icon-button--readonly": currentLanguage === LanguageType.SECONDARY,
                })}
                onClick={() => handleLanguageSwitch(LanguageType.SECONDARY)}
                disabled={isPlaying}
              >
                {currentLanguage === LanguageType.SECONDARY ? <AudioIcon /> : <MutedAudioIcon />}
              </button> */}
            </div>
          </li>

          {!!objectsState.animatedObjects.length &&
            [...objectsState.animatedObjects]
              .sort(
                // sort by arrangement
                (a, b) => zIndexMap[b.id] - zIndexMap[a.id],
              )
              .map((animatedObject) => {
                const obj = objectsState.objectList.find((o) => o.objectId === animatedObject.id);
                const animatedMetaVariablesForObj = animatedMetaVariables.filter(
                  (amv) => amv.objectId === animatedObject.id,
                );
                const objName = obj?.name ?? "";
                const objDisplayName = obj?.displayName ?? "";
                const isGhost = obj?.ghost ?? false;
                return (
                  <React.Fragment key={animatedObject.id}>
                    <li
                      key={animatedObject.id + "object-in-timeline"}
                      className="object-in-timeline"
                      style={{ height: OBJECT_ROW_HEIGHT }}
                      onClick={(e) => {
                        if (!animatedObject.id) return;
                        let type = ObjectActionsType.SET_SELECTED_OBJECT;
                        if (e.ctrlKey) {
                          type = ObjectActionsType.ADD_SELECTED_OBJECT;
                        }
                        objectsDispatch({
                          type,
                          payload: {
                            objectId: animatedObject.id,
                            domRef: e.currentTarget,
                          },
                        });
                      }}
                    >
                      <div className="w-1/4">{(objDisplayName ? objDisplayName : objName) ?? ""}</div>
                      <div className="w-3/4 flex justify-end items-center">
                        <span
                          className="h-full"
                          style={{ pointerEvents: "all", cursor: "pointer" }}
                          onClick={() => {
                            objectsDispatch({
                              type: ObjectActionsType.TOGGLE_OBJECT_GHOST,
                              objectId: animatedObject.id,
                            });
                          }}
                        >
                          <Ghost className={isGhost ? "ghost-icon-on" : ""} />
                        </span>
                        <span
                          style={{ pointerEvents: "all", cursor: "pointer" }}
                          onClick={() => {
                            objectsDispatch({
                              type: ObjectActionsType.DELETE_ANIMATED_OBJECT,
                              objectId: animatedObject.id,
                            });
                          }}
                        >
                          <X />
                        </span>
                      </div>
                    </li>
                    {animatedMetaVariablesForObj.map((variable) => {
                      const metavariableName = getMetaVariableNameFromKey(variable.metaVariableKey, variable.objectId);
                      return (
                        <li
                          key={variable.metaVariableKey + "object-in-timeline"}
                          className="object-in-timeline"
                          style={{ height: OBJECT_ROW_HEIGHT, marginLeft: "20px" }}
                          onClick={(e) => {
                            // TODO
                          }}
                        >
                          <div className="w-1/4">{metavariableName}</div>
                          <div className="w-3/4 flex justify-end items-center">
                            <span
                              style={{ pointerEvents: "all", cursor: "pointer" }}
                              onClick={() => {
                                deleteAnimatedMetaVariable(variable.metaVariableKey);
                              }}
                            >
                              <X />
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </React.Fragment>
                );
              })}
        </ul>
      </div>
    </div>
  );
}
