import "./ColorPicker.css";
import { useEffect, useState } from "react";
import { HexAlphaColorPicker } from "react-colorful";
import { formatFunctions } from "../functions/WYSIWYGToolbarFunctions";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import StandardColors from "./StandardColors";
import { DropSeparator, DropSeparatorSmoll } from "../../../panels/ObjectPropertiesPanel";

type ColorPickerType = {
  selectedObject: object;
  keyValue: "fontColor" | "backgroundColor" | "borderColor" | "annotationFontColor";
  handleFormatChange(e: any, funct: any, color?: any, keyValue?: string): void;
  color: string;
  hideTransparency?: boolean;
};

const ColorPicker = ({ keyValue, handleFormatChange, color, hideTransparency }: ColorPickerType): JSX.Element => {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const selectedObject = objectsState.selectedObjects[0];

  const [transparent, setTransparent] = useState<HTMLDivElement | null>(null);
  const [isTransparentColor, setIsTransparentColor] = useState(false);
  const [typedColor, setTypedColor] = useState(color);
  const [tempColor, setTempColor] = useState(color);
  const [showColorSlider, setShowColorSlider]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);

  useEffect(() => {
    const transparentDiv: HTMLDivElement | null = document.querySelector("div[title='transparent']");
    setTransparent(transparentDiv);
    setTypedColor(color);
    const keyColorController = () => {
      if (keyValue === "backgroundColor") {
        return selectedObject?.previousBackgroundColor;
      } else if (keyValue === "borderColor") {
        return selectedObject?.previousBorderColor;
      }
      return undefined;
    };
    setTempColor(keyColorController() || color);
  }, []);

  useEffect(() => {
    setTypedColor(color === "white" ? "#ffffff" : color);
    if (color === "white") {
      handleChange("#ffffff", "#ffffff");
    }
  }, [selectedObject]);

  if (transparent) {
    transparent.onclick = () => handleFormatChange(null, formatFunctions.changeColor, "transparent", keyValue);
  }

  useEffect(() => {
    if (selectedObject) {
      if (keyValue === "backgroundColor" && selectedObject.backgroundColor === "transparent") {
        setIsTransparentColor(true);
        return;
      } else if (keyValue === "borderColor" && selectedObject.borderColor === "transparent") {
        setIsTransparentColor(true);
        return;
      } else if (keyValue === "borderColor" && selectedObject.border === "1px solid transparent") {
        setIsTransparentColor(true);
        return;
      } else if (keyValue === "annotationFontColor" && selectedObject.fontColor === "transparent") {
        setIsTransparentColor(true);
        return;
      }
      setIsTransparentColor(false);
    }
  }, [typedColor, selectedObject]);

  const handleChange = (color = "", temporaryColor = "", transparentColor = false) => {
    const colorHex = { hex: transparentColor || color.includes("NaN") ? "transparent" : color.substring(0, 7) };
    const tempColorHex = { hex: temporaryColor };
    setTypedColor(colorHex.hex);
    if (selectedObject && selectedObject.type !== "table") {
      if (keyValue === "backgroundColor") {
        objectsDispatch({
          type: ObjectActionsType.SET_BACKGROUND_COLOR,
          payload: {
            color: colorHex,
            previousColor: tempColorHex,
            objectId: selectedObject.objectId,
          },
        });
      } else if (keyValue === "borderColor") {
        objectsDispatch({
          type: ObjectActionsType.SET_BORDER_COLOR,
          payload: {
            color: colorHex,
            previousColor: tempColorHex,
            objectId: selectedObject.objectId,
          },
        });
      } else if (keyValue === "annotationFontColor") {
        objectsDispatch({
          type: ObjectActionsType.SET_ANNOTATION_FONT_COLOR,
          payload: {
            color: colorHex,
            objectId: selectedObject.objectId,
          },
        });
      }
    } else if (selectedObject && selectedObject.type === "table") {
      if (keyValue === "backgroundColor") {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_TABLE,
          payload: { type: "backgroundColor", value: colorHex.hex },
        });
      } else if (keyValue === "borderColor") {
        objectsDispatch({
          type: ObjectActionsType.UPDATE_TABLE,
          payload: { type: "borderColor", value: colorHex.hex },
        });
      }
    } else {
      handleFormatChange(formatFunctions.changeColor, color, keyValue);
    }
  };

  const transparentColorHandler = () => {
    if (isTransparentColor) {
      setIsTransparentColor(false);
      handleChange(tempColor, typedColor, false);
      return;
    }
    setIsTransparentColor(true);
    setTempColor(typedColor);
    handleChange("", typedColor, true);
  };

  const inputColorHandler = (color: string) => {
    const normalizedColor = color.startsWith("#") ? color : `#${color}`;
    setTypedColor(normalizedColor);
    handleChange(normalizedColor, normalizedColor, false);
  };
  return (
    <div className="section-group">
      <StandardColors keyValue={keyValue} />
      <DropSeparatorSmoll title={"Custom"} setShowMenu={setShowColorSlider} showMenu={showColorSlider} />
      {showColorSlider && (
        <>
          <HexAlphaColorPicker
            style={{ width: "100%", height: "105px", alignSelf: "center" }}
            onChange={handleChange}
            color={typedColor || color}
          />
          <div className="color-box">
            <input
              style={{ width: !hideTransparency ? "100%" : "100%", marginTop: "4px", textAlign: "center" }}
              type="text"
              value={(typedColor || color)?.replace("#", "")}
              onChange={(e) => inputColorHandler(e.target.value)}
            />
            {!hideTransparency && (
              <label style={{ width: "50%" }}>
                Transparent
                <input type="checkbox" checked={isTransparentColor} onChange={transparentColorHandler} />
              </label>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ColorPicker;
