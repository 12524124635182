import React, { CSSProperties, SVGProps, useMemo } from "react";
import { SVG_SMART_OBJECT_TYPES, SmartObjectElement } from "../..";
import { SmartObjectSvgHotSpot } from "./HotSpot";
import { SmartObjectSvgRotate } from "./Rotate";
import { useShouldRender } from "./useShouldRender";
import { SmartObjectSvgFillRenderer } from "./Fill";
import { SmartObjectSvgImageSwapRenderer } from "./ImageSwap";
import { useSmartObjectStyles } from "./useSmartObjectStyles";
import { SmartObjectSvgDynamicTextRenderer } from "./DynamicText";
import { cleanSmartObjectText, getSmartObjectViewBoxMetaVariableKeys } from "../../utils";
import { useMetaVariableStore } from "../../store";
import { SmartObjectSvgImageTextRenderer } from "./ImageText";

export interface SmartObjectSvgRendererProps {
  element: SmartObjectElement;
  objectId?: string;
  parentId?: string | undefined;
  extraStyles?: CSSProperties;
  className?: any;
  onClick?: () => void;
}

export interface SmartObjectComponent {
  element: SmartObjectElement;
  objectId?: string;
  parentId?: string | undefined;
  extraStyles?: CSSProperties;
  className?: any;
  onClick?: () => void;
}

export function SmartObjectSvgRenderer({ element, objectId, parentId, extraStyles }: SmartObjectSvgRendererProps) {
  const { type, GROUP_ID, TYPE } = element;
  const uniqueKey = `${parentId}-${objectId}-${type}-${GROUP_ID}`;

  const svgId = GROUP_ID ?? parentId ?? undefined;
  if (TYPE === SVG_SMART_OBJECT_TYPES.HOTSPOT) {
    return <SmartObjectSvgHotSpot key={uniqueKey} element={element} objectId={objectId} parentId={parentId} />;
  }
  if (TYPE === SVG_SMART_OBJECT_TYPES.ROTATE) {
    return <SmartObjectSvgRotate key={uniqueKey} element={element} objectId={objectId} parentId={parentId} />;
  }

  if (TYPE === SVG_SMART_OBJECT_TYPES.FILL_VALUE) {
    return <SmartObjectSvgFillRenderer key={uniqueKey} element={element} objectId={objectId} parentId={parentId} />;
  }

  if (TYPE === SVG_SMART_OBJECT_TYPES.IMAGE_SWAP) {
    return (
      <SmartObjectSvgImageSwapRenderer key={uniqueKey} element={element} objectId={objectId} parentId={parentId} />
    );
  }

  if (TYPE === SVG_SMART_OBJECT_TYPES.DYNAMIC_TEXT) {
    return (
      <SmartObjectSvgDynamicTextRenderer key={uniqueKey} element={element} objectId={objectId} parentId={parentId} />
    );
  }

  if (TYPE === SVG_SMART_OBJECT_TYPES.IMAGE_TEXT) {
    return (
      <SmartObjectSvgImageTextRenderer key={uniqueKey} element={element} objectId={objectId} parentId={parentId} />
    );
  }

  if (TYPE === SVG_SMART_OBJECT_TYPES.FONT) {
    /**
     * We dont want to show the font images in the
     * smart object, they are used in the meta variables store
     */
    return null;
  }

  switch (type) {
    case "svg": {
      return <MemoizedSmartObjectSvgContainer key={uniqueKey} element={element} objectId={objectId} parentId={svgId} />;
    }
    case "path": {
      return <SmartObjectSvgPath key={uniqueKey} element={element} objectId={objectId} parentId={svgId} />;
    }
    case "g": {
      return <MemoizedSmartObjectG key={uniqueKey} element={element} objectId={objectId} parentId={svgId} />;
    }
    case "image": {
      return <SmartObjectImage key={uniqueKey} element={element} objectId={objectId} parentId={svgId} />;
    }
    case "defs": {
      return <MemoizedSmartObjectDefs key={uniqueKey} element={element} objectId={objectId} />;
    }
    case "rect": {
      return <SmartObjectRect key={uniqueKey} element={element} extraStyles={extraStyles} />;
    }
    case "circle": {
      return <SmartObjectCircle key={uniqueKey} element={element} />;
    }
    case "polygon": {
      return <SmartObjectPolygon key={uniqueKey} element={element} />;
    }
    case "text": {
      return <MemoizedSmartObjectText key={uniqueKey} element={element} objectId={objectId} />;
    }
    case "tspan": {
      return <SmartObjectTSpan key={uniqueKey} element={element} />;
    }
    case "polyline": {
      return <SmartObjectPolyline key={uniqueKey} element={element} />;
    }
    case "line": {
      return <SmartObjectLine key={uniqueKey} element={element} objectId={objectId} />;
    }
    case "style": {
      if (element.styles) {
        return <style>{element.styles}</style>;
      }
      return null;
    }
    case "use": {
      return <SmartObjectUse key={uniqueKey} element={element} objectId={objectId} parentId={svgId} />;
    }
    default: {
      console.warn("SmartObjectSvgRenderer: type not handled", type);
      return null;
    }
  }
}
export const MemoizedSmartObjectSvgRenderer = React.memo(SmartObjectSvgRenderer);

export function SmartObjectSvgContainer({ element, objectId }: SmartObjectComponent) {
  const { children, ...rest } = element;
  const svgProps = rest as SVGProps<SVGSVGElement>;
  const keys = useMemo(() => getSmartObjectViewBoxMetaVariableKeys(objectId!), [objectId]);
  const x = useMetaVariableStore((state) => state.metaVariables[keys.viewBoxXKey]);
  const y = useMetaVariableStore((state) => state.metaVariables[keys.viewBoxYKey]);
  const width = useMetaVariableStore((state) => state.metaVariables[keys.viewBoxWidthKey]);
  const height = useMetaVariableStore((state) => state.metaVariables[keys.viewBoxHeightKey]);
  const viewBox = useMemo(() => {
    if (x === undefined || y === undefined || width === undefined || height === undefined) return undefined;
    return `${x} ${y} ${width} ${height}`;
  }, [x, y, width, height]);

  return (
    <svg
      key={objectId}
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      {...svgProps}
      viewBox={viewBox ?? svgProps.viewBox}
    >
      {children?.map((item, index) => (
        <MemoizedSmartObjectSvgRenderer
          element={item}
          key={`${objectId}-svg-child-${item.id}-${index}`}
          objectId={objectId}
        />
      ))}
    </svg>
  );
}
export const MemoizedSmartObjectSvgContainer = React.memo(SmartObjectSvgContainer);

export function SmartObjectSvgPath({ element, extraStyles }: SmartObjectComponent) {
  const { children, id, type, ...rest } = element;
  const style = useSmartObjectStyles(element.style, extraStyles);

  const props = rest as SVGProps<SVGPathElement>;

  return <path {...props} style={style} />;
}

export function SmartObjectG({ element, objectId }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, HOTSPOT_ID, RATE, ...rest } = element;

  const props = rest as SVGProps<SVGGElement>;

  return (
    <g {...props}>
      {children?.map((item, index) => (
        <MemoizedSmartObjectSvgRenderer key={`${objectId}-group-child-${index}`} element={item} objectId={objectId} />
      ))}
    </g>
  );
}
export const MemoizedSmartObjectG = React.memo(SmartObjectG);

export function SmartObjectImage({ element, objectId }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, ...rest } = element;
  const shouldRender = useShouldRender(objectId, GROUP_ID, TYPE as SVG_SMART_OBJECT_TYPES, VALUE);
  const style = useSmartObjectStyles(element.style);

  if (!shouldRender) return null;

  const imageProps = rest as SVGProps<SVGImageElement> & { "xlink:href": string };

  return <image {...imageProps} xlinkHref={imageProps["xlink:href"]} style={style}></image>;
}

export function SmartObjectUse({ element, objectId }: SmartObjectComponent) {
  const { children, TYPE, GROUP_ID, VALUE, ...rest } = element;
  const shouldRender = useShouldRender(objectId, GROUP_ID, TYPE as SVG_SMART_OBJECT_TYPES, VALUE);

  if (!shouldRender) return null;

  const useProps = rest as SVGProps<SVGUseElement> & { "xlink:href": string };

  return <use {...useProps} xlinkHref={useProps["xlink:href"]}></use>;
}

export function SmartObjectDefs({ element, objectId }: SmartObjectComponent) {
  const { children, ...rest } = element;

  const defsProps = rest as SVGProps<SVGDefsElement>;

  return (
    <defs {...defsProps}>
      {children?.map((item, index) => (
        <MemoizedSmartObjectSvgRenderer key={`${objectId}-defs-child-${index}`} element={item} objectId={objectId} />
      ))}
    </defs>
  );
}
export const MemoizedSmartObjectDefs = React.memo(SmartObjectDefs);

export function SmartObjectRect({ element, extraStyles }: SmartObjectComponent) {
  const style = useSmartObjectStyles(element.style, extraStyles);
  const rectProps = element as SVGProps<SVGRectElement>;
  return <rect {...rectProps} style={style} />;
}

export function SmartObjectCircle({ element }: SmartObjectComponent) {
  const circleProps = element as SVGProps<SVGCircleElement>;
  const style = useSmartObjectStyles(element.style);
  return <circle {...circleProps} style={style} />;
}

export function SmartObjectLine({ element }: SmartObjectComponent) {
  const lineProps = element as SVGProps<SVGLineElement>;
  const style = useSmartObjectStyles(element.style);
  return <line {...lineProps} style={style} />;
}

export function SmartObjectPolyline({ element, extraStyles }: SmartObjectComponent) {
  const polylineProps = element as SVGProps<SVGPolylineElement>;
  const style = useSmartObjectStyles(element.style, extraStyles);
  return <polyline {...polylineProps} style={style} />;
}

export function SmartObjectPolygon({ element }: SmartObjectComponent) {
  const polygonProps = element as SVGProps<SVGPolygonElement>;
  const style = useSmartObjectStyles(element.style);
  return <polygon {...polygonProps} style={style} />;
}

export function SmartObjectText({ element, objectId }: SmartObjectComponent) {
  const { children, textContent } = element;
  const textProps = element as SVGProps<SVGTextElement>;
  const style = useSmartObjectStyles(element.style);
  const text = cleanSmartObjectText(textContent);
  return (
    <text {...textProps} style={style}>
      {text}
      {!text &&
        children?.map((item, index) => (
          <MemoizedSmartObjectSvgRenderer key={`${objectId}-text-${index}`} element={item} objectId={objectId} />
        ))}
    </text>
  );
}
export const MemoizedSmartObjectText = React.memo(SmartObjectText);

export function SmartObjectTSpan({ element }: SmartObjectComponent) {
  const { textContent, children, ...rest } = element;
  const tspanProps = rest as SVGProps<SVGTSpanElement>;
  const style = useSmartObjectStyles(element.style);
  return (
    <tspan {...tspanProps} style={style}>
      {textContent || ""}
    </tspan>
  );
}
