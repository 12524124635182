/**
 * The useObjectPosition hook returns the position of an object in the viewport.
 *
 * specification:
 *
 * -----------OLD-----------CURRENT (final output)------------------
 * width:      %   ------>    %
 * height:     %   ------>    %
 * left:       %   ------>    px - transformX
 * top:        %   ------>    px - transformY
 *
 *
 * notes:
 *
 *
 *
 */

import { useMemo } from "react";
import { useMovableElementsPlaneState } from "../contexts/MovableElementsPlaneProvider";
import { useInterpolatedFrame } from "./useInterpolatedFrame";
import { xAxisViewPercentageToPixel, yAxisViewPercentageToPixel } from "../utils/Conversion";
import { isNumber } from "../utils";
import { BlurCutoutObject } from "../types";
import { mergeNestedFrameValueToArray } from "../utils/Frames";

export function useObjectPosition(
  objectId: string,
  percentageTop: number,
  percentageLeft: number,
  percentWidth?: number,
  percentHeight?: number,
  rotationDeg?: number,
  opacity?: number,
  blurIntensity?: number,
  blurCutoutShapes?: BlurCutoutObject[],
) {
  const { viewportDOMElementWidth, viewportDOMElementHeight } = useMovableElementsPlaneState();
  const interpolatedFrame = useInterpolatedFrame(objectId);
  const transformX = interpolatedFrame?.transformX;
  const transformY = interpolatedFrame?.transformY;
  const interWidth = interpolatedFrame?.width;
  const interHeight = interpolatedFrame?.height;
  const rotation = interpolatedFrame?.rotation;
  const interpolatedOpacity = interpolatedFrame?.opacity;
  const interpolatedBlurIntensity = interpolatedFrame?.blurIntensity;

  const position = useMemo(() => {
    const masterX = isNumber(transformX)
      ? transformX
      : isNumber(percentageLeft)
      ? xAxisViewPercentageToPixel(percentageLeft)
      : 0;

    const masterY = isNumber(transformY)
      ? transformY
      : isNumber(percentageTop)
      ? yAxisViewPercentageToPixel(percentageTop)
      : 0;

    return [masterX, masterY];
  }, [transformX, percentageLeft, viewportDOMElementWidth, transformY, percentageTop, viewportDOMElementHeight]);
  const size = useMemo(() => {
    const masterWidth = isNumber(interWidth)
      ? xAxisViewPercentageToPixel(interWidth) // percent inside frames
      : isNumber(percentWidth)
      ? xAxisViewPercentageToPixel(percentWidth)
      : 0;

    const masterHeight = isNumber(interHeight)
      ? yAxisViewPercentageToPixel(interHeight) // percent inside frames
      : isNumber(percentHeight)
      ? yAxisViewPercentageToPixel(percentHeight)
      : 0;
    return [masterWidth, masterHeight];
  }, [interWidth, percentWidth, interHeight, percentHeight, viewportDOMElementWidth, viewportDOMElementHeight]);

  const rotationValue = useMemo(() => {
    const masterRotation = isNumber(rotation) ? rotation : isNumber(rotationDeg) ? rotationDeg : 0;
    return masterRotation;
  }, [rotation, rotationDeg]);

  const opacityValue = useMemo(() => {
    if (typeof interpolatedOpacity !== "number") {
      return opacity;
    } else {
      return interpolatedOpacity;
    }
  }, [opacity, interpolatedOpacity]);

  const blurValue = useMemo(() => {
    if (typeof interpolatedBlurIntensity !== "number") {
      return blurIntensity;
    } else {
      return interpolatedBlurIntensity;
    }
  }, [blurIntensity, interpolatedBlurIntensity]);

  const blurCutoutShapesValue =
    blurCutoutShapes && interpolatedFrame.blurCutoutShapes
      ? mergeNestedFrameValueToArray<BlurCutoutObject>(interpolatedFrame.blurCutoutShapes, blurCutoutShapes)
      : blurCutoutShapes;

  return {
    position,
    size,
    rotation: rotationValue,
    opacity: opacityValue,
    blurIntensity: blurValue,
    blurCutoutShapes: blurCutoutShapesValue,
  };
}
