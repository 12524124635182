import React from "react";
import AspectRatioContainer from "../../../services/Utils/AspectRatioContainer";
import * as AssetManagerTypes from "../AssetManager.types";
import IAsset from "../../../models/IAsset";
import { addLineBreaks, massageFileDescription } from "../AssetManagerUtils/utilities";
import { AbstractTooltip as Tooltip } from "../../ToolTip/ToolTip";
import { getAssetThumbnail } from "./AssetLibrary";
import { ASSET_TYPE_ID } from "../../../const";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/AssetManager/drop-down-icon.svg";
import blobUrlFormatHelper from "../../blobUrlFormatHelper";
// import lodash from "lodash"

function AssetLibraryListView(props: AssetManagerTypes.AssetLibraryListViewProps) {
  if (props.data.length === 0) {
  }
  const totalImages = React.useRef(props.data.length);
  totalImages.current = props.data.length;
  const handleAssetClick = (asset: IAsset) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (props.AssetManagerDispatch as AssetManagerTypes.Dispatch<IAsset>)({
      type: "SELECT_ASSET",
      payload: asset,
    });

  const isSelected = (el: any) => {
    return el.assetVersionId === props.selectedAsset.assetId;
  };

  const handlePlayAudio = (el: any) => {
    if (!el.blobPath) return;
    const url = blobUrlFormatHelper(el.blobPath);
    const audio = new Audio(url);
    audio.play();
  };

  const showAsArchived = props.hasCurrentLessonFilter;

  return (
    <div className="assetLibrary-list-view-two-column" style={{ display: props.assetsAreLoading ? "none" : "" }}>
      {props.data.length > 0 && (
        <div className="assetLibrary-list-view-header">
          <div>
            <p>File Name and description</p>
          </div>
        </div>
      )}
      {props.data.length > 0 ? (
        <div className="assetLibrary-list-view-content-two-columns">
          {props.data.map((el: any, index: number) => {
            let name = el.name;
            const thumbnail = getAssetThumbnail(el, props.typeMode);
            const isArchived = showAsArchived && el.isArchived;
            const isAudioEffect = props.typeMode === ASSET_TYPE_ID.AUDIO_EFFECT;

            if (props.typeMode === ASSET_TYPE_ID.SMART_OBJECT && el.smartObject) {
              name = el.smartObject?.smartObjectSvg ?? el.name;
            }

            return (
              <div
                key={"astmngrlistvw" + index}
                className={`assetLibrary-single-asset-container two-column ${isArchived ? "archived" : ""}`}
                onClick={handleAssetClick(el)}
                style={
                  isSelected(el)
                    ? {
                        background: "var(--cpat-green-hover)",
                        color: "white",
                        gridColumn: index < 6 ? 1 : 2,
                        gridRow: (index % 6) + 1,
                      }
                    : {
                        gridColumn: index < 6 ? 1 : 2,
                        gridRow: (index % 6) + 1,
                      }
                }
              >
                <div
                  className={`assetLibrary-list-view-content-name two-column ${isAudioEffect ? "audio-effect" : ""}`}
                >
                  {(props.typeMode === ASSET_TYPE_ID.IMAGE ||
                    props.typeMode === ASSET_TYPE_ID.PANORAMIC_IMAGE ||
                    props.typeMode === ASSET_TYPE_ID.SMART_OBJECT) &&
                    thumbnail && (
                      <div>
                        <AspectRatioContainer
                          height={100}
                          width={100}
                          src={thumbnail}
                          additionalStyle={{ position: "absolute" }}
                          onLoadError={(e: any) => {}}
                        />
                      </div>
                    )}
                  <div
                    className={`assetLibrary-list-view-two-column-name-description ${
                      isSelected(el) ? "selected" : ""
                    } ${isArchived ? "archived" : ""}`}
                  >
                    <div className={`assetLibrary-list-view-two-column-info-container ${isArchived ? "archived" : ""}`}>
                      <div className="assetLibrary-list-view-two-column-info-name-description">
                        <p className="assetLibrary-list-view-two-column-name">{name}</p>
                        <p
                          className="assetLibrary-list-view-two-column-description"
                          data-tooltip-id="description-tooltip"
                        >
                          {el.description}
                        </p>
                        <Tooltip id={"description-tooltip"} />
                      </div>
                      {isArchived && (
                        <div>
                          <p className="is-archived-tag">Archived</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {isAudioEffect && isSelected(el) && el.blobPath && (
                    <button onClick={() => handlePlayAudio(el)} className="audio-effect-play-button">
                      <ArrowIcon />
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="assetLibrary-listView-no-results">Sorry, We found No results matching your filters</div>
      )}
    </div>
  );
}
export default AssetLibraryListView;
