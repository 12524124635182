import { useState, useContext, useEffect, useCallback, MouseEvent } from "react";
import { Section, FramedInput } from "../../../panels/ObjectPropertiesPanel";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../contexts/ObjectsProvider";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { ElementTypes, IBasicPageAttributes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import { BaseObject, BlurCutoutObject, CutoutShapes } from "../../../types";

import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
import ColorPicker from "./ColorPicker";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Settings/add-new-tag.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/Common/remove-page-icon.svg";
import { useMiscUI } from "../../../contexts/MiscUI/MiscUIProvider";
import { nanoid } from "../../../lib/nanoId";
import { OBJECT_TYPE_SHAPE } from "../../../const";
import { useAnimatedObject } from "../../../hooks/useAnimatedObject";
import { flattenObject } from "../../../utils/Object/flattenObject";
import { frameValueToObject } from "../../../utils/Frames";

type BlurCutoutType = {
  selectedObject: BaseObject;
  isModalShown: boolean;
};

const BlurCutoutInput = ({ isModalShown }: BlurCutoutType) => {
  const objectsDispatch = useObjectsDispatch();
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const { selectedObjects } = useObjectsState();
  const selectedObjectState = useSelectedObjectState();
  const selectedObject = selectedObjects[0];
  const animatedObject = useAnimatedObject(selectedObject?.objectId);
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const [, setMiscUI] = useMiscUI();
  const [tl] = useTimeline();

  const [nodeToUpdate, setNodeToUpdate] = useState<any>();
  const [elementType] = useState<ElementTypes>("annotations");
  const [editableIndex] = useState<number>(-1);

  const pageManifest = pageContext.pageManifest;
  const currentTime = tl?.scrubbingCurrentTime;
  const frameAtCurrentTime = animatedObject?.frames?.find((frame) => frame.timestamp === currentTime);

  const blurIntensity = selectedObjectState?.blurIntensity?.toFixed?.(0) ?? 0;
  const isBlurInTimeline = typeof frameAtCurrentTime?.blurIntensity === "number";
  const isShape = selectedObject?.type === OBJECT_TYPE_SHAPE;
  const isPropertyInTimeline = useCallback(
    (property: keyof BlurCutoutObject, cutoutId: string) => {
      if (frameAtCurrentTime && frameAtCurrentTime.blurCutoutShapes) {
        const cutouts = frameValueToObject(frameAtCurrentTime.blurCutoutShapes);

        if (!cutouts[cutoutId]) {
          return false;
        }

        const propertyValue = cutouts[cutoutId][property] ?? undefined;

        return propertyValue !== undefined && propertyValue !== null;
      } else {
        return false;
      }
    },
    [frameAtCurrentTime],
  );

  const canEditHeight = !(
    selectedObject?.type?.toUpperCase().includes("LINE") || selectedObject?.type?.toUpperCase().includes("ARROW")
  );
  const blurCutoutList = selectedObjectState?.blurCutoutShapes || [];

  const blurCutoutBaseObject: Omit<BlurCutoutObject, "id"> = {
    type: "rectangular",
    x: 50,
    y: 50,
    w: 100,
    h: 100,
    isVisible: true,
    isOpen: false,
    rotate: 0,
  };

  const cutoutShapes = ["rectangular", "ellipse", "triangle"];

  function handleFormatChange(funct: any, value: any, keyValue: any) {
    const newAttributes = funct(nodeToUpdate, value, keyValue);
    updateAttributes(newAttributes);
  }

  function updateAttributes(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    switch (elementType) {
      // case 'annotation':
      case "annotations":
        if (isModalShown === true) {
          pageManifest.refAnnotations[editableIndex] = attributes;
        } else {
          pageManifest.annotations[editableIndex] = attributes;
        }
        break;
      // case 'symbol':
      case "symbols":
        isModalShown === true
          ? (pageManifest.refSymbols[editableIndex] = attributes)
          : (pageManifest.symbols[editableIndex] = attributes);
        break;
      default:
        break;
    }
    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(pageManifest);
  }

  const handleDeleteCutout = (clickEvent: MouseEvent<SVGElement>, cutoutId: string) => {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();

    objectsDispatch({
      type: ObjectActionsType.DELETE_BLUR_CUTOUT,
      payload: {
        objectId: selectedObject.objectId,
        cutoutId,
      },
    });

    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_BLUR_CUTOUT,
      payload: blurCutoutList.filter((cutout) => cutout.id !== cutoutId),
    });
  };

  const onBlurIntensityChange = (intensity: string) => {
    const blurIntensity = parseFloat(intensity);

    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_BLUR_INTENSITY,
      payload: blurIntensity,
    });

    objectsDispatch({
      type: ObjectActionsType.SET_BLUR_INTENSITY,
      payload: {
        objectId: selectedObject.objectId,
        blurIntensity,
      },
    });

    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId: selectedObject.objectId,
        frame: {
          timestamp: currentTime,
          blurIntensity: blurIntensity,
        },
      },
    });
  };

  const handleRemoveCutoutFrameProperty = (cutoutId: string, prop: keyof BlurCutoutObject) => {
    objectsDispatch({
      type: ObjectActionsType.DELETE_NESTED_PROPERTY_FROM_OBJECT_FRAME,
      payload: {
        objectId: selectedObject.objectId,
        timestamp: currentTime,
        property: "blurCutoutShapes",
        nestedProperty: `${cutoutId}.${prop}`,
      },
    });
  };

  const updateCutoutProperty = (cutoutId: string, props: Partial<BlurCutoutObject>) => {
    const updatedBlurCutoutArray = selectedObjectState.blurCutoutShapes?.slice(0);
    const cutoutIndex = updatedBlurCutoutArray?.findIndex((cutout) => cutout.id === cutoutId);

    updatedBlurCutoutArray[cutoutIndex] = {
      ...updatedBlurCutoutArray[cutoutIndex],
      ...props,
    };

    objectsDispatch({
      type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
      payload: {
        objectId: selectedObject.objectId,
        cutoutId,
        props,
      },
    });

    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_BLUR_CUTOUT,
      payload: updatedBlurCutoutArray,
    });
  };

  const handleCutoutPropertyChange = (cutoutId: string, props: Partial<BlurCutoutObject>, frameOnly = false) => {
    if (!frameOnly) {
      updateCutoutProperty(cutoutId, props);
    }

    objectsDispatch({
      type: ObjectActionsType.UPSERT_OBJECT_FRAME,
      payload: {
        objectId: selectedObject.objectId,
        frame: {
          timestamp: currentTime,
          blurCutoutShapes: flattenObject({
            ...(frameAtCurrentTime?.blurCutoutShapes ?? {}),
            ...flattenObject({ [cutoutId]: props }),
          }),
        },
      },
    });
  };

  useEffect(() => {
    return blurCutoutList.forEach((item) => {
      updateCutoutProperty(item.id, { isOpen: false });
    });
  }, []);

  return (
    <>
      {!isShape && (
        <Section title="Color" wrap boldTitle={false}>
          <ColorPicker
            selectedObject={selectedObject}
            keyValue="backgroundColor"
            handleFormatChange={handleFormatChange}
            color={selectedObject?.backgroundColor || ""}
          />
        </Section>
      )}
      <Section title={null} wrap={false}>
        <FramedInput
          label="Blur"
          min={0}
          max={100}
          isHovered={isBlurInTimeline}
          value={blurIntensity.toString()}
          inputType="number"
          onFrameRemove={() => {
            objectsDispatch({
              type: ObjectActionsType.DELETE_PROPERTY_FROM_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                timestamp: currentTime,
                property: "blurIntensity",
              },
            });
          }}
          onInputChange={(e) => onBlurIntensityChange(e.target.value)}
          onFrameAdd={() => {
            objectsDispatch({
              type: ObjectActionsType.UPSERT_OBJECT_FRAME,
              payload: {
                objectId: selectedObject.objectId,
                frame: {
                  timestamp: currentTime,
                  blurIntensity: Number(blurIntensity) ?? 0,
                },
              },
            });
          }}
        />
      </Section>
      <Section title={!isShape ? "Blur Cutout" : null} wrap boldTitle={false}>
        {blurCutoutList.length > 0 &&
          blurCutoutList.map((blurCutoutItem, index) => {
            const currentCutout = selectedObject?.blurCutoutShapes?.[index];

            if (!currentCutout) {
              return null;
            }

            return (
              <section
                onClick={() => {
                  setMiscUI({
                    type: "SET_SELECTED_MASK_ID",
                    payload: blurCutoutItem.id,
                  });

                  blurCutoutList.forEach((item) => {
                    if (item.id !== blurCutoutItem.id) {
                      updateCutoutProperty(item.id, { isOpen: false });
                    }
                  });
                  updateCutoutProperty(blurCutoutItem.id, { isOpen: true });
                }}
                key={blurCutoutItem.id}
                className="blur-image-cutout-panel"
              >
                <div className="blur-cutout-internal-area">
                  <div className={`blur-cutout-title-${currentCutout.isOpen ? "border" : "no-border"}`}>
                    <div className="blur-cutout-title-left">
                      <TrashIcon
                        role="button"
                        onClick={(clickEvent) => handleDeleteCutout(clickEvent, blurCutoutItem.id)}
                      />
                      <span className="blur-cutout-title">
                        {isShape ? "Mask Cutout" : "Blur Cutout"} {index + 1}
                      </span>
                      <div
                        className={`expand-triangle-icon-${currentCutout.isOpen ? "down" : "up"}`}
                        role="button"
                        onClick={(clickEvent: MouseEvent) => {
                          clickEvent.preventDefault();
                          clickEvent.stopPropagation();

                          updateCutoutProperty(blurCutoutItem.id, { isOpen: !blurCutoutItem.isOpen });
                        }}
                      ></div>
                    </div>
                  </div>
                  {currentCutout.isOpen && (
                    <div className="blur-cutout-properties">
                      <Section title="Cutout shape" wrap>
                        <select
                          id="cutoutShape"
                          onChange={(e) => {
                            updateCutoutProperty(blurCutoutItem.id, { type: e.target.value as CutoutShapes });
                          }}
                          value={blurCutoutItem.type}
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {cutoutShapes.map((item, index) => (
                            <option value={item} key={`blurcutout-select-options-${index}`}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </Section>
                      <Section title="Position" wrap>
                        <div className="sub-section-nowrap">
                          <FramedInput
                            label="X"
                            min={0}
                            value={blurCutoutItem.x}
                            inputType="number"
                            svgShowing={true}
                            isHovered={isPropertyInTimeline("x", blurCutoutItem.id)}
                            onInputChange={(e) => {
                              handleCutoutPropertyChange(blurCutoutItem.id, { x: Number(e.target.value) });
                            }}
                            onFrameAdd={() => {
                              handleCutoutPropertyChange(blurCutoutItem.id, { x: blurCutoutItem.x }, true);
                            }}
                            onFrameRemove={() => {
                              handleRemoveCutoutFrameProperty(blurCutoutItem.id, "x");
                            }}
                          />
                          <FramedInput
                            label="Y"
                            min={0}
                            value={blurCutoutItem.y}
                            inputType="number"
                            svgShowing={true}
                            isHovered={isPropertyInTimeline("y", blurCutoutItem.id)}
                            onInputChange={(e) => {
                              handleCutoutPropertyChange(blurCutoutItem.id, { y: Number(e.target.value) });
                            }}
                            onFrameAdd={() => {
                              handleCutoutPropertyChange(blurCutoutItem.id, { y: blurCutoutItem.y }, true);
                            }}
                            onFrameRemove={() => {
                              handleRemoveCutoutFrameProperty(blurCutoutItem.id, "y");
                            }}
                          />
                        </div>
                      </Section>
                      <Section title="Dimensions" wrap={false}>
                        <FramedInput
                          label="W"
                          min={0}
                          value={blurCutoutItem.w}
                          inputType="number"
                          svgShowing={true}
                          isHovered={isPropertyInTimeline("w", blurCutoutItem.id)}
                          onInputChange={(e) => {
                            handleCutoutPropertyChange(blurCutoutItem.id, { w: Number(e.target.value) });
                          }}
                          onFrameAdd={() => {
                            handleCutoutPropertyChange(blurCutoutItem.id, { w: blurCutoutItem.w }, true);
                          }}
                          onFrameRemove={() => {
                            handleRemoveCutoutFrameProperty(blurCutoutItem.id, "w");
                          }}
                        />
                        <FramedInput
                          label="H"
                          min={0}
                          value={blurCutoutItem.h}
                          inputType="number"
                          svgShowing={true}
                          isHovered={isPropertyInTimeline("h", blurCutoutItem.id)}
                          canEditHeight={canEditHeight}
                          onInputChange={(e) => {
                            handleCutoutPropertyChange(blurCutoutItem.id, { h: Number(e.target.value) });
                          }}
                          onFrameAdd={() => {
                            handleCutoutPropertyChange(blurCutoutItem.id, { h: blurCutoutItem.h }, true);
                          }}
                          onFrameRemove={() => {
                            handleRemoveCutoutFrameProperty(blurCutoutItem.id, "h");
                          }}
                        />
                      </Section>
                      <Section title="Rotation" wrap={false}>
                        <FramedInput
                          label="Degrees"
                          value={blurCutoutItem.rotate}
                          inputType="number"
                          svgShowing={true}
                          isHovered={isPropertyInTimeline("rotate", blurCutoutItem.id)}
                          onInputChange={(e) => {
                            handleCutoutPropertyChange(blurCutoutItem.id, { rotate: Number(e.target.value) });
                          }}
                          onFrameAdd={() => {
                            handleCutoutPropertyChange(blurCutoutItem.id, { rotate: blurCutoutItem.rotate }, true);
                          }}
                          onFrameRemove={() => {
                            handleRemoveCutoutFrameProperty(blurCutoutItem.id, "rotate");
                          }}
                        />
                      </Section>
                      <Section separator wrap>
                        <FramedInput
                          label="Visibility"
                          value={blurCutoutItem.isVisible}
                          inputType="checkbox"
                          svgShowing={true}
                          isHovered={isPropertyInTimeline("isVisible", blurCutoutItem.id)}
                          onInputChange={() => {
                            handleCutoutPropertyChange(blurCutoutItem.id, {
                              isVisible: !blurCutoutItem.isVisible,
                            });
                          }}
                          onFrameAdd={() => {
                            handleCutoutPropertyChange(
                              blurCutoutItem.id,
                              {
                                isVisible: blurCutoutItem.isVisible,
                              },
                              true,
                            );
                          }}
                          onFrameRemove={() => {
                            handleRemoveCutoutFrameProperty(blurCutoutItem.id, "isVisible");
                          }}
                        />
                      </Section>
                    </div>
                  )}
                </div>
              </section>
            );
          })}
        <div
          className="tags-add blur-cutout-plus-icon"
          role="button"
          onClick={() => {
            const newCutoutObject = {
              ...blurCutoutBaseObject,
              x: 50,
              y: 50,
              w: 100,
              h: 100,
              isVisible: true,
              isOpen: true,
              rotate: 0,
              id: nanoid(),
            };

            objectsDispatch({
              type: ObjectActionsType.ADD_BLUR_CUTOUT,
              payload: {
                objectId: selectedObject.objectId,
                cutout: newCutoutObject,
              },
            });

            selectedObjectDispatch({
              type: SelectedObjectActionTypes.SET_BLUR_CUTOUT,
              payload: [...blurCutoutList, newCutoutObject],
            });
          }}
        >
          <PlusIcon />
        </div>
      </Section>
    </>
  );
};

export default BlurCutoutInput;
