import { create } from "zustand";
import { TimelineAudioState } from "../../../../contexts/PageAudioManager/types";

export enum ConflictType {
  LEFT = "left",
  RIGHT = "right",
}

export enum InsertMode {
  BEFORE = "left",
  AFTER = "right",
}

export interface AudioEffectObject {
  objectId: string;
  start: number;
  end: number;
  duration: number;
  input: string; // blob url
  // title: string;
  conflictLeft: boolean;
  conflictRight: boolean;
}

export interface AudioEffectStoreState {
  isDragging: boolean;
  currentPosition: number | null; // in seconds
  draggingObjectId: string | null; // objectId
  objects: Map<string, AudioEffectObject>;
  conflictObject: string | null;
  conflictType: ConflictType;
  insertMode: InsertMode;
}

export interface AudioEffectStoreActions {
  setDragging: (objectId: string | null) => void;
  setObjects: (objects: TimelineAudioState[]) => void;
  setCurrentPosition: (position: number) => void;
  setConflict: (objectId: string | null, type?: ConflictType) => void;
  setInsertMode: (mode: InsertMode) => void;
}

export const useAudioEffectStore = create<AudioEffectStoreState & AudioEffectStoreActions>()((setState) => ({
  // INSERT MODE
  insertMode: InsertMode.AFTER,
  setInsertMode: (mode) => {
    setState({ insertMode: mode });
  },
  // DRAGGING
  isDragging: false,
  draggingObjectId: null,
  setDragging: (objectId) => {
    if (objectId) {
      setState({
        isDragging: true,
        draggingObjectId: objectId,
      });
    } else {
      setState({
        isDragging: false,
        draggingObjectId: null,
      });
    }
  },
  // OBJECT HANDLING
  objects: new Map(),
  setObjects: (objects) => {
    // Initial pass to create the primary entries
    const baseObjectsMap: Map<string, AudioEffectObject> = new Map(
      objects.map<[string, AudioEffectObject]>((audio) => [
        audio.objectId,
        {
          objectId: audio.objectId,
          start: audio.start,
          end: audio.end,
          duration: audio.duration ?? audio.start - audio.end,
          conflictLeft: false,
          conflictRight: false,
          input: audio.input,
        },
      ]),
    );

    setState({
      objects: baseObjectsMap,
    });
  },
  // CONFLICT
  currentPosition: null,
  conflictObject: null,
  conflictType: ConflictType.LEFT,
  setCurrentPosition: (position: number) => {
    setState({
      currentPosition: position,
    });
  },
  setConflict: (conflictObject, conflictType = ConflictType.LEFT) => {
    setState({
      conflictObject,
      conflictType,
    });
  },
}));

(window as any).audioEffectStore = useAudioEffectStore;
