import { useState, useEffect, Dispatch, SetStateAction, useContext } from "react";
import _ from "lodash";
import { DropSeparator, Section } from "../../../panels/ObjectPropertiesPanel";
import LineWidthButton from "../../WYSIWYGToolbar/components/LineWidthButton";
import { BaseObject } from "../../../types";
import { ElementTypes, IBasicPageAttributes } from "../../../pageTypes/BasicPage_Player/components/IBasePage";
import { IPageContext, PageContext } from "../../../routes/builderContexts";
import { IPropertyBox } from "../../ObjectPropertyBox/models/IObjectPropertyBox";
import { IAnnotation } from "../../Annotation/models/IAnnotation";
import ISymbolStyle from "../../Symbol/models/ISymbolStyle";
import * as pbFunctions from "../../ObjectPropertyBox/functions/PropertyBoxFunctions";
import { emptyPropertyBox } from "../../ObjectPropertyBox/models/emptyPropertyBox";
import ColorPicker from "./ColorPicker";
import { extractHexColor } from "../../../utils/Conversion";

type BlurInputType = {
  selectedObject: BaseObject;
  isModalShown: boolean;
  hideOutlineColor: boolean;
  hideFillColor: boolean;
  hideBorderWidth?: boolean;
  hideFontColor?: boolean;
  currentTime: number;
};

const ColorInput = ({
  selectedObject,
  isModalShown,
  hideOutlineColor = false,
  hideFillColor = false,
  hideBorderWidth,
  hideFontColor,
}: BlurInputType) => {
  const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  const pageManifest: any = pageContext.pageManifest;
  const [nodeToUpdate, setNodeToUpdate]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const [elementType]: [ElementTypes, Dispatch<SetStateAction<ElementTypes>>] = useState<ElementTypes>("annotations");
  const [pageDims]: [DOMRect | null, Dispatch<SetStateAction<DOMRect | null>>] = useState<DOMRect | null>(null);
  const [, setPropertyBox]: [IPropertyBox, Dispatch<SetStateAction<IPropertyBox>>] =
    useState<IPropertyBox>(emptyPropertyBox);
  const [, setIsPropertyBoxShown]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false);
  const [, setDirections]: [string[], Dispatch<SetStateAction<string[]>>] = useState([
    "n",
    "nw",
    "ne",
    "s",
    "se",
    "sw",
    "e",
    "w",
  ]);
  const [, setTarget]: [any, Dispatch<SetStateAction<any>>] = useState<any>();
  const [editableIndex]: [number, Dispatch<SetStateAction<number>>] = useState<number>(-1);

  let tempNode: any = _.cloneDeep(nodeToUpdate);

  function validateNodeName(index: number, node: any, type: string) {
    if (!_.has(node, "name") || node.name?.length === 0) {
      switch (type) {
        case "annotation":
          return `${node.type} ${index}`;
        case "symbols":
          return `${pageManifest.symbols[index].name} ${index}`;
        default:
          return;
      }
    } else {
      return node.name;
    }
  }

  const getDirections = (target: HTMLElement | SVGSVGElement) => {
    const name: string | null = target ? target.getAttribute("name") : null;

    if (
      name === "arrow" ||
      name === "lineArrow" ||
      name === "solidLine" ||
      name === "dashedLine" ||
      name === "pointArrow" ||
      _.startsWith(target.id, "label")
    ) {
      setDirections(["e", "w"]);
    } else {
      setDirections(["n", "nw", "ne", "s", "se", "sw", "e", "w"]);
    }
  };

  useEffect(() => {
    if (elementType === "annotations" && pageContext.pageManifest?.annotations?.length > 0) {
      const lastAdded: number = pageContext.pageManifest?.annotations?.length - 1;
      const pageManifest: any = pageContext.pageManifest;
      const addedID = `${pageManifest.annotations[lastAdded]?.type}-${lastAdded}`;
      const addedTarget = document.querySelector(`#${addedID}`) as HTMLElement | SVGSVGElement;
      if (addedTarget) {
        tempNode = pageManifest.annotations[lastAdded];
        tempNode.name = validateNodeName(lastAdded, tempNode, "annotation");
        pbFunctions.createPropertyBox(tempNode, pageDims as DOMRect, setPropertyBox);
        setIsPropertyBoxShown(true);
        setNodeToUpdate(tempNode);
        getDirections(addedTarget);
        setTarget(addedTarget);
        addedTarget.focus();
      }
    }
  }, [pageContext.pageManifest?.annotations?.length]);

  function handleFormatChange(funct: any, value: any, keyValue: any) {
    const newAttributes = funct(nodeToUpdate, value, keyValue);
    updateAttributes(newAttributes);
  }

  function updateAttributes(attributes: IAnnotation | IBasicPageAttributes | ISymbolStyle) {
    switch (elementType) {
      // case 'annotation':
      case "annotations":
        if (isModalShown === true) {
          pageManifest.refAnnotations[editableIndex] = attributes;
        } else {
          pageManifest.annotations[editableIndex] = attributes;
        }
        break;
      // case 'symbol':
      case "symbols":
        isModalShown === true
          ? (pageManifest.refSymbols[editableIndex] = attributes)
          : (pageManifest.symbols[editableIndex] = attributes);
        break;
      default:
        break;
    }
    setNodeToUpdate(attributes);
    pageContext.updatePageManifest(pageManifest);
  }

  return (
    <>
      {hideOutlineColor && (
        <Section title="Outline" wrap boldTitle={true}>
          <ColorPicker
            selectedObject={selectedObject}
            keyValue="borderColor"
            handleFormatChange={handleFormatChange}
            color={
              selectedObject.type === "table"
                ? extractHexColor(selectedObject?.border || "") || "#ffffff"
                : selectedObject?.borderColor || "#ffffff"
            }
          />
          {!hideBorderWidth && (
            <LineWidthButton attributes={nodeToUpdate as IAnnotation} handleFormatChange={handleFormatChange} />
          )}
        </Section>
      )}
      {hideFillColor && (
        <Section title="Fill" wrap boldTitle={true}>
          <ColorPicker
            selectedObject={selectedObject}
            keyValue="backgroundColor"
            handleFormatChange={handleFormatChange}
            color={selectedObject?.backgroundColor || ""}
          />
        </Section>
      )}
      {hideFontColor && (
        <Section title="Font Color" wrap boldTitle={false}>
          <ColorPicker
            selectedObject={selectedObject}
            keyValue="annotationFontColor"
            handleFormatChange={handleFormatChange}
            color={selectedObject?.fontColor || ""}
            hideTransparency={true}
          />
        </Section>
      )}
    </>
  );
};

export default ColorInput;
