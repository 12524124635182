import type { Frame, BlurCutoutObject } from "../../types";

type AvailableFrameProps = Omit<Frame, "id">;
type AvailableNestedFrameProps = Pick<Frame, "blurCutoutShapes">;

const canPropertyBeCalculated = (propertyValue: unknown) => {
  return typeof propertyValue === "number";
};

const testPropertyExistence = (property: number | boolean | string | null | undefined) =>
  property !== null && property !== undefined;

export function interpolate(value1: number, value2: number, value1T: number, value2T: number, t: number) {
  return value1 + ((value2 - value1) * (t - value1T)) / (value2T - value1T);
}

export function interpolateFrame(framesBehind: Frame[], framesAhead: Frame[], currentTime: number) {
  function interpolateProperty<K extends keyof AvailableFrameProps>(
    property: K,
  ): number | undefined | BlurCutoutObject {
    const framesBehindWithProperty = framesBehind.filter((frame) => canPropertyBeCalculated(frame[property]));
    const framesAheadWithProperty = framesAhead.filter((frame) => canPropertyBeCalculated(frame[property]));
    const hasFramesBehind = framesBehindWithProperty.length > 0;
    const hasFramesAhead = framesAheadWithProperty.length > 0;
    const isInBetween = hasFramesBehind && hasFramesAhead;
    const isLastFrame = !hasFramesAhead && hasFramesBehind;
    const isFirstFrame = !hasFramesBehind && hasFramesAhead;

    if (!hasFramesBehind && !hasFramesAhead) {
      return undefined;
    }

    if (isInBetween) {
      return interpolate(
        framesBehindWithProperty[framesBehindWithProperty.length - 1][property]! as number,
        framesAheadWithProperty[0][property]! as number,
        framesBehindWithProperty[framesBehindWithProperty.length - 1].timestamp,
        framesAheadWithProperty[0].timestamp,
        currentTime,
      );
    } else if (isFirstFrame) {
      return framesAheadWithProperty[0][property] as number;
    } else if (isLastFrame) {
      return framesBehindWithProperty[framesBehindWithProperty.length - 1][property] as number;
    }
  }

  function interpolateNestedProperty<K extends keyof AvailableNestedFrameProps>(
    property: K,
  ): undefined | Record<string, unknown> {
    const framesBehindWithProperty = framesBehind.filter((frame) => frame[property]);
    const framesAheadWithProperty = framesAhead.filter((frame) => frame[property]);

    const hasFramesBehind = framesBehindWithProperty.length > 0;
    const hasFramesAhead = framesAheadWithProperty.length > 0;

    const isLastFrame = !hasFramesAhead && hasFramesBehind;
    const isFirstFrame = !hasFramesBehind && hasFramesAhead;

    if (!hasFramesBehind && !hasFramesAhead) {
      return undefined;
    }

    const calculatedPreviousValues = [...framesBehindWithProperty].reduce<Record<string, unknown>>((stack, frame) => {
      return { ...stack, ...frame[property] };
    }, {});
    const calculatedCurrentValue = [...framesAheadWithProperty]
      .reverse()
      .reduce<Record<string, unknown>>((stack, frame) => {
        return { ...stack, ...frame[property] };
      }, {});

    // If it's the first just return it
    if (isFirstFrame) {
      // Issue is that we might have scatered frames for each of the properties...
      // Gotta get the first frame for each subproperty
      // Another way to get that is to merge them in the oposite order, but might be too heavy of an operation
      return calculatedCurrentValue;
    }

    if (isLastFrame) {
      return calculatedPreviousValues;
    }

    // This is the correct state of the object considering
    //  that the frame behind have precedence
    //  now just add on top of this the interpolated values
    const interpolatedNestedObject = {
      ...calculatedCurrentValue,
      ...calculatedPreviousValues,
    };

    // Now ideally we calculate only number properties
    const nestedProperties = Object.keys(calculatedCurrentValue);

    for (const nestedProperty of nestedProperties) {
      // Find the last frame where this nested property was present
      const previousFrameWithNestedProperty = framesBehindWithProperty
        .filter((frame) => testPropertyExistence(frame[property]?.[nestedProperty]))
        .pop();
      // Find the next frame that contains that nested property
      const nextFrameWithNestedProperty = framesAheadWithProperty
        .filter((frame) => testPropertyExistence(frame[property]?.[nestedProperty]))
        .shift();

      if (
        previousFrameWithNestedProperty &&
        nextFrameWithNestedProperty &&
        canPropertyBeCalculated(calculatedCurrentValue[nestedProperty])
      ) {
        interpolatedNestedObject[nestedProperty] = interpolate(
          previousFrameWithNestedProperty[property]?.[nestedProperty] as number,
          nextFrameWithNestedProperty[property]?.[nestedProperty] as number,
          previousFrameWithNestedProperty.timestamp,
          nextFrameWithNestedProperty.timestamp,
          currentTime,
        );
      }
    }

    return interpolatedNestedObject;
  }

  // a frame can be missing x or y
  const interpolatedFrame: Omit<Frame, "id"> = {
    timestamp: currentTime,
    // Flat props
    x: interpolateProperty("x"),
    y: interpolateProperty("y"),
    width: interpolateProperty("width"),
    height: interpolateProperty("height"),
    rotation: interpolateProperty("rotation"),
    opacity: interpolateProperty("opacity"),
    pitch: interpolateProperty("pitch"),
    yaw: interpolateProperty("yaw"),
    zoom: interpolateProperty("zoom"),
    value: interpolateProperty("value"),
    blurIntensity: interpolateProperty("blurIntensity"),
    blurOpacity: interpolateProperty("blurOpacity"),
    // Nested props
    blurCutoutShapes: interpolateNestedProperty("blurCutoutShapes"),
  };
  return interpolatedFrame;
}
