import React from "react";

import { useShallow } from "zustand/react/shallow";
import { useTimeline } from "../../../../contexts/TimelineProvider/TimelineProvider";
import { BAR_HEIGHT } from "../../TimelinePanel";
import { ReactComponent as LoadingSpinner } from "../../../../assets/icons/Common/loading.svg";

import { AudioWave } from "../AudioWave";
import "./index.css";
import classes from "../../../../utils/HTML/classes";
import { useNarrationStore } from "../CompositeNarrationRow/store";
import { useAudioManagerStore } from "../../../../contexts/PageAudioManager";

export const SELECTED_WAVE_COLOR = "#ffffff";
export const DEFAULT_WAVE_COLOR = "#5c864e";

export interface SecondaryAudioWaveBarProps {
  objectId: string;
  selected: boolean;
  onSelect: (unselect?: boolean) => void;
}

export const SecondaryAudioWaveBar = ({ objectId, selected, onSelect }: SecondaryAudioWaveBarProps) => {
  const [, , { clippedSpaceFromUnitSpace }] = useTimeline();
  const [draggingObjectId] = useNarrationStore(useShallow((state) => [state.draggingObjectId]));
  const audio = useAudioManagerStore((state) => state.getNarrationAudio(objectId));

  if (!audio) {
    return null;
  }

  const width = clippedSpaceFromUnitSpace(audio.end) - clippedSpaceFromUnitSpace(audio.start);
  const canRender = width && audio.ready;

  return (
    <div
      className={classes("audio-wave-bar", {
        "audio-wave-bar--selected": selected,
        // "audio-wave-bar--dragging": isParentDragging,
      })}
      style={{
        width,
      }}
      onClick={(event) => {
        event.stopPropagation();

        onSelect(selected);
      }}
    >
      {canRender ? (
        <div
          style={{
            width,
            height: BAR_HEIGHT,
          }}
        >
          <AudioWave
            waveColor={selected ? SELECTED_WAVE_COLOR : DEFAULT_WAVE_COLOR}
            key={audio.objectId}
            objectId={audio.objectId}
            height={BAR_HEIGHT}
            width={width}
          />
        </div>
      ) : (
        <div className="audio-wave-loading" style={{ width }}>
          <LoadingSpinner className="audio-loading-spinner" />
          <span className="audio-wave-loading-message">Loading Audio...</span>
        </div>
      )}
    </div>
  );
};
