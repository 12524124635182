import { TaskOptions } from "../lib/interactivity";

export interface BaseObject {
  backgroundColor?: string;
  previousBackgroundColor?: string;
  borderColor?: string;
  strokeWidth?: number;
  previousBorderColor?: string;
  border?: string;
  objectId: string;
  domRef?: any;
  moduleRef?: any;
  lockAspectRatio?: boolean;
  zIndex?: number;
  type?: string;
  top: number;
  left: number;
  width: number;
  height: number;
  rotation: number;
  // blur: Blur;
  blurIntensity: number;
  blurOpacity: number;
  blurColor: string;
  blurCutoutShapes: BlurCutoutObject[];
  fontColor?: string;
  rotate?: number;
  opacity?: number;
  transform?: {
    rotate?: number;
    scaleX?: number;
    scaleY?: number;
    matrix3d?: number[];
  };
  rawData?: {
    pixelHeight: number;
    pixelWidth: number;
    pixelTop: number;
    pixelLeft: number;
  };
  // used for text boxes
  hasHorizontalPadding?: boolean;
  /**
   *
   */
  ghost?: boolean;
  /**
   * human readable name
   */
  displayName?: string;

  // For panoramics
  yaw: number;
  pitch: number;
  zoom: number;

  /**
   * These properties should only exist in a BaseObject while it is being copied
   * or pasted, they belong in the `useMetaVariableStore` not in the objects themselves.
   */
  metaVariables?: Record<string, any>;
  metaVariablesData?: Record<string, any>;

  /**
   * @deprecated legacy property
   */
  name?: string;
}

export interface Blur {
  pickerHexColor?: string;
  intensity?: number;
  color?: string;
  opacity?: number;
}

export type Image = BaseObject;

export interface PanoramicObject extends BaseObject {
  assetVersionId: number;
  assetBlobPath: string;
  pType: "cubemap";
  cubeMap: { type: "front" | "back" | "left" | "right" | "top" | "bottom"; path: string }[];
  type: "panoramic";
  yaw: number;
  pitch: number;
  zoom: number;
  isDisplayed: boolean;
}

export interface ShapeObject extends BaseObject {
  assetVersionId: number;
  isDisplayed: boolean;
}

export interface IAnnotation extends BaseObject {
  fillColor: string;
  strokeColor: string;
  strokeWidth: number;
}

export type HotspotNextClick = Omit<HotspotObject, "nextClicks" | "objectId" | "pauseOnLoad">;
export type LatestHotspotVersion = "1.2.0";
export type HotspotTarget = {
  objectId: string;
  tasks: Omit<TaskOptions, "action">[];
};
export interface HotspotObject extends BaseObject {
  type: "hotspot";
  nextClicks: HotspotNextClick[];
  pauseOnLoad?: boolean;
  resumeOnClick?: boolean;
  required: boolean;
  version: string;
  targets: HotspotTarget[];
  isDisplayed?: boolean;
}

export interface SmartObject extends BaseObject {
  elementList: any[];
}
export enum PageTypeList {
  Quiz = "Quiz",
  FMS = "FMS",
  Base = "Base",
  ThreeDModel = "ThreeDModel",
  Walkaround = "Walkaround",
  warning = "warning",
}
export type pageType = PageTypeList;

export type AnnotationShapes = "circle" | "square" | "roundedSquare" | "triangle";
export type CutoutShapes = "rectangular" | "ellipse" | "triangle";

export interface BlurCutoutObject {
  type: CutoutShapes;
  x: number;
  y: number;
  w: number;
  h: number;
  isVisible: boolean;
  isOpen: boolean;
  rotate: number;
  id: string;
}
