import React from "react";
import {
  clippedSpaceFromUnitSpace,
  clippedSpaceToUnitSpace,
  snapToTimeline,
  useTimeline,
} from "../../../../contexts/TimelineProvider/TimelineProvider";
import { ReactComponent as DragBars } from "../../../../assets/icons/timeline/drag-icon-green.svg";
import { useDomRef } from "../../../../hooks/useDomRef";
import useDrag from "../../../../hooks/useDrag";
import { createLogger, emptyArray } from "../../../../utils";
import { BAR_HEIGHT, OBJECT_ROW_HEIGHT } from "../../TimelinePanel";
import "./ObjectRow.css";
import useRefAndState from "../../../../hooks/useRefAndState";
import { useLessonPage, useLessonPagesDispatch } from "../../../../contexts/LessonPagesProvider/LessonPagesProvider";
import { FramesFR } from "./Frames/Frames";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../../../contexts/ObjectsProvider";
import { Frame } from "../../../../types";
import { AnimatedMetaVariable, useMetaVariableStore } from "../../../../lib/SmartObject/store";
import { CompositeNarrationRow } from "../CompositeNarrationRow";
import { AudioEffectRow } from "../AudioEffectRow";

const log = createLogger("ObjectRow");
const BAR_HANDLE_WIDTH = 15;
export default function ObjectRow() {
  const objectsState = useObjectsState();
  const objectsDispatch = useObjectsDispatch();
  const [tl] = useTimeline();
  const { currentLessonPageIndex } = useLessonPage();
  const animatedMetaVariables = useMetaVariableStore((s) => s.animatedMetaVariables);
  const adjustAnimatedMetaVariablesTimestamps = useMetaVariableStore((s) => s.adjustAnimatedMetaVariablesTimestamps);

  if (!objectsState.animatedObjects) {
    return null;
  }
  // TODO: this could be done each time we update the objects instead of each render
  const zIndexMap = Object.fromEntries(objectsState.objectList.map((o) => [o.objectId, o.zIndex]));
  return (
    <>
      <CompositeNarrationRow key={`${currentLessonPageIndex}-composite`} />
      <AudioEffectRow key={`${currentLessonPageIndex}-audio-effects`} />

      {!!objectsState.animatedObjects.length &&
        [...objectsState.animatedObjects]
          .sort(
            // sort by arrangement
            (a, b) => zIndexMap[b.id] - zIndexMap[a.id],
          )
          .map((ao) => {
            const animatedMetaVariablesObj = animatedMetaVariables.filter((v) => v.objectId === ao.id);
            return (
              <React.Fragment key={ao.id}> 
                {ao.type === "hotspot" && <HotspotBar key={ao.id} start={ao.start} objectId={ao.id} />}
                {(ao.type === "bar" || !ao.type) && (
                  <>
                    <ObjectBar
                      key={ao.id}
                      objectId={ao.id}
                      start={ao.start}
                      end={ao.end ?? null}
                      frames={ao.frames ?? []}
                      onMoved={(newStart: number, newEnd: number | null) => {
                        const sequenceLength = tl.sequenceLength;
                        objectsDispatch({
                          type: ObjectActionsType.UPDATE_OBJECT_START_END,
                          payload: {
                            objectId: ao.id,
                            start: newStart,
                            // send null if the end is the same as the sequence length
                            end: sequenceLength === newEnd ? null : newEnd,
                          },
                        });

                        adjustAnimatedMetaVariablesTimestamps(
                          ao.id,
                          ao.start,
                          newStart,
                          ao.end ?? sequenceLength,
                          newEnd ?? sequenceLength,
                        );
                      }}
                    />
                    {animatedMetaVariablesObj.length > 0 &&
                      animatedMetaVariablesObj.map((variable) => (
                        <ObjectBar
                          frames={variable}
                          key={variable.metaVariableKey}
                          start={ao.start}
                          end={ao.end ?? null}
                          onMoved={() => {
                            //
                          }}
                          disableDrag
                        />
                      ))}
                  </>
                )}
              </React.Fragment>
            );
          })}
    </>
  );
}

function HotspotBar({ start, objectId }: { start: number; objectId: string }) {
  const lessonPagesDispatch = useLessonPagesDispatch();
  const [timeline, timelineDispatch] = useTimeline();
  const [hpBarRef, hpBarState] = useRefAndState<HTMLDivElement | null>(null);
  const objectsDispatch = useObjectsDispatch();
  const {
    scaledSpace: { leftPadding },
    clippedSpace,
    widthInPx,
    sequenceLength,
  } = timeline;
  const left = clippedSpaceFromUnitSpace(start, leftPadding, clippedSpace, widthInPx);
  const end = clippedSpaceFromUnitSpace(start + 1, leftPadding, clippedSpace, widthInPx);
  useDrag(hpBarState, {
    debugName: "ObjectBar",
    onDragStart() {
      const initialPosition = left;
      let finalUnitPosition: number;
      const oldLength = end - start;
      return {
        onDrag(dx) {
          let newPosition = initialPosition + dx;
          finalUnitPosition = clippedSpaceToUnitSpace(newPosition, leftPadding, clippedSpace, widthInPx);

          newPosition = clippedSpaceFromUnitSpace(finalUnitPosition, leftPadding, clippedSpace, widthInPx);
          hpBarState!.style.left = newPosition + "px";
        },
        onDragEnd(dragHappened) {
          if (!dragHappened) return;
          let snappedPosition = snapToTimeline(finalUnitPosition);
          const clippedPos = clippedSpaceFromUnitSpace(snappedPosition, leftPadding, clippedSpace, widthInPx);
          hpBarState!.style.left = clippedPos + "px";
          if (snappedPosition < 0) {
            snappedPosition = 0;
          }

          objectsDispatch({
            type: ObjectActionsType.UPDATE_OBJECT_START_END,
            payload: {
              objectId: objectId,
              start: snappedPosition,
              end: null, // hotspots always no end
            },
          });
        },
      };
    },
  });

  return (
    <li className="right-obj-row">
      <div
        className="right-obj-wrapper"
        style={{
          height: OBJECT_ROW_HEIGHT,
        }}
      >
        <div
          className="hotspot center alternating-color"
          ref={hpBarRef}
          style={{
            position: "absolute",
            backgroundColor: "var(--hotspot-color)",
            left,
            width: end - left,
            height: BAR_HEIGHT,
            pointerEvents: "all",
            cursor: "url('/Mouse/move-row.svg') 10 5, auto",
          }}
        ></div>
      </div>
    </li>
  );
}

function ObjectBar(props: {
  onMoved: any;
  objectId?: string;
  start: number;
  end: number | null;
  frames: Frame[] | AnimatedMetaVariable;
  disableDrag?: boolean;
}) {
  const [timeline] = useTimeline();
  const {
    scaledSpace: { leftPadding },
    clippedSpace,
    widthInPx,
    sequenceLength,
  } = timeline;
  const start = props.start ?? 0;
  const end = props.end ?? sequenceLength;
  const frames = props.frames ?? emptyArray;
  const [barRef, setRef] = useDomRef();
  const [leftDragRef, setLeftDragRef] = useDomRef();
  const [rightDragRef, setRightDragRef] = useDomRef();
  const [framesRef, setFramesRef] = useDomRef();
  const clippedStart = clippedSpaceFromUnitSpace(start ?? 0, leftPadding, clippedSpace, widthInPx);
  const clippedEnd = clippedSpaceFromUnitSpace(end, leftPadding, clippedSpace, widthInPx);

  const [draggingMiddle] = useDrag(barRef, {
    debugName: "ObjectBar",
    onDragStart() {
      const initialPosition = clippedStart;
      let finalUnitPosition: number;
      const oldLengthUnits = end - start;
      return {
        onDrag(dx) {
          if (props.disableDrag) return;
          if (!barRef) return;
          if (!leftDragRef) return;
          if (!rightDragRef) return;
          if (!framesRef) return;
          let newPosition = initialPosition + dx;
          finalUnitPosition = clippedSpaceToUnitSpace(newPosition, leftPadding, clippedSpace, widthInPx);

          newPosition = clippedSpaceFromUnitSpace(finalUnitPosition, leftPadding, clippedSpace, widthInPx);
          const newEndTimeUnits = finalUnitPosition + oldLengthUnits;
          const newEndTimeClipped = clippedSpaceFromUnitSpace(newEndTimeUnits, leftPadding, clippedSpace, widthInPx);
          rightDragRef.style.left = newEndTimeClipped - BAR_HANDLE_WIDTH + "px";
          barRef.style.left = newPosition + "px";
          leftDragRef.style.left = newPosition + "px";
          // rightDragRef!.style.left = clippedRight + 'px';
          framesRef.style.transform = `translateX(${dx}px)`;
        },
        onDragEnd(dragHappened) {
          if (props.disableDrag) return;
          if (!dragHappened) return;
          if (!barRef) return;
          if (!leftDragRef) return;
          if (!rightDragRef) return;
          if (!framesRef) return;
          // let snappedUnitPosition = Math.round(finalUnitPosition);
          // round to nearest 0.25
          let snappedUnitPosition = snapToTimeline(finalUnitPosition);
          if (snappedUnitPosition < 0) {
            snappedUnitPosition = 0;
          }

          let newEndTimeUnits: number | null = snappedUnitPosition + oldLengthUnits;
          const newClippedSpace = clippedSpaceFromUnitSpace(snappedUnitPosition, leftPadding, clippedSpace, widthInPx);

          barRef.style.left = newClippedSpace + "px";
          leftDragRef.style.left = newClippedSpace + "px";
          // rightDragRef!.style.left = newClippedSpace + oldLengthUnits + 'px';
          if (snappedUnitPosition < 0) {
            snappedUnitPosition = 0;
          }

          if (newEndTimeUnits >= sequenceLength) {
            newEndTimeUnits = null;
          }

          const [newStart, timelineEndUnits] = move(start, end, snappedUnitPosition, newEndTimeUnits, sequenceLength);
          if (Number.isNaN(newStart) || Number.isNaN(timelineEndUnits)) {
            // skip if the new start or end is NaN

            return console.error("newStart or timelineEndUnits is NaN");
          }
          props.onMoved(newStart, timelineEndUnits);
          log("newStart", newStart, "newEnd", timelineEndUnits);

          if (typeof newStart === "number") {
            const newClippedLeft = clippedSpaceFromUnitSpace(newStart, leftPadding, clippedSpace, widthInPx);
            barRef.style.left = newClippedLeft + "px";

            if (timelineEndUnits) {
              const timelineEndClipped = clippedSpaceFromUnitSpace(
                timelineEndUnits,
                leftPadding,
                clippedSpace,
                widthInPx,
              );
              barRef.style.width = timelineEndClipped - newClippedLeft + "px";
              rightDragRef.style.left = timelineEndClipped - BAR_HANDLE_WIDTH + "px";
            } else if (timelineEndUnits === null) {
              const newLength = clippedSpaceFromUnitSpace(sequenceLength, leftPadding, clippedSpace, widthInPx);
              barRef.style.width = newLength - newClippedLeft + "px";
            }
          }
          framesRef.style.transform = `translateX(${0}px)`;
        },
      };
    },
  });
  const [draggingLeft] = useDrag(leftDragRef, {
    debugName: "ObjectBar-LeftDrag",
    onDragStart() {
      const start = props.start ?? 0;
      const end = props.end ?? sequenceLength;
      const initialPosition = clippedStart;
      const initialWidth = clippedEnd - clippedStart;
      let finalUnitPosition = start ?? 0;
      const firstFrameTime = frames[0]?.timestamp ?? null;
      const hasFrame = firstFrameTime !== null;
      const firstFramePosition = clippedSpaceFromUnitSpace(
        hasFrame ? firstFrameTime : end - 1,
        leftPadding,
        clippedSpace,
        widthInPx,
      );
      const minWidth = Math.abs(firstFramePosition - clippedEnd);

      return {
        onDrag(dx) {
          if (props.disableDrag) return;
          if (!barRef) return;
          if (!leftDragRef) return;
          const left = initialPosition + dx;
          const newWidth = initialWidth - dx;

          if (newWidth < minWidth) {
            return;
          }

          barRef.style.left = left + "px";
          barRef.style.width = newWidth + "px";
          leftDragRef.style.left = left + "px";
          finalUnitPosition = clippedSpaceToUnitSpace(left, leftPadding, clippedSpace, widthInPx);
        },
        onDragEnd(dragHappened) {
          if (props.disableDrag) return;
          if (!dragHappened) return;
          if (!barRef) return;
          if (!leftDragRef) return;
          let snappedLeftUnits = snapToTimeline(finalUnitPosition);
          let newClippedSpaceLeft = clippedSpaceFromUnitSpace(snappedLeftUnits, leftPadding, clippedSpace, widthInPx);
          if (newClippedSpaceLeft < 0) {
            newClippedSpaceLeft = leftPadding;
          }
          barRef.style.left = newClippedSpaceLeft + "px";
          barRef.style.width = clippedEnd - newClippedSpaceLeft + "px";
          leftDragRef.style.left = newClippedSpaceLeft + "px";
          if (snappedLeftUnits < 0) {
            snappedLeftUnits = 0;
          }
          const [newStart, newEnd] = move(start, end, snappedLeftUnits, undefined, sequenceLength);
          props.onMoved(newStart, newEnd);
          if (newStart) {
            const newClippedStart = clippedSpaceFromUnitSpace(newStart, leftPadding, clippedSpace, widthInPx);
            barRef.style.left = newClippedStart + "px";
            barRef.style.width = clippedEnd - newClippedStart + "px";
            leftDragRef.style.left = newClippedStart + "px";
          }
          if (newEnd && newStart) {
            const newClippedEnd = clippedSpaceFromUnitSpace(newEnd, leftPadding, clippedSpace, widthInPx);
            const newClippedStart = clippedSpaceFromUnitSpace(newStart, leftPadding, clippedSpace, widthInPx);
            barRef.style.left = newClippedStart + "px";
            barRef.style.width = newClippedEnd - newClippedStart + "px";
            leftDragRef.style.left = newClippedStart + "px";
          }
        },
      };
    },
  });
  const [draggingRight] = useDrag(rightDragRef, {
    debugName: "ObjectBar-RightDrag",
    onDragStart() {
      const initialPosition = clippedStart;
      const initialWidth = clippedEnd - clippedStart;
      let lastWidth: number;
      const lastFrameTime = frames[frames.length - 1]?.timestamp ?? null;
      const hasFrame = lastFrameTime !== null;
      const lastFramePosition = clippedSpaceFromUnitSpace(
        hasFrame ? lastFrameTime : start + 1,
        leftPadding,
        clippedSpace,
        widthInPx,
      );
      const minWidth = clippedStart * -1 + lastFramePosition;
      return {
        onDrag(dx) {
          if (props.disableDrag) return;
          if (!barRef) return;
          if (!rightDragRef) return;
          lastWidth = initialWidth + dx;
          let rightPixel = initialPosition + lastWidth - BAR_HANDLE_WIDTH;
          // const snappedWidth = l
          if (rightPixel < lastFramePosition - BAR_HANDLE_WIDTH) {
            rightPixel = lastFramePosition - BAR_HANDLE_WIDTH;
          }
          if (lastWidth < minWidth) {
            lastWidth = minWidth;
          }
          barRef.style.width = lastWidth + "px";
          rightDragRef.style.left = rightPixel + "px";
        },
        onDragEnd(dragHappened) {
          if (props.disableDrag) return;
          if (!barRef) return;
          if (!rightDragRef) return;
          if (!dragHappened) return;
          const newClippedEnd = lastWidth + clippedStart;
          const newUnitEnd = clippedSpaceToUnitSpace(newClippedEnd, leftPadding, clippedSpace, widthInPx);
          let snappedEnd: number | null = snapToTimeline(newUnitEnd);
          if (snappedEnd > sequenceLength) {
            // if right drag is over the sequence length, set it to null
            snappedEnd = null;
          }
          const [newStart, newEnd] = move(start, end, undefined, snappedEnd, sequenceLength);
          props.onMoved(newStart, newEnd);
          if (typeof newEnd === "number") {
            const endClipped = clippedSpaceFromUnitSpace(newEnd, leftPadding, clippedSpace, widthInPx);
            barRef.style.width = endClipped - clippedStart + "px";
            rightDragRef.style.left = endClipped - BAR_HANDLE_WIDTH + "px";
          } else if (newEnd === null) {
            const newLength = clippedSpaceFromUnitSpace(sequenceLength, leftPadding, clippedSpace, widthInPx);
            barRef.style.width = newLength - clippedStart + "px";
            rightDragRef.style.left = newLength - BAR_HANDLE_WIDTH + "px";
          }
        },
      };
    },
  });

  const cursorLock = props.disableDrag ? "not-allowed" : getDraggingCursor(draggingLeft, draggingRight, draggingMiddle);

  return (
    <li className="right-obj-row" style={{ height: OBJECT_ROW_HEIGHT }}>
      <div
        className="right-obj-wrapper"
        style={{
          height: OBJECT_ROW_HEIGHT,
          pointerEvents: "all",
        }}
      >
        <div
          className="pointer-events center bar-left-handle"
          ref={setLeftDragRef}
          style={{
            position: "absolute",
            left: clippedStart,
            width: BAR_HANDLE_WIDTH,
            height: BAR_HEIGHT,
            zIndex: 20,
            borderRadius: 5,
            cursor: cursorLock,
          }}
        >
          <DragBars />
        </div>
        <div
          className="pointer-events center bar-middle-handle alternating-color"
          ref={setRef}
          style={{
            position: "absolute",
            left: clippedStart,
            width: clippedEnd - clippedStart,
            height: BAR_HEIGHT,
            borderRadius: 5,
            cursor: cursorLock,
          }}
        ></div>
        <div
          className="pointer-events center bar-right-handle"
          ref={setRightDragRef}
          style={{
            position: "absolute",
            left: clippedEnd - BAR_HANDLE_WIDTH,
            width: BAR_HANDLE_WIDTH,
            height: BAR_HEIGHT,
            zIndex: 20,
            borderRadius: 5,
            cursor: cursorLock,
          }}
        >
          <DragBars />
        </div>
        <FramesFR ref={setFramesRef} frames={frames} start={start} end={end} objectId={props.objectId} />
      </div>
    </li>
  );
}

function getDraggingCursor(draggingLeft: boolean, draggingRight: boolean, draggingMiddle: boolean) {
  if (draggingLeft || draggingRight) {
    return "url('/Mouse/drag-row-border.svg') 10 9, auto";
  } else if (draggingMiddle) {
    return "url('/Mouse/move-row.svg') 10 5, auto";
  }
}

function move(
  prevStart: any,
  prevEnd: any,
  start: number | undefined,
  end: number | null | undefined,
  sequenceLength: number,
) {
  const newTimes = barStartAndEndLogic({
    start: start,
    end: end,
    sequenceLength,
    prevStart,
    prevEnd,
  });

  return [newTimes.start, newTimes.end];
}

function barStartAndEndLogic(args: {
  start?: number;
  end?: number | null;
  sequenceLength: number;
  prevStart: number;
  prevEnd: number;
}): { start?: number; end?: number | null } {
  const { start, end, sequenceLength, prevStart, prevEnd } = args;

  function isNumber(value: any): value is number {
    return typeof value === "number";
  }

  function handleStartOnly(start: number): number {
    start = Math.max(0, Math.min(start, sequenceLength - 1, prevEnd - 1));
    return start;
  }

  function handleEndOnly(end: number | null): number | null {
    if (isNumber(end)) {
      end = Math.max(prevStart + 1, Math.min(end, sequenceLength - 1));
    }
    return end;
  }

  function handleBothStartAndEnd(start: number, end: number | null): [number, number | null] {
    start = Math.max(0, Math.min(start, end === null ? sequenceLength - 1 : end - 1));
    end = end === null ? null : Math.max(start + 1, Math.min(end, sequenceLength - 1));
    return [start, end];
  }

  let newStart: number | undefined = start;
  let newEnd: number | null | undefined = end;

  if (start !== undefined && end === undefined) {
    newStart = handleStartOnly(start);
  } else if (start === undefined && end !== undefined) {
    newEnd = handleEndOnly(end);
  } else if (start !== undefined && end !== undefined) {
    [newStart, newEnd] = handleBothStartAndEnd(start, end);
  }

  if (newStart === undefined) {
    newStart = prevStart;
  }
  if (newEnd === undefined) {
    newEnd = prevEnd;
  }

  return { start: newStart, end: newEnd };
}
