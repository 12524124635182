import React from "react";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import { ElementTypes, IBasicPageImageV2 } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import AnnotationHighlight from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";
import { IKeyPressHandler } from "../../components/ObjectPropertyBox/models/IKeyPressHandler";
import { ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";
import type { BlurCutoutObject } from "../../types";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import {
  useInteracitvityHotspotDispatch,
  InteractivityHotspotActionTypes,
} from "../../contexts/InteractivityHotspotProvider";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { objectOpacityFromRules } from "../../utils";
import { BlurComponent } from "../../components/Annotations/components/BlurComponent";
import { MasksRenderer } from "./MasksRenderer";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";
import { BlurCutoutComponent } from "../../components/Annotations/components/BlurCutoutComponent";

export interface ImagePropType {
  blobUrl: string;
  grayscale?: number;
  ffElement: IBasicPageImageV2;
  index: number;
  frames?: any[];
  framesCacheId?: string;
  kp: IKeyPressHandler;
  target: any;
  objectId: string;

  scale?: any[];
  rotate?: number;
  zIndex?: number;

  pixelTop?: number;
  pixelLeft?: number;
  pixelWidth?: number;
  pixelHeight?: number;
  clipTop?: number;
  clipLeft?: number;
  clipRight?: number;
  clipBottom?: number;
  clipPath?: string;

  maintainRatio?: boolean;

  height?: number;
  width?: number;
  left: number;
  top: number;

  version?: any;

  fontColor?: string;
  fontStyle?: string;
  fontWeight?: any;

  borderColor?: string;
  shadowColor?: string;
  textDecoration?: string;
  backgroundColor?: string;
  opacity?: number;
  visible?: boolean;
  ghost?: boolean;
  blurIntensity?: number;
  blurOpacity?: number;
  blurColor?: string;
  blurCutoutShapes: BlurCutoutObject[];

  handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void;
  loadImage: (e: React.SyntheticEvent<HTMLImageElement>, index: number, objectId: string) => void;
  selectTarget: (
    target: HTMLDivElement,
    index: number,
    type: ElementTypes,
    ref?: React.RefObject<HTMLElement | SVGSVGElement>,
  ) => void;
  handleImageDivClick: (index: number) => void;
}
const BlurredImage = (props: ImagePropType): JSX.Element => {
  const {
    blobUrl,
    index,
    loadImage,
    objectId,
    scale,
    clipTop,
    clipLeft,
    clipRight,
    clipBottom,
    fontColor,
    fontStyle,
    fontWeight,
    borderColor,
    shadowColor,
    version,
    width,
    left,
    top,
    rotate,
    height,
    maintainRatio,
    zIndex,
    textDecoration,
    backgroundColor,
    clipPath,
    opacity,
    visible,
    ghost,
    blurIntensity,
    blurCutoutShapes,
    // transformOrigin,
  } = props;
  const scaleString: string = scale ? `scale(${scale[0]}, ${scale[1]})` : "";

  const {
    position: [x, y],
    size: [widthWithFrames, heightWithFrames],
    rotation,
    opacity: opacityValue,
    blurIntensity: interpolatedBlurIntensity,
  } = useObjectPosition(objectId, top, left, width, height, rotate, opacity, blurIntensity);
  const isInTime = useObjectIsInTime(objectId);
  const rotateString: string = rotation ? `rotate(${rotation}deg)` : "rotate(0deg)";
  const hasLegacyClipPath = ![clipTop, clipLeft, clipRight, clipBottom].every((c) => c === 0 || c === undefined);
  const clipP =
    hasLegacyClipPath && !clipPath
      ? `inset(${clipTop}px ${clipRight}px ${clipBottom}px ${clipLeft}px)`
      : clipPath
      ? clipPath
      : "";
  const objectsDispatch = useObjectsDispatch();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();
  const [miscUI] = useMiscUI();
  const currentSelectedCutoutId = miscUI.selectedMask[0];
  const isCutoutSelected = Boolean(currentSelectedCutoutId);
  const transformString = `translate(${x}px, ${y}px) ${rotateString}`;
  const cutoutList = blurCutoutShapes ?? [];
  const masks = cutoutList.map((cutout) => {
    return {
      type: cutout.type,
      x: cutout.x,
      y: cutout.y,
      width: cutout.w,
      height: cutout.h,
      rotate: cutout.rotate,
      cutoutId: cutout.id,
      isVisible: cutout.isVisible,
    };
  });
  const maskIds = masks.map((mask) => `url(#${mask.cutoutId}) exclude`);
  // wen need the linear gradient for this trick to work
  const maskString = `${maskIds.join(",")}, linear-gradient(#000 0 0)`;

  return (
    <div
      className={`target ff-image guide-${objectId}`}
      id={`image-target-${index}`}
      data-objectid={objectId}
      key={index}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId },
        });
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
      }}
      onDoubleClick={() => props.handleImageDivClick(index)}
      tabIndex={0}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        clipPath: clipP,
        zIndex: zIndex,
        scale: `${scaleString}`,
        transform: transformString,
        color: `${fontColor}`,
        fontStyle: `${fontStyle}`,
        fontWeight: fontWeight,
        textDecoration: `${textDecoration}`,
        backgroundColor: `${backgroundColor}`,
        outline: `${borderColor}`,
        boxShadow: `${shadowColor}`,
        opacity: objectOpacityFromRules(opacityValue, visible, isInTime, ghost),
        filter: visible ? "none" : "grayScale(100%)",
      }}
    >
      <MasksRenderer masks={masks} />
      <AnnotationHighlight objectId={objectId} />
      <div id="free-form-container" className="img-aspect-container">
        <div className="free-form-element aspect-container-inside">
          <div className={`free-form-inner aspect-centering${isCutoutSelected ? " no-hover-image" : " "}`}>
            <img
              id={`ff-image-${index}`}
              src={blobUrlFormatHelper(blobUrl)}
              alt=""
              onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
                // we only want this to trigger if there is no version or if the version is less than 3
                if (!version || Number(version) < 3) {
                  loadImage(e, index, objectId);
                }
              }}
              style={{
                display: isInTime || ghost ? "block" : "none",
                width: "100%",
                height: maintainRatio ? "auto" : "100%",
                mask: maskString,
                maskComposite: "exclude",
                filter: typeof props.grayscale === "number" ? `grayscale(${props.grayscale}%)` : undefined,
              }}
            />
            {typeof interpolatedBlurIntensity === "number" && interpolatedBlurIntensity > 0 && (
              <BlurComponent
                width={width || 0}
                height={height || 0}
                objectId=""
                blurIntensity={interpolatedBlurIntensity as number}
                opacity={0.5}
                zIndex={zIndex || 0}
                id={`${objectId}-blur`}
                isDisplayed={true}
                fullWidth
                inactiveDrag
                backgroundColor="transparent"
              />
            )}
            {cutoutList.map((blurCutoutItem) => (
              <BlurCutoutComponent
                key={blurCutoutItem.id}
                imagePath={blobUrlFormatHelper(blobUrl)}
                cutoutId={blurCutoutItem.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlurredImage;
