import React, { MouseEventHandler, useEffect, useMemo } from "react";

import { ReactComponent as StopIcon } from "../../../assets/icons/audio/stop-icon.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/audio/play-icon.svg";
import { ReactComponent as PauseIcon } from "../../../assets/icons/audio/pause-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Common/trash-icon.svg";

import { ReactComponent as ZoomInIcon } from "../../../assets/icons/Common/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "../../../assets/icons/Common/zoom-out.svg";

import { TOP_STRIP_HEIGHT } from "../TimelinePanel";
import { useTimeline } from "../../../contexts/TimelineProvider/TimelineProvider";
import { useAudioManagerStore } from "../../../contexts/PageAudioManager";
import { renderTimeLength } from "../../AudioProperties/helpers";
import { useTimer } from "../../../contexts/Timer";
import { useConfirmation } from "../../../contexts/Confirmation";
import { useShallow } from "zustand/react/shallow";
import { useMetaVariableStore } from "../../../lib/SmartObject/store";
import { useObjectsState } from "../../../contexts/ObjectsProvider";
import {
  getLastAnimatedMetaVariableTimeStamp,
  getLastAnimatedObjectTimeStamp,
  getLastAudioTimeStamp,
} from "../helpers";

const ZOOM_STRENGTH = 3;
export default function LeftOverlay() {
  const Timer = useTimer();
  const [{ scrubbingCurrentTime }, timelineDispatch] = useTimeline();
  const narrationControls = useAudioManagerStore((state) => state.getSequenceControls());
  const { animatedObjects } = useObjectsState();
  const animatedMetaVariables = useMetaVariableStore((s) => s.animatedMetaVariables);
  const audioEffects = useAudioManagerStore((s) => s.audioEffects);
  const narrationAudios = useAudioManagerStore((s) => s.narrationAudios);
  const [selectedAudio, removeNarrationAudio, setIsDirty] = useAudioManagerStore(
    useShallow((state) => [state.selectedAudio, state.removeNarrationAudio, state.setIsDirty]),
  );
  // const narrationControls = getSequenceControls();
  const { confirm } = useConfirmation();

  const [{ heightInPx }, dispatcher] = useTimeline();

  const handleClickPlay: MouseEventHandler = () => {
    if (!Timer.running) {
      const currentTime = Number(scrubbingCurrentTime) ?? 0;
      narrationControls?.play(currentTime);
      Timer.start(currentTime);
    }
  };
  const handleClickPause: MouseEventHandler = () => {
    if (Timer.running) {
      narrationControls?.pause();
      Timer.stop();
    }
  };
  const handleClickStop: MouseEventHandler = () => {
    if (Timer.running) {
      narrationControls?.stop();
      Timer.stop();
    }

    // Send scrub back to 0
    timelineDispatch({
      type: "SET_SECONDS",
      payload: 0,
    });
  };

  const lastAnimatedObjectTimestamp = useMemo(() => getLastAnimatedObjectTimeStamp(animatedObjects), [animatedObjects]);
  const lastAudioEffectTimestamp = useMemo(() => getLastAudioTimeStamp(audioEffects), [audioEffects]);
  const lastNarrationAudioTimestamp = useMemo(() => getLastAudioTimeStamp(narrationAudios), [narrationAudios]);
  const lastAnimatedMetaVariableTimestamp = useMemo(
    () => getLastAnimatedMetaVariableTimeStamp(animatedMetaVariables),
    [animatedMetaVariables],
  );

  const lastKeyframeTimestamp = useMemo(
    () =>
      Math.max(
        lastAnimatedObjectTimestamp,
        lastAnimatedMetaVariableTimestamp,
        lastAudioEffectTimestamp,
        lastNarrationAudioTimestamp,
      ),
    [
      lastAnimatedObjectTimestamp,
      lastAnimatedMetaVariableTimestamp,
      lastAudioEffectTimestamp,
      lastNarrationAudioTimestamp,
    ],
  );

  useEffect(() => {
    if (!Timer.running) return;

    if (scrubbingCurrentTime > lastKeyframeTimestamp) {
      narrationControls?.pause();
      Timer.stop();
    }
  }, [Timer.running, scrubbingCurrentTime, lastKeyframeTimestamp]);

  const handleClickZoomIn = () => {
    dispatcher({
      type: "ADD_RANGE",
      payload: {
        start: 0,
        end: -ZOOM_STRENGTH,
      },
    });
  };

  const handleClickZoomOut = () => {
    dispatcher({
      type: "ADD_RANGE",
      payload: {
        start: 0,
        end: ZOOM_STRENGTH,
      },
    });
  };

  const handleClickDeleteAudio = async () => {
    const shouldRemove = await confirm({
      title: "Remove Audio",
      message: "Are you sure you want to delete this audio from the timeline?",
    });

    if (shouldRemove && selectedAudio) {
      removeNarrationAudio(selectedAudio);

      setIsDirty(true);
    }
  };

  return (
    <div
      className="left-overlay"
      style={{
        position: "absolute",
        width: "30%",
        height: heightInPx,
        left: 0,
        top: 0,
        zIndex: 3,
      }}
    >
      <div
        className="timeline-left-top"
        style={{
          height: TOP_STRIP_HEIGHT,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <div className="timeline-actions">
          {Timer.running ? (
            <button
              className="cpat-icon-button cpat-icon-button--md"
              disabled={!Timer.running}
              onClick={handleClickPause}
              title="Pause"
            >
              <PauseIcon />
            </button>
          ) : (
            <button
              className="cpat-icon-button cpat-icon-button--md"
              disabled={Timer.running || scrubbingCurrentTime > lastKeyframeTimestamp}
              onClick={handleClickPlay}
              title="Play/Resume"
            >
              <PlayIcon />
            </button>
          )}
          <div className="timeline-divider"></div>
          <button className="cpat-icon-button cpat-icon-button--md" onClick={handleClickStop} title="Stop">
            <StopIcon />
          </button>
          <div className="timeline-divider"></div>
          <button className="cpat-icon-button cpat-icon-button--md" onClick={handleClickZoomIn} title="Stop">
            <ZoomInIcon />
          </button>
          <div className="timeline-divider"></div>
          <button className="cpat-icon-button cpat-icon-button--md" onClick={handleClickZoomOut} title="Stop">
            <ZoomOutIcon />
          </button>
          <div className="timeline-divider"></div>
          <button
            className="cpat-icon-button cpat-icon-button--md"
            onClick={handleClickDeleteAudio}
            title="Delete Audio"
            disabled={!selectedAudio}
          >
            <DeleteIcon />
          </button>
          <TimelineTimeCounter currentTime={scrubbingCurrentTime} />
        </div>
      </div>
    </div>
  );
}

function TimelineTimeCounter({ currentTime }: { currentTime: number }) {
  return (
    <div className="timeline-timer">
      <strong>Time: </strong>
      <span>{renderTimeLength(currentTime, true)}</span>
    </div>
  );
}
