import { useState, useEffect } from "react";
import { Section, FramedInput } from "../../../panels/ObjectPropertiesPanel";
import {
  SelectedObjectActionTypes,
  useSelectedObjectDispatch,
  useSelectedObjectState,
} from "../../../contexts/SelectedObjectProvider/SelectedObjectProvider";
import { ObjectActionsType, useObjectsDispatch } from "../../../contexts/ObjectsProvider";
import { BaseObject } from "../../../types";
import { OBJECT_TYPE_SHAPE } from "../../../const";

type BlurInputType = {
  selectedObject: BaseObject;
  currentTime?: number;
};

const BlurInput = ({ selectedObject }: BlurInputType) => {
  const selectedObjectDispatch = useSelectedObjectDispatch();
  const { blurIntensity } = useSelectedObjectState();
  const objectsDispatch = useObjectsDispatch();
  const isMask = selectedObject?.type === OBJECT_TYPE_SHAPE;

  const onBlurIntensityChange = (intensity: string) => {
    const intensityValue = Number(intensity);

    selectedObjectDispatch({
      type: SelectedObjectActionTypes.SET_BLUR_INTENSITY,
      payload: intensityValue,
    });

    objectsDispatch({
      type: ObjectActionsType.SET_BLUR_INTENSITY,
      payload: { objectId: selectedObject.objectId, blurIntensity: intensityValue },
    });
  };

  return (
    <Section title={null} wrap={false} separator={true}>
      <FramedInput
        label="Blur"
        min={0}
        value={blurIntensity}
        inputType="number"
        isHovered={false}
        onInputChange={(e) => onBlurIntensityChange(e.target.value)}
        svgShowing={isMask ? true : false}
      />
    </Section>
  );
};

export default BlurInput;
