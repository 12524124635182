import { AudioInputTypes } from "../classes/GlobalAudioManager/types";
import { TextToSpeechFormSchema } from "../components/Modals/TextToSpeechModal/TextToSpeechForm";

export enum EInteractiveAudioType {
  ONESHOT = "oneshot",
  NARRATION = "narration",
  EFFECT = "effect",
}

/**
 * I the future will be supporting actual languages
 */
export enum LanguageType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export interface IInteractiveAudio {
  objectId: string;
  title?: string;
  description?: string;
  type: EInteractiveAudioType;
  keywords?: string;
  start: number;
  end: number;
  duration?: number;
  language: LanguageType;
  parentObjectId?: string;
  // should we support other forms such as src, buffer, etc..  or leave it to implementations?
  blob?: Blob;
  // testing a different entry here
  // input: AudioInputTypes;
  input: string;
}
/**
 * one shot audios are audios that are saved. they can still
 * be created from tts
 */
export interface OneShotAudio extends IInteractiveAudio {
  type: EInteractiveAudioType.ONESHOT;
}

export interface NarrationAudio extends IInteractiveAudio {
  type: EInteractiveAudioType.NARRATION;
  /**
   * exact text that was generated from the text to speech
   */
  savedText: string;
  ttsProps?: Pick<TextToSpeechFormSchema, "language" | "voice" | "narrationText" | "prosodyRate" | "pronunciationText">;
}

export interface EffectAudio extends Omit<IInteractiveAudio, "language"> {
  type: EInteractiveAudioType.EFFECT;
}

export type ManifestInteractiveAudio<TAudioType extends IInteractiveAudio> = Omit<TAudioType, "blob">;
