import { useMetaVariableStore } from "../../../../store";
import { getHotspotFreezeTimelineKey } from "../../../../utils";
import { Collapse } from "./Collapse";
import styles from "../AdvancedPanel.module.css";
import { useObjectsState } from "../../../../../../contexts/ObjectsProvider";

interface HotspotFreezeProps {
  objectId: string;
}

export const HotspotFreeze = ({ objectId }: HotspotFreezeProps) => {
  const keys = getHotspotFreezeTimelineKey(objectId);
  const value = useMetaVariableStore((s) => s.metaVariables[keys.key]);
  const setMetaVariable = useMetaVariableStore((s) => s.setMetaVariable);
  const animatedMetaVariables = useMetaVariableStore((s) => s.animatedMetaVariables);
  const upsertAnimatedMetaVariable = useMetaVariableStore((s) => s.upsertAnimatedMetaVariable);
  const metadata = useMetaVariableStore((s) => s.metaVariablesData);
  const { selectedObjects, animatedObjects } = useObjectsState();
  const selectedObjectId = selectedObjects[0]?.objectId;
  const isObjectInTimeline = animatedObjects.find((o) => o.id === selectedObjectId);

  const handleCheckboxClick = () => {
    const newValue = !value;
    setMetaVariable(keys.key, newValue);

    if (isObjectInTimeline) {
      animatedMetaVariables.forEach((metaVariable) => {
        const metaVariableKey = metaVariable.metaVariableKey;
        if (
          metaVariableKey.includes(selectedObjectId) &&
          metaVariableKey.includes("hotspot") &&
          metaVariableKey.includes("enabled")
        ) {
          let target = metadata[metaVariableKey].target;

          /**
           * This is a fallback case for when a lesson was already built and the SMOs are added
           * to the lesson and timeline.
           *
           * This target property relies on adding a smart object from the asset manager
           * to be properly setup in the meta data store
           * If the SMO was already added the target key will not be found
           *
           * In that case we can "infer" the target from the metaVariableKey by parsing the key itself
           */
          if (!target) {
            target = metaVariableKey.split("-hotspot")[0] ?? "";
          }

          metaVariable.oneshot?.forEach((oneshot) => {
            upsertAnimatedMetaVariable(
              metaVariable.metaVariableKey,
              oneshot.timestamp,
              oneshot.value,
              selectedObjectId,
              false,
              {
                resumeOnClick: newValue,
                target,
              },
            );
          });
        }
      });
    }
  };

  if (value === undefined || !isObjectInTimeline) return null;

  return (
    <Collapse title="Freeze Timeline" key={keys.key}>
      <div className={styles.checkboxWrapper}>
        <input type="checkbox" checked={value as boolean} onChange={handleCheckboxClick} />
        <p>Timeline Freeze until Hot Spots are accomplished</p>
      </div>
    </Collapse>
  );
};
