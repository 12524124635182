import { IUploadLessonData } from "../../../models/IUploadLessonData";
import { IUploadAssetRequest } from "../../../models/IUploadAssetRequest";
import genericRepositoryService from "../../../services/genericRepositoryService";
import createFormDataInMbChunks from "../../fileDivider";
import { APPLICATION_JSON } from "../../constants/mimeTypes";
import { lowerCaseExtension, replaceInvalidChars } from "../../../utils/Strings";
import * as constants from "../../constants/pageTypes";
import * as AssetManagerTypes from "../AssetManager.types";
import _ from "lodash";
import { fileListObject } from "../interfaces/uploaderInterfaces";
import { convertObjectIntoJsonFile } from "../../../lib/SmartObject";
import { ASSET_TYPE_ID } from "../../../const";
import JSZip from "jszip";

/**
 * export interface IUploadLessonData{
    aircraftPlacementIds: number[] | null;
    ataIds: number[] | null;
    aircraftId: number | null;
    versionName: string;
    aircraftFamilyId: number | null,
    manufacturerId:number | null,
    tailoredForLmsKey: number | null;
}
 */
// interface assetUploadResponse {
//     assetId: number,
//     blobPath: string,
//     errorMessage: string | null
// }
interface returnObject {
  data: AssetManagerTypes.CurrentSelectedAsset | undefined;
  message: string;
}

//export const MAX_BLOB_UPLOAD_SIZE = 101000000;  // TODO: get this value from backend API instead of hard code here
// export const MAX_BLOB_UPLOAD_SIZE = 100700000; //  100,755,915 is seen to work, speculatiing the form upload process includes some size extras not yet explained
export const MAX_BLOB_UPLOAD_SIZE = 200700000;

export const IsFileTooBig = (theFile: File) => {
  return theFile?.size > MAX_BLOB_UPLOAD_SIZE;
};

export const fileTooBigCheckMessage = (theFile: File) => {
  if (IsFileTooBig(theFile)) {
    return (
      "This file is " +
      theFile?.size.toLocaleString(undefined, { minimumFractionDigits: 0 }) +
      " bytes and exceeds the maximum allowed size of " +
      MAX_BLOB_UPLOAD_SIZE.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      }) +
      " bytes."
    );
  }
  return "";
};

//const fileUploadLoop = async (event: React.ChangeEvent<HTMLInputElement>, lessonData:IUploadLessonData, assetTypeId: number) => {
//const getAssetsToRemove = (pageManifest: any, pagePlayerType: string, assetTypeId: number, assetIndex: number, assetData: assetUploadResponse)
//getAssetsToRemove = (pageManifest: any, pagePlayerType: string, assetTypeId: number, assetIndex: number, assetData: assetUploadResponse) => {

export const fileUploadProcess = async (file: File, lessonData: IUploadLessonData, assetTypeId: number) => {
  if (file !== null) {
    const mutableFile = new File([file], massageFileName(file)); //converts the file name to a DB friendly one
    const newAsset = await fileValidation(mutableFile, lessonData, assetTypeId); //checks if file is valid then uploads
    return newAsset;
  }
};

export const smartObjectUploadProcess = async (fileData: fileListObject, lessonData: IUploadLessonData) => {
  const returnObject: returnObject = {
    data: undefined,
    message: "",
  };
  const { file, smartObject } = fileData;

  if (!file || !smartObject) {
    returnObject.message = "File is required";
    return returnObject;
  }
  const [filename] = file.name.split(".");
  const jsonFilename = `smartObject.json`;
  const settingsFilename = `settings.json`;

  const mutableFile = new File([file], massageFileName(file));
  const mutableSmartObject = new File([smartObject], massageFileName({ name: jsonFilename }), {
    type: APPLICATION_JSON,
  });

  const settings = {
    type: "svg",
    files: {
      svg: file.name,
      json: jsonFilename,
    },
  };

  const json = convertObjectIntoJsonFile(settings, "settings");
  const mutableSettingsJson = new File([json], massageFileName({ name: settingsFilename }), { type: APPLICATION_JSON });

  // check if valid extension
  const validFileType = manageFileType(mutableFile, ASSET_TYPE_ID.SMART_OBJECT);

  if (!validFileType) {
    returnObject.message = "Invalid file type";
    return returnObject;
  }

  if (IsFileTooBig(file)) {
    returnObject.message = fileTooBigCheckMessage(mutableFile);
    return returnObject;
  }

  const request = {
    assetTypeId: ASSET_TYPE_ID.SMART_OBJECT,
    description: lessonData.description ? lessonData.description : file.name,
    aircraftId: lessonData.aircraftId,
    aircraftFamilyId: lessonData.aircraftFamilyId,
    manufacturerId: lessonData.manufacturerId,
    aircraftPlacementIds: lessonData.aircraftPlacementIds,
    ataIds: lessonData.ataIds,
    versionName: lessonData.versionName,
    tailoredForLmsKey: lessonData.tailoredForLmsKey,
    uploadForLmsKey: lessonData.uploadForLmsKey,
  };
  const requestBlob = new Blob([JSON.stringify(request)], {
    type: APPLICATION_JSON,
  });
  // convert blob to json file
  const requestFile = new File([requestBlob], "request.json", {
    type: APPLICATION_JSON,
  });
  const data = new FormData();
  data.append("request", requestFile);
  data.append("file", mutableFile);
  data.append("smartObject", mutableSmartObject);
  const settingsFile = new File([mutableSettingsJson], "settings.json", { type: "application/json" });
  data.append("settings", settingsFile);

  const zip = new JSZip();

  for (const [key, value] of data.entries()) {
    if (value instanceof Blob) {
      // for blob and file objects
      zip.file(value.name, value);
    } else {
      // for json objects
      zip.file(key, new Blob([JSON.stringify(value)], { type: "application/json" }));
    }
  }

  // generate the zip file
  const content = await zip.generateAsync({ type: "blob" });

  const adjustedFileName = filename.replace(/\s+/g, "_");
  const formData = new FormData();
  formData.append("request", requestFile);
  formData.append("zipfile", content, adjustedFileName + "_data.zip");

  return await genericRepositoryService.uploadAsset(formData);
};

export const getAssetsToRemove = (
  pageManifest: any,
  pagePlayerType: string,
  assetTypeId: number,
  assetIndex: number,
  assetData: AssetManagerTypes.CurrentSelectedAsset,
) => {
  // try {
  switch (assetTypeId) {
    case ASSET_TYPE_ID.IMAGE:
      return getImageAssetsToRemove(pageManifest, pagePlayerType, assetIndex, assetData);
    case ASSET_TYPE_ID.AUDIO:
      return getAudioAssetsToRemove(pageManifest, assetIndex, assetData.assetId);
    case ASSET_TYPE_ID.VIDEO:
      return pageManifest.video.assetVersionId;
    case ASSET_TYPE_ID.SCORM:
      return null;
    default:
      return null;
  }
  // } catch (err) {

  // }
};
export const massageFileName = (file: any) => {
  //for use to display file name as how it will actually be represented in the blobpath
  let fileName = lowerCaseExtension(file.name);
  if (fileName === undefined) {
    // Handle that this is not a filename with a proper extension
    //will send original file name probably causing error.
    return file.name;
  }
  fileName = replaceInvalidChars(fileName);
  return fileName.toLowerCase();
};

export const associateAssetToPage = (
  pageManifest: any,
  assetData: AssetManagerTypes.CurrentSelectedAsset,
  assetTypeId: number,
  editorModeIndex: number,
  pagePlayerType: string,
  assetIndex: number,
  isAnnotationsShown?: any,
) => {
  switch (assetTypeId) {
    case ASSET_TYPE_ID.IMAGE:
      pageManifest = updateManifestImageAsset(
        pageManifest,
        assetData,
        editorModeIndex,
        pagePlayerType,
        assetIndex,
        isAnnotationsShown,
      );
      break;
    case ASSET_TYPE_ID.AUDIO:
      pageManifest = updateManifestAudioAsset(pageManifest, assetData, assetIndex);
      break;
    case ASSET_TYPE_ID.VIDEO: {
      const pm = _.cloneDeep(pageManifest);
      pm.video.path = assetData.blobPath;
      pm.video.assetVersionId = assetData.assetId;
      pageManifest = pm;
      break;
    }
    case ASSET_TYPE_ID.SCORM:
      {
        const pm = _.cloneDeep(pageManifest);
        pm.pageScormObject[0].blobPath = assetData.blobPath;
        pm.pageScormObject[0].assetVersionId = assetData.assetId;
        pageManifest = pm;
      }
      break;
    default:
      break;
  }
  return pageManifest;
};

const fileValidation = async (file: File, lessonData: IUploadLessonData, assetTypeId: number) => {
  const returnObject: returnObject = {
    data: undefined,
    message: "",
  };

  //check if valid extension
  const validFileType = manageFileType(file, assetTypeId);

  if (IsFileTooBig(file)) {
    returnObject.message = fileTooBigCheckMessage(file);
    return returnObject;
  } // need to confirm how this is used by caller...

  //if
  if (validFileType) {
    const uploads = await assetUpload(file, lessonData, assetTypeId);

    if (uploads) {
      returnObject.data = uploads.data;
      returnObject.message = "Upload Success";

      return returnObject;
    }
  } else {
    returnObject.message = "Invalid file type";
    return returnObject;
  }
};
const assetUpload = async (file: File, lessonData: IUploadLessonData, assetTypeId: number) => {
  if (file !== null) {
    let imageData: any = { h: -1, w: -1 }; //image dimensions are part of the asset request if you aren't using images it will just set to null so don't worry about it
    if (assetTypeId === 4) {
      imageData = await getImageDims(file);
    }
    //let aircraftIdsArray: string[] = lessonData.aircraftIdssplit(",");
    const requestFormData = new FormData();
    const request: IUploadAssetRequest = {
      imageWidth: imageData.w === -1 ? null : imageData.w,
      imageHeight: imageData.h === -1 ? null : imageData.h,
      assetTypeId: assetTypeId,
      description: lessonData.description ? lessonData.description : file.name,
      aircraftId: lessonData.aircraftId,
      aircraftFamilyId: lessonData.aircraftFamilyId,
      manufacturerId: lessonData.manufacturerId,
      aircraftPlacementIds: lessonData.aircraftPlacementIds,
      ataIds: lessonData.ataIds,
      versionName: lessonData.versionName,
      tailoredForLmsKey: lessonData.tailoredForLmsKey,
      uploadForLmsKey: lessonData.uploadForLmsKey,
    };
    const requestBlob = new Blob([JSON.stringify(request)], {
      type: APPLICATION_JSON,
    });
    requestFormData.append("request", requestBlob);
    createFormDataInMbChunks(requestFormData, file);
    const response = await genericRepositoryService.uploadAsset(requestFormData);
    // TODO GPS: show user if any were duplicates
    if (response.isSuccess) {
      if (response.data.isArchived) {
        const message = "This file " + file.name + " already exists in the Library Archives.";
        alert(message);
      } else if (response.data.isReusedAsset) {
        let message = "This file " + file.name + " already exists in the Library.";
        message += " Description, Manufacturer, Model, Variant and Tag info will be combined with original asset.";
        alert(message);
      }
      return response;
    }
  }
};

export const manageFileType = (file: any, assetTypeId: number) => {
  let returnBool = false;
  const validFileTypes = {
    images: [".jpg", ".png", ".gif", ".jpeg"],
    svg: [".svg"],
    audio: [".mp3"],
    video: [".mp4"],
    text: [".txt", ".rtf"],
    pdf: [".pdf"],
    interactiveDiagram: [".zip", ".prt"],
    livery: [".png"],
  };
  if (file.name) {
    switch (assetTypeId) {
      case ASSET_TYPE_ID.FMS:
      case ASSET_TYPE_ID.COCKPIT_PROCEDURE:
      case ASSET_TYPE_ID.MISCELLANEOUS:
      case ASSET_TYPE_ID.IMAGE: {
        returnBool = fileTypeCheck(file, validFileTypes.images);
        break;
      }
      case ASSET_TYPE_ID.AUDIO:
      case ASSET_TYPE_ID.AUDIO_EFFECT:
        returnBool = fileTypeCheck(file, validFileTypes.audio);
        break;
      case ASSET_TYPE_ID.RICH_TEXT:
      case ASSET_TYPE_ID.PDF:
        returnBool = fileTypeCheck(file, validFileTypes.pdf);
        break;
      case ASSET_TYPE_ID.VIDEO:
        returnBool = fileTypeCheck(file, validFileTypes.video);
        break;
      case ASSET_TYPE_ID.SCORM:
        returnBool = fileTypeCheck(file, validFileTypes.interactiveDiagram);
        break;
      case ASSET_TYPE_ID.LIVERY:
        returnBool = fileTypeCheck(file, validFileTypes.livery);
        break;
      case ASSET_TYPE_ID.SMART_OBJECT:
        returnBool = fileTypeCheck(file, validFileTypes.svg);
        break;
      default:
        break;
    }
  }
  return returnBool;
};
const fileTypeCheck = (file: any, fileTypeArray: string[]) => {
  if (file.name) {
    //looks at the file type at the end of a string for valid type for the asset
    const fileName = file.name.split(".");
    const fileExtension = fileName[fileName.length - 1].toLowerCase();
    if (fileTypeArray.indexOf(`.${fileExtension}`) === -1) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
const manageFileExtension = (fileName: string) => {
  let fileNameSplit = "";
  let tempString: string[] = [];
  let lastIndex = 0;
  if (!fileName || fileName.length === 0) {
    console.warn("Do Nothing");
  } else {
    tempString = fileName.split(".");
    lastIndex = tempString.length - 1;
    tempString[lastIndex] = tempString[lastIndex].toLowerCase();
    fileNameSplit = tempString?.join(".");
  }
  return fileNameSplit;
};
const getImageDims = async (file: File) => {
  return new Promise((resolve, reject) => {
    const _URL = window.URL || window.webkitURL;
    let image: any;
    let dims = { h: -1, w: -1 };

    image = new Image();

    image.onload = () => {
      dims = { h: image.height, w: image.width };
      resolve(dims);
    };
    image.onerror = reject;
    image.src = _URL.createObjectURL(file);
  });
};
const updateManifestImageAsset = (
  pageManifest: any,
  assetData: AssetManagerTypes.CurrentSelectedAsset,
  editorModeIndex: number,
  pagePlayerType: string,
  assetIndex: number,
  isAnnotationsShown?: any,
) => {
  //this function was set to async and was causing issues. I removed the async and it works now
  // const pageContext: IPageContext = useContext<IPageContext>(PageContext);
  // if (pageContext.isAnnotationsShown){

  // }

  ///////////////////////// need to detect if in design mode or in edit mode to put image into refference image or into holding in page manifest//////////////////////

  switch (pagePlayerType) {
    case constants.ThreeD_PAGE:
      if (editorModeIndex === 3) {
        pageManifest.choices.splice(assetIndex, 1, {
          image: assetData.blobPath,
          assetVersionId: assetData.assetId,
        });
      } else {
        pageManifest.StepGraphic.splice(assetIndex, 1, {
          image: assetData.blobPath,
          assetVersionId: assetData.assetId,
        });
      }
      break;
    case constants.WALK_AROUND:
      if (editorModeIndex === 3) {
        pageManifest.choices.splice(assetIndex, 1, {
          image: assetData.blobPath,
          assetVersionId: assetData.assetId,
        });
      } else {
        pageManifest.StepGraphic.splice(assetIndex, 1, {
          image: assetData.blobPath,
          assetVersionId: assetData.assetId,
        });
      }
      break;
    case constants.CABIN:
      if (editorModeIndex === 3) {
        pageManifest.choices.splice(assetIndex, 1, {
          image: assetData.blobPath,
          assetVersionId: assetData.assetId,
        });
      } else {
        pageManifest.StepGraphic.splice(assetIndex, 1, {
          image: assetData.blobPath,
          assetVersionId: assetData.assetId,
        });
      }
      break;
    case constants.QUIZ_PAGE:
      pageManifest.image.splice(assetIndex, 1, {
        imagePath: assetData.blobPath,
        assetVersionId: assetData.assetId,
      });
      break;
    case constants.FMS_PAGE:
      if (isAnnotationsShown === true) {
        pageManifest = AddFmsHoldingImage(pageManifest, assetData, assetIndex);
      } else {
        pageManifest = AddFmsRefImage(pageManifest, assetData);
      }
      break;
    case constants.BASIC_PAGE:
    default:
      // updatedManifest.pageImage.splice(assetContext.assetIndex, 1, { "imagePath": data.blobPath, "assetVersionId": data.assetId });
      pageManifest.pageImage[assetIndex].imagePath = assetData.blobPath;
      pageManifest.pageImage[assetIndex].assetVersionId = assetData.assetId;
      break;
    case constants.TITLE_PAGE:
    case constants.EXIT_PAGE:
      pageManifest = updateTitleOrExit(pageManifest, assetData, assetIndex);
      break;
  }
  return pageManifest;
};
const AddFmsRefImage = (pageManifest: any, assetData: AssetManagerTypes.CurrentSelectedAsset) => {
  const imageObject = {
    imagePath: assetData.blobPath,
    assetVersionId: assetData.assetId,
    ParentVersion: 0,
  };
  if (pageManifest.pageImage) {
    pageManifest.pageImage[0] = imageObject;
  } else {
    pageManifest["pageImage"] = [imageObject];
  }
  return pageManifest;
};
const AddFmsHoldingImage = (pageManifest: any, assetData: AssetManagerTypes.CurrentSelectedAsset, imgIndex: number) => {
  const imageObject = {
    imagePath: assetData.blobPath,
    assetVersionId: assetData.assetId,
    ParentVersion: 0,
  };
  if (pageManifest.holdingDisplay.length >= 1) {
    pageManifest.holdingDisplay[imgIndex] = imageObject;
  } else {
    pageManifest.holdingDisplay[0] = imageObject;
    // pageManifest.holdingDisplay[pageManifest.holdingDisplay.length] = imageObject;
  }
  return pageManifest;
};
const updateTitleOrExit = (
  pageManifest: any,
  assetData: AssetManagerTypes.CurrentSelectedAsset,
  assetIndex: number,
) => {
  // imagesList.forEach((image: IAsset) => {
  // if (image.assetVersionId === selectedImage) {
  switch (assetIndex) {
    case 0:
      pageManifest.BrandedLogo.imagePath = assetData.blobPath;
      pageManifest.BrandedLogo.assetVersionId = assetData.assetId;
      // updatedManifest.BrandedLogo.parentVersionId = image.parentVersionId;
      break;
    case 1:
      pageManifest.Background.imagePath = assetData.blobPath;
      pageManifest.Background.assetVersionId = assetData.assetId;
      // updatedManifest.Background.parentVersionId = image.parentVersionId;
      break;
    case 2:
      pageManifest.AirplaneOverlay.imagePath = assetData.blobPath;
      pageManifest.AirplaneOverlay.assetVersionId = assetData.assetId;
      // updatedManifest.AirplaneOverlay.parentVersionId = image.parentVersionId;
      break;
    default:
      break;
  }
  return pageManifest;
  // }
  // });
};
export const updateManifestAudioAsset = (
  pageManifest: any,
  assetData: AssetManagerTypes.CurrentSelectedAsset,
  audioIndex: number,
) => {
  //needs to check for the index to exist
  pageManifest.Audio.splice(audioIndex, 1, {
    ...pageManifest.Audio[audioIndex],
    File: assetData.blobPath,
    assetVersionId: assetData.assetId,
  });
  return pageManifest;
};

const getImageAssetsToRemove = (
  pageManifest: any,
  pagePlayerType: string,
  assetIndex: number,
  assetData: AssetManagerTypes.CurrentSelectedAsset,
) => {
  switch (pagePlayerType) {
    case constants.ThreeD_PAGE:
      try {
        if (pageManifest.StepGraphic[assetIndex].assetVersionId !== assetData.assetId) {
          return pageManifest.StepGraphic[assetIndex].assetVersionId;
        }
      } catch {
        return null;
      }
      break;
    case constants.QUIZ_PAGE:
      if (pageManifest.image[assetIndex].assetVersionId !== assetData.assetId) {
        return pageManifest.image[assetIndex].assetVersionId;
      }
      break;
    case constants.TITLE_PAGE:
    case constants.EXIT_PAGE:
      return removeAssetsFromTitleOrExit(pageManifest, assetIndex);
    case constants.BASIC_PAGE: {
      let count = 0;
      let avid: number | null = null;
      _.forEach(pageManifest.pageImage, (image, index) => {
        if (pageManifest.pageImage[assetIndex].assetVersionId === image.assetVersionId) {
          count++;
        }
      });
      if (count > 1) {
        avid = null;
      } else {
        avid = pageManifest.pageImage[assetIndex].assetVersionId;
      }
      return avid;
    }
    default:
      return null;
  }
};
const removeAssetsFromTitleOrExit = (pageManifest: any, assetIndex: number) => {
  switch (assetIndex) {
    case 0:
      return pageManifest.BrandedLogo.assetVersionId;
    case 1:
      return pageManifest.AirplaneOverlay.assetVersionId;
    case 2:
      return pageManifest.Background.assetVersionId;
    default:
      return -1;
  }
};
export const getAudioAssetsToRemove = (pageManifest: any, audioIndex: number, assetVersionId: number) => {
  if (pageManifest.Audio[audioIndex].Version !== assetVersionId) {
    return pageManifest.Audio[audioIndex].Version === "" ? null : pageManifest.Audio[audioIndex].Version;
  } else {
    return null;
  }
};
