import React from "react";
import { IKeyPressHandler } from "../../components/ObjectPropertyBox/models/IKeyPressHandler";
import { ObjectActionsType, useObjectsDispatch } from "../../contexts/ObjectsProvider";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import type { BlurCutoutObject } from "../../types";
import AnnotationHighlight from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";
import { ElementTypes } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { objectOpacityFromRules } from "../../utils";
import { ShapeMasks } from "./ShapeMasks";
import { useMiscUI } from "../../contexts/MiscUI/MiscUIProvider";

export interface ShapePropType {
  index: number;
  keyPressHandlers: IKeyPressHandler;
  target: HTMLElement;
  objectId: string;

  rotate?: number;
  zIndex?: number;

  pixelTop?: number;
  pixelLeft?: number;
  pixelWidth?: number;
  pixelHeight?: number;

  maintainRatio?: boolean;

  height?: number;
  width?: number;
  left: number;
  top: number;

  version?: any;

  shadowColor?: string;
  backgroundColor?: string;
  opacity?: number;
  visible?: boolean;
  ghost?: boolean;
  blurIntensity?: number;
  blurOpacity?: number;
  blurColor?: string;
  blurCutoutShapes: BlurCutoutObject[];

  handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void;
  loadImage: (e: React.SyntheticEvent<HTMLImageElement>, index: number, objectId: string) => void;
  selectTarget: (
    target: HTMLDivElement,
    index: number,
    type: ElementTypes,
    ref?: React.RefObject<HTMLElement | SVGSVGElement>,
  ) => void;
  handleImageDivClick: (index: number) => void;
}

const Shape = (props: ShapePropType): JSX.Element => {
  const {
    index,
    objectId,
    width,
    left,
    top,
    rotate,
    height,
    zIndex,
    backgroundColor,
    shadowColor,
    opacity,
    visible,
    ghost,
    blurIntensity,
    blurCutoutShapes,
  } = props;

  const [, setMiscUI] = useMiscUI();

  const {
    position: [x, y],
    size: [widthWithFrames, heightWithFrames],
    rotation,
    opacity: currentOpacity,
    blurIntensity: interpolatedBlurIntensity,
    blurCutoutShapes: interpolatedBlurCutoutShapes,
  } = useObjectPosition(objectId, top, left, width, height, rotate, opacity, blurIntensity, blurCutoutShapes);

  const rotateString = rotation ? `rotate(${rotation}deg)` : "rotate(0deg)";
  const objectsDispatch = useObjectsDispatch();
  const transformString = `translate(${x}px, ${y}px) ${rotateString}`;
  const isTransparent = backgroundColor === "transparent";
  const cutoutList = interpolatedBlurCutoutShapes ?? [];

  const masks = cutoutList.map((cutout) => ({
    type: cutout.type,
    x: cutout.x,
    y: cutout.y,
    width: cutout.w,
    height: cutout.h,
    rotate: cutout.rotate,
    cutoutId: cutout.id,
    isVisible: cutout.isVisible,
  }));
  const hasBlur = typeof interpolatedBlurIntensity === "number" && interpolatedBlurIntensity > 0;
  const blurString = hasBlur ? `blur(${interpolatedBlurIntensity}px)` : "";
  const isVisibleInTimeline = useObjectIsInTime(objectId);
  const calculatedOpacity = objectOpacityFromRules(currentOpacity, visible, isVisibleInTimeline, ghost);

  return (
    <div
      className={`target ff-shape guide-${objectId}`}
      id={`shape-target-${index}`}
      data-objectid={objectId}
      key={index}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!objectId) return;

        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }

        objectsDispatch({
          type,
          payload: { objectId },
        });
      }}
      tabIndex={0}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        zIndex: zIndex,
        transform: transformString,
      }}
    >
      <ShapeMasks
        masks={masks}
        objectId={objectId}
        width={widthWithFrames}
        height={heightWithFrames}
        onSelect={(selectedMask) => {
          // Close all other cutout panels
          masks.forEach((mask) => {
            if (mask.cutoutId !== selectedMask.cutoutId) {
              objectsDispatch({
                type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
                payload: {
                  objectId,
                  cutoutId: mask.cutoutId,
                  props: { isOpen: false },
                },
              });
            }
          });

          // Open selected mask panel
          objectsDispatch({
            type: ObjectActionsType.UPDATE_BLUR_CUTOUT,
            payload: {
              objectId,
              cutoutId: selectedMask.cutoutId,
              props: { isOpen: true },
            },
          });

          // Update selection
          setMiscUI({
            type: "SET_SELECTED_MASK_ID",
            payload: selectedMask.cutoutId,
          });
        }}
      />
      <AnnotationHighlight objectId={objectId} />
      <div id="free-form-container" className="aspect-container">
        <div className="free-form-element aspect-container-inside">
          <div className="free-form-inner aspect-centering no-hover-image">
            <div
              id={`ff-shape-${index}`}
              style={{
                display: isVisibleInTimeline || ghost ? "block" : "none",
                width: "100%",
                height: "100%",
                filter: visible ? "none" : "grayscale(100%)",
                mask: `url(#${objectId})`,
                boxShadow: `${shadowColor}`,
                backgroundColor: !isTransparent ? `rgb(from ${backgroundColor} R G B / ${calculatedOpacity})` : ``,
                backdropFilter: blurString,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shape;
